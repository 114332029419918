import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { irisIngestionApi } from "../api/irisIngestionApi";

export const useIrisIngestionUploadMutation = () => {
  return useMutation({
    mutationKey: ["iris-create-upload"],
    mutationFn: (data) => irisIngestionApi.createUpload(data),
    keepPreviousData: true
  });
};

export const useIrisIngestionUploadCompleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["iris-complete-upload"],
    mutationFn: (id) => irisIngestionApi.completeUpload(id),
    keepPreviousData: true,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["iris-docs-list"] });
    }
  });
};

export const useIrisIngestionUpdateDocumentsMutation = (handleSuccess) => {
  return useMutation({
    mutationKey: ["iris-update-document"],
    mutationFn: (data) => irisIngestionApi.updateDocuments(data),
    keepPreviousData: true,
    onSuccess: handleSuccess
  });
};
export const useIrisIngestionGenerateFileTitleMutation = () => {
  return useMutation({
    mutationKey: ["iris-generate-title"],
    mutationFn: async (data) => {
      const res = await irisIngestionApi.generateFileTitle(data);
      return res.file_name;
    },
    keepPreviousData: true
  });
};

export const useIrisIngestionDeleteDocumentMutation = (handleSuccess) => {
  return useMutation({
    mutationKey: ["iris-docs-delete-doc"],
    mutationFn: (fileId) => irisIngestionApi.deleteDocument(fileId),
    keepPreviousData: true,
    onSuccess: handleSuccess
  });
};

export const useIrisIngestionResumeUpdateDocumentMutation = (handleSuccess) => {
  return useMutation({
    mutationKey: ["iris-resume-update"],
    mutationFn: (fileId) => irisIngestionApi.resumeUpdateDocument(fileId),
    keepPreviousData: true,
    onSuccess: handleSuccess
  });
};

export const useIrisIngestionMarkFailedMutation = () => {
  return useMutation({
    mutationKey: ["iris-mark-failed"],
    mutationFn: (fileId) => irisIngestionApi.markDocumentFailed(fileId),
    keepPreviousData: true
  });
};
