import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { ticketDetailsApi } from "@/features/ticket-details/ticketDetailsApi";

export const useTicketDetailsSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(ticketDetailsApi.setTicketDetailsSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ticket-details"] });
    }
  });
};
