import { getCurrentInstance } from "vue";

export const useThemeSwitcher = () => {
  const root = getCurrentInstance().proxy;
  const user = root.$store.getters["session/User"];
  const $t = root.$t;

  const saveProfile = (theme) => {
    const query = `mutation ($input: UserModelMutationInput!){
        updateUser(input:$input) {
            jwt
            errors {
            messages
            field
            },
            theme,
        }
    }`;
    const variables = {
      input: {
        id: user.id,
        theme: theme === "dark" ? 1 : 0,
        username: user.username
      }
    };

    root.$http
      .post("graphql/", { query, variables })
      .then((res) => {
        const updatedProfile = res.data.data.updateUser;
        root.$store.dispatch("session/UpdateProfile", updatedProfile);
        root.errors = updatedProfile.errors;
        if (root.errors) {
          root.errors.forEach((error) => {
            error.messages.forEach((message) => {
              root.$message("ALERT", { text: message, type: "error", timeout: 1000 });
            });
          }, this);
        } else {
          root.$message("ALERT", { text: $t("Saved"), type: "success", timeout: 1000 });
        }
      })
      .catch((res) => {
        root.errors = res.errors;
        if (!root.errors) {
          return;
        }
        root.errors.forEach((error) => {
          root.$message("ALERT", { text: error, type: "error", timeout: 1000 });
        }, this);
      });
  };

  const updateTheme = (theme) => {
    if (theme === "dark") {
      root.$store.dispatch("app/SetTheme", "dark");
      root.$vuetify.theme.dark = true;
    } else {
      root.$store.dispatch("app/SetTheme", "light");
      root.$vuetify.theme.dark = false;
    }
    saveProfile(theme);
  };

  return {
    updateTheme
  };
};
