<template>
  <v-timeline-item
    :color="data.color"
    :icon="data.icon"
    :icon-color="data.time ? data.iconColor : 'var(--text-secondary)'"
    fill-dot
  >
    <div class="d-flex flex-column align-start timeline-item">
      <p
        :class="[
          'text-body-1 font-weight-medium',
          data.time ? data.titleColor : 'color--text-secondary'
        ]"
      >
        {{ data.title }}
      </p>
      <p
        v-if="data.time"
        class="time text-body-2 color--text-secondary"
      >
        <span v-if="showFromLabel">{{ $t("From") }}: </span
        >{{ moment(data.time).format($datetime.default) }}
      </p>
    </div>
  </v-timeline-item>
</template>

<script setup>
import moment from "moment";
import { computed, toRefs } from "vue";
import { useIntl } from "@/shared/useIntl";
import { TICKET_STATUS } from "@/features/ticket-details/constants";

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
    default: () => ({})
  },
  stage: {
    type: String,
    required: true
  }
});

const { stage, ticket } = toRefs(props);
const { $t } = useIntl();

const CONFIG = {
  waiting: {
    color: "var(--bg-card)",
    titleColor: "color--text-yellow",
    icon: "mdi-update",
    iconColor: "var(--text-yellow)",
    time: (ticket) => ticket.value.ticketedDate,
    title: $t("Waiting")
  },
  active: {
    color: "var(--bg-card)",
    titleColor: "color--text-accent",
    icon: "mdi-briefcase-outline",
    iconColor: "var(--text-accent)",
    time: (ticket) => ticket.value.firstResponseDate || ticket.value.closedDate,
    title: $t("Active")
  },

  [TICKET_STATUS.RESOLVED]: {
    color: "var(--bg-card)",
    titleColor: "color--text-green",
    icon: "mdi-check-circle-outline",
    iconColor: "var(--text-green)",
    time: (ticket) => ticket.value.closedDate,
    title: $t("Resolved")
  },

  [TICKET_STATUS.CREATED_BY_MISTAKE]: {
    color: "var(--bg-card)",
    titleColor: "color--text-secondary",
    icon: "mdi-cancel",
    iconColor: "var(--text-secondary)",
    time: (ticket) => ticket.value.closedDate,
    title: $t("Cancelled")
  },

  [TICKET_STATUS.CAN_NOT_BE_RESOLVED]: {
    color: "var(--bg-card)",
    titleColor: "color--text-red",
    icon: "mdi-cancel",
    iconColor: "var(--text-red)",
    time: (ticket) => ticket.value.closedDate,
    title: $t("Can’t be resolved")
  }
};

const data = computed(() => {
  let state = stage.value;

  if (state === "resolved") {
    state = ticket.value.solutionType;
  }

  const result = CONFIG[state || stage.value];
  result.time = result.time(ticket);

  return result;
});

const showFromLabel = computed(() => {
  const statuses = Object.values(TICKET_STATUS);
  return !statuses.includes(stage.value);
});
</script>

<style lang="scss" scoped>
.v-timeline-item:last-of-type {
  padding-bottom: 0 !important;
}
</style>
