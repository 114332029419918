<template>
  <div class="ticket">
    <div
      class="desktopView"
      v-if="vw > 1000"
    >
      <div class="ticket-details">
        <div class="card">
          <div class="card-header">
            <div class="options">
              <h2>{{ ticket && ticket.title ? ticket.title : $t("Ticket Details") }}</h2>
              <v-text-field
                v-if="ticket && 'reference' in ticket && saveTicketFunc"
                v-model="ticket.reference"
                :append-outer-icon="refSave ? 'mdi-content-save' : ''"
                :label="$t('Reference ID')"
                @input="refSave = true"
                single-line
                hide-details
              >
                <template v-slot:append-outer>
                  <v-icon
                    class=""
                    v-if="refSave"
                    @click="saveRef"
                    >mdi-content-save</v-icon
                  >
                </template>
              </v-text-field>
              <v-btn
                color="primary"
                @click="subscribe"
                v-if="subscribeFunc && !subscribed"
              >
                {{ $t("Subscribe") }}
              </v-btn>
              <v-btn
                color="warning"
                @click="unsubscribe"
                v-if="unsubscribeFunc && subscribed"
              >
                {{ $t("Unsubscribe") }}
              </v-btn>
            </div>
            <div
              class="status"
              :class="status.class"
            >
              <p>{{ status.name }}</p>
            </div>
          </div>
          <div
            class="card-content"
            v-if="ticket"
          >
            <div class="card fks">
              <div class="item">
                <label class="label">{{ $t("Machine") }}</label>
                <p>{{ ticket.machineName }}</p>
              </div>
              <div class="item">
                <label class="label">{{ $t("Issue Type") }}</label>
                <p>{{ ticket.issueTypeName }}</p>
              </div>
              <div class="item">
                <label class="label">{{ $t("Part") }}</label>
                <p>{{ ticket.partName }}</p>
              </div>
              <div class="item">
                <label class="label">{{ $t("Asset") }}</label>
                <p>{{ ticket.assetName }}</p>
              </div>
              <div class="item">
                <label class="label">{{ $t("Order") }}</label>
                <p>{{ ticket.factoryOrder }}</p>
              </div>
            </div>
            <div class="row">
              <Timeline
                :ticket="ticket"
                :closeFunc="closeFunc"
                :timeline-error-message="timelineErrorMessage"
              />
              <div class="col-xs-5">
                <div class="users">
                  <div class="userGroup">
                    <h3 class="label">
                      {{ $t("Assigned") }}
                      <v-dialog
                        content-class="assignableDialog"
                        v-model="assignDialog"
                        persistent
                        max-width="600px"
                        v-if="assignFunc"
                      >
                        <DialogCloseButton :onClick="() => (assignDialog = false)" />
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            light
                            fab
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i
                              class="fa fa-plus"
                              aria-hidden="true"
                            ></i>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            <h3>{{ $t("Assign Users") }}</h3>
                          </v-card-title>
                          <v-card-text>
                            <div class="row">
                              <div class="col-xs-12 col-sm-6">
                                <v-text-field
                                  v-model="userSearch"
                                  append-icon="mdi-magnify"
                                  :label="$t('Search Users to Assign')"
                                  single-line
                                  hide-details
                                  clearable
                                ></v-text-field>
                                <div class="assignable">
                                  <p
                                    v-for="(user, user_index) in assignableUsers"
                                    @click="newUsers.push(user)"
                                  >
                                    {{ user.firstName }} {{ user.lastName }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-6">
                                <h2>{{ $t("New Users") }}</h2>
                                <div class="newUsers">
                                  <p
                                    v-for="(new_user, new_user_index) in newUsers"
                                    @click="newUsers.splice(new_user_index, 1)"
                                  >
                                    {{ new_user.firstName }} {{ new_user.lastName }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              text
                              @click="assignDialog = false"
                            >
                              {{ $t("Cancel") }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              primary
                              text
                              :disabled="newUsers.length == 0"
                              :loading="assiningTicket"
                              @click="assignUsers()"
                            >
                              {{ $t("Assign") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </h3>
                    <div class="usersInGroup">
                      <p
                        class="user"
                        v-for="(user, user_index) in assignedTo"
                      >
                        {{ user }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="!ticket.closedDate"
                    class="userGroup"
                  >
                    <h3 class="label">{{ $t("Responding") }}</h3>
                    <div class="usersInGroup">
                      <div class="button-container">
                        <v-btn
                          color="primary"
                          light
                          fab
                          small
                          @click="respondToTicket"
                          v-if="respondFunc && hasntResponded"
                        >
                          <i
                            class="fa fa-hand-paper-o"
                            aria-hidden="true"
                          ></i>
                        </v-btn>
                        <v-btn
                          color="primary"
                          light
                          fab
                          small
                          @click="PinLoginVisible = true"
                          v-if="respondFunc"
                        >
                          <i class="mdi mdi-dialpad"></i>
                        </v-btn>
                      </div>
                      <PinLogin
                        ref="pinlogin"
                        :visible-prop.sync="PinLoginVisible"
                        :login-callback="loginCallback"
                      />
                      <p
                        class="user"
                        v-for="(user, user_index) in respondingTo"
                      >
                        {{ user }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="extra-details">
        <slot name="extraDetails"></slot>
      </div>
      <div class="comment-feed">
        <div class="card">
          <comment-feed
            :filters="filters"
            :newCommentParams="commentParams"
            :reload="reloadComments"
          />
        </div>
      </div>
    </div>
    <div
      clas="mobileView"
      v-else
    >
      <div class="tab-headers">
        <v-tabs
          class="tabs"
          v-model="mobileTab"
          align-with-title
          key="tabs"
          fixed-tabs
          height="4rem"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="info">
            <i
              class="fa fa-file-text"
              aria-hidden="true"
            ></i>
          </v-tab>
          <v-tab key="timeline">
            <i
              class="fa fa-clock-o"
              aria-hidden="true"
            ></i>
          </v-tab>
          <v-tab key="comments">
            <i
              class="fa fa-comment-o"
              aria-hidden="true"
            ></i>
          </v-tab>
        </v-tabs>
      </div>
      <transition-group
        class="wrapper"
        name="slide-fade-list"
      >
        <div
          key="info"
          class="card ticket-info"
          v-if="mobileTab == 0"
        >
          <div class="card-header">
            <div class="options">
              <h2>{{ ticket && ticket.title ? ticket.title : $t("Ticket Details") }}</h2>
            </div>
          </div>
          <div
            class="card-content fks"
            v-if="ticket"
          >
            <div class="row">
              <div
                class="status"
                :class="status.class"
              >
                <p>{{ status.name }}</p>
              </div>
              <v-btn
                color="primary subscribe-button"
                @click="subscribe"
                v-if="subscribeFunc && !subscribed"
              >
                {{ $t("Subscribe") }}
              </v-btn>
              <v-btn
                color="warning subscribe-button"
                @click="unsubscribe"
                v-if="unsubscribeFunc && subscribed"
              >
                {{ $t("Unsubscribe") }}
              </v-btn>
            </div>
            <div class="item issue-type">
              <label class="label">{{ $t("Issue Type") }}</label>
              <p>{{ ticket.issueTypeName }}</p>
            </div>
            <div class="item">
              <label class="label">{{ $t("Machine") }}</label>
              <p>{{ ticket.machineName }}</p>
            </div>
            <div class="item">
              <label class="label">{{ $t("Part") }}</label>
              <p>{{ ticket.partName }}</p>
            </div>
            <div class="item">
              <label class="label">{{ $t("Asset") }}</label>
              <p>{{ ticket.assetName }}</p>
            </div>
            <div class="item">
              <label class="label">{{ $t("Order") }}</label>
              <p>{{ ticket.factoryOrder }}</p>
            </div>
            <div class="userInfo">
              <div class="users">
                <div class="userGroup">
                  <h3 class="label">{{ $t("Assigned") }}</h3>
                  <div class="usersInGroup">
                    <v-dialog
                      content-class="assignableDialog"
                      v-model="assignDialog"
                      persistent
                      max-width="600px"
                      v-if="assignFunc"
                    >
                      <DialogCloseButton :onClick="() => (assignDialog = false)" />
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          light
                          fab
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i
                            class="fa fa-plus"
                            aria-hidden="true"
                          ></i>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline">
                          <h3>{{ $t("Assign Users") }}</h3>
                        </v-card-title>
                        <v-card-text>
                          <div class="row">
                            <div class="col-xs-12 col-sm-6">
                              <v-text-field
                                v-model="userSearch"
                                append-icon="mdi-magnify"
                                :label="$t('Search Users to Assign')"
                                single-line
                                hide-details
                                clearable
                              ></v-text-field>
                              <div class="assignable">
                                <p
                                  v-for="(user, user_index) in assignableUsers"
                                  @click="newUsers.push(user)"
                                >
                                  {{ user.firstName }} {{ user.lastName }}
                                </p>
                              </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                              <h2>{{ $t("New Users") }}</h2>
                              <div class="newUsers">
                                <p
                                  v-for="(new_user, new_user_index) in newUsers"
                                  @click="newUsers.splice(new_user_index, 1)"
                                >
                                  {{ new_user.firstName }} {{ new_user.lastName }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            @click="assignDialog = false"
                          >
                            {{ $t("Cancel") }}
                          </v-btn>
                          <v-btn
                            color="primary"
                            primary
                            text
                            :disabled="newUsers.length == 0"
                            :loading="assiningTicket"
                            @click="assignUsers()"
                          >
                            {{ $t("Assign") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <p
                      class="user"
                      v-for="(user, user_index) in assignedTo"
                    >
                      {{ user }}
                    </p>
                  </div>
                </div>
                <div class="userGroup">
                  <h3 class="label">{{ $t("Responding") }}</h3>
                  <div class="usersInGroup">
                    <v-btn
                      color="primary"
                      light
                      fab
                      small
                      @click="respondToTicket"
                      v-if="respondFunc && hasntResponded && !ticket.closedDate"
                    >
                      <i
                        class="fa fa-hand-paper-o"
                        aria-hidden="true"
                      ></i>
                    </v-btn>
                    <p
                      class="user"
                      v-for="(user, user_index) in respondingTo"
                    >
                      {{ user }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="extra-details">
              <slot name="extraDetails"></slot>
            </div>
          </div>
        </div>
        <div
          key="timeline"
          class="card timeline-card"
          v-if="mobileTab == 1"
        >
          <div class="card-header">
            <h2>{{ $t("Timeline") }}</h2>
          </div>
          <div class="card-content">
            <Timeline
              :ticket="ticket"
              :closeFunc="closeFunc"
            />
          </div>
        </div>
        <div
          key="comments"
          class="card"
          v-if="mobileTab == 2"
        >
          <comment-feed
            :filters="filters"
            :newCommentParams="commentParams"
            :reload="reloadComments"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";
@import "../../scss/mq";
.assignableDialog {
  h3 {
    color: $blue;
  }
  h2 {
    margin-top: 1.5rem;
    margin-left: 5px;
  }
  .v-input {
    margin: 0 5px;
  }
  .assignable,
  .newUsers {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    padding: 0.5rem;
    height: 276px;
    overflow-y: auto;
    margin-top: 1rem;
    p {
      transition: all 0.25s ease-in-out;
      margin: 0.25rem;
      padding: 0.25rem 1rem;
      border-radius: 1rem;
      cursor: pointer;
    }
  }
  .assignable {
    p:hover {
      background-color: $green;
    }
  }
  .newUsers {
    p:hover {
      background-color: $red;
    }
  }
}
.ticket {
  height: 100%;
  width: 100%;
  .desktopView {
    margin: 1rem;
    display: grid;
    grid-template-columns: minmax(800px, 1.5fr) minmax(250px, 1fr);
    grid-template-rows: minmax(600px, 1.5fr) minmax(200px, 1fr);
    grid-auto-flow: dense;
    gap: 1rem 1rem;
    height: 100%;
    width: 100%;
  }
  .comment-feed {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
  .card {
    height: 100%;
    overflow: hidden;

    .fks .subscribe-button {
      height: unset !important;
    }

    .card-header {
      h2 {
        font-size: 1.5rem;
        margin-left: 0.5rem;
        color: $blue;
      }
      .v-input {
        padding-top: 0;
        margin-left: 50px;
      }
      .options {
        display: flex;
        button {
          margin-left: 1rem;
        }
      }
      .status {
        border-radius: 0.2rem;
        padding: 0.25rem 1rem;
        margin-right: 0.5rem;
      }
    }
    .card-content {
      .fks {
        margin: 1rem 0.5rem;
        border-radius: 0.5rem;
        min-height: 3rem;
        height: 5rem;
        display: flex;
        justify-content: space-around;

        .item {
          height: 5rem;
          min-height: 5rem;
          margin: 0.75rem 1rem;
          width: 12rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          p {
            margin: 0.25rem 0.5rem;
            text-align: center;
          }
        }
      }
      .row {
        height: calc(100% - 6rem);

        .timeline {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          margin-top: -1rem;
          h3 {
            margin-left: 4rem;
          }
        }
        .theme--dark.v-timeline .v-timeline-item__dot {
          background: none;
        }
        .v-timeline {
          height: calc(100% - 5rem);
          width: 100%;
          .v-timeline-item {
            align-items: center;
            height: 20%;
            &.duration {
              height: 0%;
              text-align: right;
            }
            .v-timeline-item__body,
            .itemDetails {
              display: flex;
              align-items: center;
              .time {
                min-width: 10rem;
                margin-right: 2rem;
              }
              .duration {
                min-width: 8rem;
              }
            }
          }
        }
        .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
          left: calc(30% - 1px) !important;
        }
        .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
          .v-timeline-item:nth-child(odd):not(.v-timeline-item--before)
          .v-timeline-item__body {
          max-width: calc(70% - 48px);
        }
        .users {
          height: 100%;
          width: 100%;
          .userGroup {
            height: 50%;
            width: 100%;
            padding: 1rem;
            text-align: center;
            position: relative;
            h3 {
              button {
                margin-right: -4rem;
                margin-left: 1rem;
              }
            }
            .label {
              margin: auto;
              padding: 0.25rem;
            }
            button {
              i {
                font-size: 1.25rem;
              }
            }
            .usersInGroup {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              flex-wrap: wrap;
              max-height: calc(100% - 2rem);
              overflow: auto;
              .user {
                margin: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
  .button-container {
    display: flex;
    gap: 8px;
  }
}
@include media_below(1400px) {
  .ticket {
    padding: 1rem;
    .desktopView {
      display: grid;
      grid-template-columns: 1fr;
      height: unset;
      max-width: 100%;
    }
    .ticket-details {
      max-width: 100%;
      order: 1;
    }
    .extra-details {
      order: 2;
    }
    .comment-feed {
      grid-column: 1;
      grid-row: 3;
      order: 3;
    }
  }
  .mobileView {
    width: 100%;
    margin-left: 1rem;
  }
}
@include media_below(1000px) {
  .ticket {
    padding: 0 0.5rem;
    .tab-headers {
      width: 100%;
      height: 65px;
      position: fixed;
      bottom: 0;
      padding-bottom: 5px;
      z-index: 999;
      background-color: $darkGrey;
      .v-tab {
        padding: 0 5px;
        min-width: 50px;
        i {
          margin: 0 0.5rem;
          font-size: 1.5rem;
          transition: all 0.25s ease-in-out;
        }
      }
    }
    .wrapper {
      position: absolute;
      top: 0;
      left: 0.5rem;
      width: calc(100% - 1rem);
      bottom: calc(65px + 0.5rem);
      z-index: 100;
      padding-top: 0.5rem;
      .ticket-info {
        .card-header {
          background-color: $grey;
          h2 {
            width: 100%;
          }
        }
        .card-content {
          display: flex;
          flex-wrap: wrap;
          .row {
            height: auto;
            width: 100%;
            margin-right: 0;
            .v-btn,
            .status {
              width: calc(50% - 2rem);
              text-align: center;
              margin: 1rem;
              border-radius: 4px;
              font-weight: 500;
              letter-spacing: 0.0892857143em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .item {
            width: 50%;
            &.issue-type {
              width: 100%;
            }
            p {
              text-align: left;
              font-size: 1.25rem;
            }
            .label {
              color: $blue;
              font-weight: bold;
            }
          }
          .userInfo {
            width: 100%;
            margin: 1rem 0;
            .users {
              display: flex;
              justify-content: space-around;
              .userGroup {
                .usersInGroup {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }
              }
              .label {
                color: $blue;
                font-weight: bold;
              }
            }
          }
          .extra-details {
            margin-bottom: 2rem;
          }
        }
      }
      .timeline-card {
        .card-content {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import CommentFeed from "@/components/CommentFeed";
import DialogCloseButton from "@/components/DialogCloseButton";
import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";

import PinLogin from "../PinLogin.vue";
import Timeline from "./Timeline";

export default {
  props: [
    "ticket_id",
    "ticket",
    "assignFunc",
    "users",
    "respondFunc",
    "closeFunc",
    "subscribeFunc",
    "unsubscribeFunc",
    "saveTicketFunc",
    "timelineErrorMessage"
  ],
  data() {
    return {
      mobileTab: 0,
      loadingTicket: false,
      filters: {
        ticketId: { value: this.ticket_id }
      },
      assignedDialog: false,
      commentParams: {
        ticketId: this.ticket_id
      },
      closeTicketDialog: false,
      closingTicket: false,
      assignDialog: false,
      subscribingTicket: false,
      userSearch: "",
      newUsers: [],
      assiningTicket: false,
      ticketSocket: null,
      reloadComments: false,
      refSave: false,
      PinLoginVisible: false
    };
  },
  created() {
    this.connectTicketSocket();
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
    if (this.ticketSocket) {
      this.ticketSocket.close();
      this.ticketSocket = null;
    }
  },
  components: {
    PinLogin,
    CommentFeed,
    Timeline,
    DialogCloseButton
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      vw: "app/VW"
    }),
    assignableUsers() {
      if (this.users) {
        let assignable = [];
        let ids = [];
        if (this.ticket.assignedTo) {
          ids = this.ticket.assignedTo
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll("'", "")
            .split(",");
        }
        this.users.forEach((user) => {
          let match = ids.find((id) => {
            return id == user.id;
          });
          if (!match) {
            match = this.newUsers.find((newUser) => {
              return newUser.id == user.id;
            });
            if (!match) {
              if (this.userSearch && this.userSearch != "") {
                let fullName = (user.firstName + " " + user.lastName).toLowerCase();
                if (fullName.includes(this.userSearch.toLowerCase())) {
                  assignable.push(user);
                }
              } else {
                assignable.push(user);
              }
            }
          }
        });
        return assignable;
      } else {
        return [];
      }
    },
    assignedTo() {
      if (this.ticket.assignedToNames) {
        return this.ticket.assignedToNames
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
    },
    respondingTo() {
      if (this.ticket.respondingNames) {
        return this.ticket.respondingNames
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
    },
    subscribed() {
      if (this.ticket.unsubscribed) {
        let unsubbed = this.ticket.unsubscribed
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
        if (unsubbed.includes(this.user.id.toString())) {
          return false;
        }
      }
      let ids = [];
      if (this.ticket.subscribed) {
        ids += this.ticket.subscribed
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      if (this.ticket.assigned) {
        ids += this.ticket.assigned
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      if (this.ticket.responding) {
        ids += this.ticket.responding
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      console.log(ids, this.user.id.toString());
      return ids.includes(this.user.id.toString());
    },
    hasntResponded() {
      let ids = this.ticket.responding
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("'", "")
        .split(",");
      return !ids.includes(this.user.id.toString());
    },
    status() {
      if (this.ticket) {
        if (this.ticket.closedDate) {
          return {
            class: "red",
            name: this.$t("Closed")
          };
        } else if (this.ticket.firstResponseDate) {
          return {
            class: "orange",
            name: this.$t("Active")
          };
        } else if (this.ticket.ticketedDate) {
          return {
            class: "green",
            name: this.$t("Waiting")
          };
        } else {
          return {
            class: "blue",
            name: this.$t("Not Ticketed")
          };
        }
      }
    }
  },
  methods: {
    moment,
    seconds,
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    connectTicketSocket() {
      this.ticketSocket = this.$socket(`ticket/${this.ticket_id}/`, (r) => {
        let data = JSON.parse(r.data);
        let type = data.type;
        let message = data.message;
        switch (type) {
          case "comment":
            // eslint-disable-next-line no-case-declarations
            let context = this;
            setTimeout(function () {
              context.reloadComments = !context.reloadComments;
            }, 1000);
            break;
          case "ticket_update":
            Dispatcher.$message("TICKET_UPDATE");
        }
      });
    },
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    },
    assignUsers() {
      this.assiningTicket = true;
      this.assignFunc(this.newUsers);
      this.assiningTicket = false;
      this.assignDialog = false;
    },
    subscribe() {
      this.subscribingTicket = true;
      this.subscribeFunc();
      this.subscribingTicket = false;
    },
    unsubscribe() {
      this.subscribingTicket = true;
      this.unsubscribeFunc();
      this.subscribingTicket = false;
    },
    respondToTicket() {
      this.respondingToTicket = true;
      this.respondFunc(this.pinJwt);
    },
    saveRef() {
      this.savingRef = true;
      this.saveTicketFunc();
      this.refSave = false;
      this.savingReg = false;
    },
    closeTicket() {
      this.closingTicket = true;
      this.closeFunc();
    },
    loginCallback(v, item, username) {
      this.pinJwt = v;
      this.respondToTicket();
      this.pinJwt = null;
    }
  }
};
</script>
