import { render, staticRenderFns } from "./ZoneControlAutoPerformance.vue?vue&type=template&id=2688a561&scoped=true&"
import script from "./ZoneControlAutoPerformance.vue?vue&type=script&lang=js&"
export * from "./ZoneControlAutoPerformance.vue?vue&type=script&lang=js&"
import style0 from "./ZoneControlAutoPerformance.vue?vue&type=style&index=0&id=2688a561&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2688a561",
  null
  
)

export default component.exports