<template>
  <ModalDialog
    :value="open"
    persistent
    max-width="400px"
    height="256px"
    @close="$emit('close')"
  >
    <template #title> {{ $t("Respond") }} </template>

    <template #body>
      <div class="text-subtitle-1 text--primary">
        {{
          $t("Are you sure you want to respond to {title}?", { title: ticket.title || ticket.id })
        }}
      </div>
    </template>
    <template #actions>
      <btn
        class="btn-respond"
        primary
        @click="$emit('close')"
      >
        {{ $t("Cancel") }}
      </btn>
      <btn
        class="btn-respond"
        variant="primary"
        :loading="loading"
        @click="$emit('submit')"
      >
        {{ $t("Respond") }}
      </btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

defineProps({
  ticket: {
    type: Object,
    default: () => ({})
  },
  open: {
    type: Boolean,
    default: false,
    required: true
  },
  loading: { type: Boolean, default: false, required: true }
});

defineEmits(["close", "submit"]);
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.btn-respond {
  min-width: 168px !important;
}
</style>
