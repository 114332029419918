export const Presets = {
  SHIFT: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
  NONE: 5,
  PREV_PRODUCTION_DAY: 7,
  CURRENT_PRODUCTION_DAY: 8
};

export const Scales = {
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month"
};

export const PRESETS_TO_SCALE = {
  [Presets.SHIFT]: Scales.HOUR,
  [Presets.DAY]: Scales.HOUR,
  [Presets.WEEK]: Scales.DAY,
  [Presets.MONTH]: Scales.WEEK,
  [Presets.YEAR]: Scales.MONTH,
  [Presets.PREV_PRODUCTION_DAY]: Scales.HOUR,
  [Presets.CURRENT_PRODUCTION_DAY]: Scales.HOUR
};

export const SCALE_TO_PRESET = {
  [Scales.HOUR]: Presets.DAY,
  [Scales.DAY]: Presets.WEEK,
  [Scales.WEEK]: Presets.MONTH,
  [Scales.MONTH]: Presets.YEAR
};

export const FILTERS_DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const DATE_FORMAT = "MMM DD";
export const DATE_FORMAT_YEAR = "MMM DD, YYYY";
export const DATE_TIME_FORMAT = "hh:mm A";

export const QUERY_LONG_LIVE_STALE_TIME = 60 * 60 * 1000; // 1hr

export const TABLET_WIDTH = 1200;
export const MOBILE_WIDTH = 768;
