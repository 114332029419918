<template>
  <ModalDialog
    content-class="assign"
    :value="open"
    persistent
    max-width="576px"
    full-screen-mobile
    height="555px"
    @close="$emit('close')"
  >
    <template #title> {{ $t("Close ticket") }} </template>

    <template #body>
      <form
        id="ticket-details"
        @submit.prevent="submit"
      >
        <div class="text-subtitle-2">{{ $t("Select a resolution for a ticket") }}</div>

        <v-radio-group
          v-model="solutionType"
          class="mt-2"
        >
          <v-radio
            v-for="item in BUTTONS"
            :key="item.value"
            class="mb-4"
            :label="item.title"
            :value="item.value"
          ></v-radio>
        </v-radio-group>

        <v-textarea
          v-model="solutionDetails"
          :error="Boolean(textareaError)"
          :error-messages="textareaError"
          :hide-details="!textareaError"
          required
          filled
          :label="$t('Solution*')"
        />

        <v-alert
          v-if="errMessage"
          type="error"
          class="mt-4"
        >
          {{ errMessage }}
        </v-alert>
      </form>
    </template>
    <template #actions>
      <btn
        class="btn-close"
        primary
        @click="() => $emit('close')"
      >
        {{ $t("Cancel") }}
      </btn>
      <btn
        class="btn-close"
        variant="error"
        :loading="closeTicketLoading"
        type="submit"
        form="ticket-details"
      >
        {{ $t("Close") }}
      </btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import { computed, ref, toRefs, watch } from "vue";

import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import { TICKET_STATUS } from "@/features/ticket-details/constants";
import { useIntl } from "@/shared/useIntl";
import { useTicketClose } from "@/features/ticket-details/useTicketClose";

const props = defineProps({
  ticket: { type: Object, default: () => undefined },
  open: { type: Boolean, default: false, required: true }
});

const TICKET_DETAILS_MIN_LENGTH = 30;
const { open } = toRefs(props);

const solutionType = ref(TICKET_STATUS.RESOLVED);
const solutionDetails = ref("");
const textareaError = ref("");

const emit = defineEmits(["close"]);

const { $t } = useIntl();
const {
  mutate: closeTicket,
  isLoading: closeTicketLoading,
  error
} = useTicketClose(props.ticket.id, () => {
  emit("close");
});

watch(open, () => {
  solutionType.value = TICKET_STATUS.RESOLVED;
  solutionDetails.value = "";
});

const BUTTONS = [
  {
    title: $t("Issue resolved"),
    value: TICKET_STATUS.RESOLVED
  },
  {
    title: $t("Issue can't be resolved"),
    value: TICKET_STATUS.CAN_NOT_BE_RESOLVED
  },
  {
    title: $t("Ticket created by mistake"),
    value: TICKET_STATUS.CREATED_BY_MISTAKE
  }
];

const checkTextarea = () => {
  if (!solutionDetails.value) {
    textareaError.value = $t("This field is required");
  } else if (solutionDetails.value && solutionDetails.value.length < TICKET_DETAILS_MIN_LENGTH) {
    textareaError.value = $t("Describe a solution (min ${minLength} s.)", {
      minLength: TICKET_DETAILS_MIN_LENGTH
    });
  } else {
    textareaError.value = "";
  }

  return !textareaError.value;
};

const submit = async () => {
  const valid = checkTextarea();

  if (valid) {
    await closeTicket({
      ticket_id: props.ticket.id,
      solutionType: solutionType.value,
      solutionDetails: solutionDetails.value
    });
  }
};

const errMessage = computed(() => {
  if (error.value) {
    return error?.value[0]?.message || $t("Something went wrong");
  }

  return null;
});

defineExpose({
  BUTTONS,
  solutionType,
  solutionDetails,
  closeTicketLoading,
  submit,
  errMessage
});
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.btn-close {
  min-width: 168px !important;
}
</style>
