import { useQuery } from "@tanstack/vue-query";
import { settingsApi } from "./settingsApi";
import { computed, readonly, watch } from "vue";
import moment from "moment-timezone";
import { QUERY_LONG_LIVE_STALE_TIME } from "@/shared/constants";

export const useSettingsTimezoneQuery = () => {
  const timezoneQuery = useQuery({
    queryKey: ["timezone"],
    queryFn: settingsApi.getTimezone,
    staleTime: QUERY_LONG_LIVE_STALE_TIME
  });

  const timezone = computed(() => {
    let settings = timezoneQuery.data.value && timezoneQuery.data.value.data.settings;

    let tz = "America/New_York";
    if (settings && settings.length > 0) {
      tz = settings[0].timezone;
    }

    return tz;
  });

  watch(
    timezone,
    (val) => {
      moment.tz.setDefault(val);
    },
    { immediate: true }
  );

  return {
    timezone: readonly(timezone),
    isFetchedTimezone: timezoneQuery.isFetched
  };
};
