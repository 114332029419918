// dummy $t function, Vue does not initialize translations on this step
import { WORKFLOW_EVENTS_STATUSES } from "@/utils/workflowEvents";

const $t = (template) => template;

export const MAX_FILES_SIZE_GB = 5;
export const FILES_TYPES = ".pdf,.csv,.xlsx,.xls";
export const OTHER_MACHINE = {
  id: "other",
  name: $t("Other")
};

//hardcoded
export const KNOWLEDGE_DOMAINS = [
  { id: 1, name: $t("Manufacturing"), apiName: "manufacturing" },
  { id: 2, name: $t("Finance"), apiName: "finance" }
];

export const IRIS_UPLOAD_INJECT_FILES = "files";
export const IRIS_UPLOAD_INJECT_FILES_ERROR = "filesError";
export const IRIS_UPLOAD_INJECT_VALIDATE_FILES = "validateFiles";
export const UPPY = "uppy";
export const APPLY_UPPY_MUTATION = "applyUppyMutation";
export const UPLOAD_STATUSES = "uploadStatuses";
export const UPPY_UPLOAD = "uppyUpload";
export const ON_UPPY_LISTENER = "onUppyListener";
export const OFF_UPPY_LISTENER = "offUppyListener";
export const UPPY_FILES_TO_UPLOAD = "filesToUpload";
export const POSSIBLE_UPLOAD_STATUSES = {
  //uppy statuses
  initiated: "Initiated",
  uploadProgress: "Uploading",
  uploadSuccess: "Uploaded",
  uploadFailed: "Failed",
  //api statuses
  created: "Created",
  processing: "Processing",
  success: "Success",
  indexing: "Indexing",
  textract_reading: "Textract Reading",
  textract_in_progress: "Textract in Progress",
  in_process: "In Process",
  uploaded_to_s3: "Uploaded to S3",
  extracting_text: "Extracting Text",
  waiting_for_processing: "Waiting for Processing",
  failed: "Failed",
  aborted: "Aborted",
  finished: "Finished",
  textract_failed: "Textract Failed",
  textract_completed: "Textract Completed",
  deleted: "Removed from LLM access",
  deletion_failed: "Removal Failed",
  updates_postponed: "Updates Postponed"
};

export const FINALIZED_UPLOAD_STATUSES = [
  "initiated",
  "uploadProgress",
  "uploadSuccess",
  "uploadFailed",
  "success",
  "failed",
  "aborted",
  "finished",
  "textract_failed",
  "deleted",
  "deletion_failed",
  "updates_postponed"
];

export const IRIS_STATUSES_AVAILABLE_FOR_DELETION = [
  POSSIBLE_UPLOAD_STATUSES.success,
  POSSIBLE_UPLOAD_STATUSES.failed,
  POSSIBLE_UPLOAD_STATUSES.finished
];

export const IRIS_UPPY_UPLOAD_STATUSES = [
  POSSIBLE_UPLOAD_STATUSES.initiated,
  POSSIBLE_UPLOAD_STATUSES.uploadProgress,
  POSSIBLE_UPLOAD_STATUSES.uploadSuccess,
  POSSIBLE_UPLOAD_STATUSES.uploadFailed
];

export const IRIS_UPLOAD_INJECT_ADD_FILES = "addFilesToUpload";
export const IRIS_UPLOAD_INJECT_UPLOAD = "upload";
export const IRIS_UPLOAD_INJECT_UPLOAD_DISABLED = "uploadDisabled";
export const IRIS_UPLOAD_TEXT_MIN_CHARACTERS = 50;
export const IRIS_UPLOAD_TEXT_MAX_CHARACTERS = 500;
export const IRIS_UPLOAD_UPDATE_STATUS_INTERVAL = 30000;

export const IRIS_UPLOAD_FILE_SOURCE_TYPE = {
  document: "Uploaded",
  typed: "Typed"
};

export const IRIS_INGESTION_KNOWLEDGE_VIEW_FILTER_PARAMS = "knowledgePortalViewFilterParams";
export const IRIS_INGESTION_KNOWLEDGE_VIEW_IS_FILTER_OPEN = "knowledgePortalViewIsFilterOpen";
export const IRIS_INGESTION_KNOWLEDGE_VIEW_SEARCH = "knowledgePortalViewSearch";
export const IRIS_INGESTION_UPLOAD_CREATE_TYPE_KNOWLEDGE_OBJECT = "createTypeKnowledgeObject";
export const IRIS_INGESTION_UPLOAD_UPDATE_TYPE_KNOWLEDGE_OBJECT = "updateTypeKnowledgeObject";

export const ORIGINAL_IRIS_INGESTION_TYK_STATUS = {
  on_review: "on_review",
  rejected: "rejected",
  approved: "approved",
  published: "published",
  deleted: "deleted"
};

export const IRIS_INGESTION_POSSIBLE_REVIEW_STATUSES = {
  [ORIGINAL_IRIS_INGESTION_TYK_STATUS.on_review]: "On Review",
  [ORIGINAL_IRIS_INGESTION_TYK_STATUS.rejected]: "Rejected",
  [ORIGINAL_IRIS_INGESTION_TYK_STATUS.approved]: "Approved",
  [ORIGINAL_IRIS_INGESTION_TYK_STATUS.published]: "Published",
  [ORIGINAL_IRIS_INGESTION_TYK_STATUS.deleted]: "Deleted"
};

export const IRIS_INGESTION_MAX_IMAGE_SIZE_MB = 10;
export const IRIS_INGESTION_ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
export const IRIS_INGESTION_CREATE_UPLOAD = "irisIngestionCreateUpload";
export const IRIS_INGESTION_UPLOAD_HANDLE_FILTER_PARAMS_CHANGE = "handleUploadFilterParamsChange";
export const IRIS_INGESTION_UPLOAD_FILTER_PARAMS = "irisUploadFilterParams";
export const IRIS_INGESTION_UPLOAD_INPUT_ERRORS = "irisUploadInputErrors";
export const IRIS_INGESTION_TYPE_KNOWLEDGE_DATA = "irisTypeKnowledgeData";
export const IRIS_INGESTION_TYPE_KNOWLEDGE_ERROR = "irisTypeKnowledgeError";
export const IRIS_INGESTION_MACHINES = "machines";

export const ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS = {
  created: "created",
  approved: "approved",
  rejected: "rejected",
  updated: "updated",
  published: "published",
  deleted: "deleted"
};
export const IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS_NAMES = {
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.created]: $t("Created"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.approved]: $t("Approved"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.rejected]: $t("Rejected"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.updated]: $t("Updated"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.published]: $t("Published"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.deleted]: $t("Deleted")
};

export const IRIS_INGESTION_TYPE_KNOWLEDGE_EVENT_ACTION_STATUSES = {
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.created]: WORKFLOW_EVENTS_STATUSES.info,
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.approved]: WORKFLOW_EVENTS_STATUSES.success,
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.rejected]: WORKFLOW_EVENTS_STATUSES.error,
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.deleted]: WORKFLOW_EVENTS_STATUSES.error,
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.updated]: WORKFLOW_EVENTS_STATUSES.waiting,
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.published]: WORKFLOW_EVENTS_STATUSES.progress
};

export const IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS_DESCRIPTIONS = {
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.created]: $t("created a ticket"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.approved]: $t("approved"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.rejected]: $t("responded"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.updated]: $t("sent to review"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.published]: $t("published"),
  [ORIGINAL_IRIS_INGESTION_TYPE_KNOWLEDGE_ACTIONS.deleted]: $t("deleted")
};

export const IRIS_INGESTION_FILTER_FIELDS = {
  DOMAIN: "domain",
  MACHINE: "machine",
  TYK_STATUS: "tykStatus"
};

export const IRIS_INGESTION_FILTER_DOMAIN_VALUES = {
  finance: "Finance",
  manufacturing: "Manufacturing"
};

export const IRIS_INGESTION_FILTER_STATUS_VALUES = [
  { value: "on_review", text: $t("On Review") },
  { value: "rejected", text: $t("Rejected") },
  { value: "approved", text: $t("Approved") },
  { value: "published", text: $t("Published") },
  { value: "deleted", text: $t("Deleted") }
];

export const UPPY_ALLOWED_FILE_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".txt",
  ".xlsx",
  ".xls",
  ".csv",
  ".pptx",
  ".ppt"
];
