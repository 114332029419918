<template>
  <ModalDialog
    content-class="assign"
    :value="open"
    persistent
    max-width="576px"
    full-screen-mobile
    @close="closeAssign"
  >
    <template #title> {{ $t("Assign users") }} </template>

    <template #body>
      <div class="d-flex flex-md-row flex-column">
        <div class="col-xs-12 col-md-6 py-0">
          <TextField
            v-model="userSearch"
            filled
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            single-line
            class="assign-input"
            hide-details
            clearable
          ></TextField>
          <div class="assignable mt-4">
            <p
              v-for="user in filteredUsers"
              :key="user.id"
              class="assign-item"
              @click="newUsers.push(user)"
            >
              {{ user.firstName }} {{ user.lastName }}
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 py-0">
          <div class="text-body-1 assign-heading color--text-primary">{{ $t("Added") }}</div>
          <div class="newUsers mt-0 mt-md-2">
            <div
              v-for="(new_user, new_user_index) in newUsers"
              :key="new_user.id"
              class="assign-item selected"
              @click="newUsers.splice(new_user_index, 1)"
            >
              {{ new_user.firstName }} {{ new_user.lastName }}

              <div>
                <v-icon class="color--text--secondary">mdi-close</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <btn
        class="btn-assign"
        primary
        @click="closeAssign"
      >
        {{ $t("Cancel") }}
      </btn>
      <btn
        class="btn-assign"
        variant="primary"
        :disabled="newUsers.length === 0"
        :loading="assigningTicket"
        @click="helpAssign(ticket)"
      >
        {{ $t("Assign") }}
      </btn>
    </template>
  </ModalDialog>
</template>

<script>
import { mapGetters } from "vuex";
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import TextField from "@/ui/TextField/TextField.vue";

export default {
  name: "TicketAssignDialog",
  components: { TextField, ModalDialog, Btn },

  props: {
    type: { type: String, default: "p911" },
    ticket: { type: Object, default: () => undefined },
    open: { type: Boolean, default: false, required: true },
    machine_group_id: { type: String, default: "", required: false }
  },

  data() {
    return {
      assignDialog: true,
      userSearch: "",
      users: [],
      newUsers: [],
      assigningTicket: false
    };
  },

  computed: {
    ...mapGetters({
      user: "session/User",
      statuses: "app/Statuses"
    }),
    filteredUsers() {
      if (!this.userSearch) {
        return this.users;
      }
      return this.users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.userSearch.toLowerCase());
      });
    }
  },

  created() {
    this.checkMachineGroupPrioritization();
  },
  methods: {
    checkMachineGroupPrioritization() {
      let query = "";
      let variables = {};
      if (this.machine_group_id) {
        query = `query getMachineGroup($id: ID!){
          machineGroup(id: $id){
            id
            ... on MachineGroupType{
              prioritization
            }
          }
          users{
            id,
            firstName,
            lastName,
          }
        }`;
        variables = { id: this.machine_group_id };
      } else {
        query = `query {
          users{
            id,
            firstName,
            lastName,
          }
        }`;
      }

      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        if (
          res.data.data &&
          "machineGroup" in res.data.data &&
          "prioritization" in res.data.data.machineGroup
        ) {
          this.prioritization = res.data.data.machineGroup.prioritization;
        }
        this.users = res.data.data.users;
      });
    },

    closeAssign() {
      this.assignDialog = false;
      this.newUsers = [];
      this.$emit("close");
    },

    helpAssign(ticket) {
      this.assigningTicket = true;
      let newUserIds = [];
      this.newUsers.forEach((user) => {
        newUserIds.push(user.id);
      });

      let query;
      let variables;

      if (this.type === "p911") {
        query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
          updateHelpTicket(globalId:$id, helpData:$helpTicketData){
            ok,
            helpTicket{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          helpTicketData: {
            assignNew: newUserIds
          }
        };
      } else {
        query = `mutation ($id: ID!, $issueData: IssueInput!){
          updateIssue(globalId:$id, issueData:$issueData){
            ok,
            issue{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          issueData: {
            assignNew: newUserIds
          }
        };
      }

      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          this.newUsers = [];
          this.assigningTicket = false;
          this.$emit("success");
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/scss/_variables.scss";

.assign {
  .assignable,
  .newUsers {
    border: rem(1px) solid var(--border-secondary);
    border-radius: rem(8px);
    padding: rem(20px);
    height: rem(272px);
    overflow-y: auto;
  }
}

.assign-item {
  transition: all 0.25s ease-in-out;
  padding: rem(12px) rem(12px) rem(11px);
  border-radius: rem(8px);
  cursor: pointer;
  border: rem(1px) solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background-color: var(--bg-brand);
    border: rem(1px) solid var(--border-secondary);
    color: var(--text-primary);
  }
}

.assign-heading {
  min-height: 56px;
  display: flex;
  align-items: center;
}
.root {
  background-color: var(--bg-secondary);
}
.assign-input ::v-deep .v-label {
  color: var(--text-primary);
}
.btn-assign {
  min-width: 168px !important;
}
</style>
