<template>
  <div class="operator-analytics">
    <v-btn
      class="filter-btn"
      fab
      x-small
      light
      v-if="vw < 1200"
      @click="showSearch = !showSearch"
    >
      <i
        class="fa fa-filter"
        v-if="!showSearch"
      ></i>
      <i
        class="fa fa-times"
        v-if="showSearch"
      ></i>
    </v-btn>
    <div
      class="filters card"
      v-if="vw > 1200 || showSearch"
    >
      <div class="view card">
        <v-radio-group v-model="view">
          <v-radio
            key="table"
            :label="$t('Data Table')"
            value="table"
          ></v-radio>
          <v-radio
            key="charts"
            :label="$t('Analytics')"
            value="chart"
          ></v-radio>
          <v-radio
            key="scorecard"
            :label="$t('Scorecard')"
            value="scorecard"
            :disabled="!user.useDevelopmentEdge"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="dates card">
        <h3>{{ $t("Presets") }}</h3>
        <button
          class="btn radioBtn"
          v-for="(btn, btnIndex) in intervals"
          :key="btn.value"
          :class="{ active: preset == btn.value }"
          @click="presetClick(btn.value)"
        >
          {{ btn.name }}
        </button>
        <datetime-picker
          id="from_date"
          :label="$t('From Date')"
          v-model="from_date"
        ></datetime-picker>
        <datetime-picker
          id="to_date"
          :label="$t('To Date')"
          v-model="to_date"
        ></datetime-picker>
      </div>
      <div class="searchbars card">
        <v-autocomplete
          clearable
          :placeholder="$t('Zone')"
          :items="Array.from(zoneSet)"
          @change="
            (e) => {
              setFilter('zone', e);
            }
          "
          :value="filters.zone"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <v-autocomplete
          clearable
          :placeholder="$t('Machine')"
          :items="Array.from(machineSet)"
          @change="
            (e) => {
              setFilter('machine', e);
            }
          "
          :value="filters.machine"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <v-autocomplete
          clearable
          :placeholder="$t('Part')"
          :items="Array.from(partSet)"
          @change="
            (e) => {
              setFilter('part', e);
            }
          "
          :value="filters.part"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <v-autocomplete
          clearable
          :placeholder="$t('Operator')"
          :items="Array.from(operatorSet)"
          @change="
            (e) => {
              setFilter('name', e);
            }
          "
          :value="filters.name"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
      </div>
    </div>
    <div
      class="table"
      v-if="view == 'table'"
    >
      <v-data-table
        :headers="table_headers"
        :table_headers="header_options"
        :items="sorted_items"
        @click:row="rowClick"
        :expanded.sync="expanded"
        :single-expand="true"
        item-key="key"
        :hide-default-header="vw > 1000"
        :loading="loading"
      >
        <template
          v-slot:header="{ props }"
          v-if="vw > 1000"
        >
          <thead>
            <tr>
              <th
                v-for="head in table_headers"
                :key="head.text"
                :id="head.text"
              >
                <div
                  class="header_item"
                  v-bind:class="head.class"
                  @click="
                    () => {
                      sortby(head);
                    }
                  "
                >
                  <div class="rowcontent">{{ head.text }}</div>
                  <i
                    v-if="head.sort == 'asc'"
                    class="mdi mdi-arrow-up"
                  />
                  <i
                    v-else-if="head.sort == 'desc'"
                    class="mdi mdi-arrow-down"
                  />
                  <i v-else></i>
                </div>
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:item.expand="{ item }">
          <v-btn
            x-small
            fab
            light
            v-if="item.machines && item.machines.length > 1 && !expanded.includes(item)"
          >
            <i
              class="fa fa-caret-down"
              aria-hidden="true"
            ></i>
          </v-btn>
          <v-btn
            x-small
            fab
            light
            v-if="item.machines && item.machines.length > 1 && expanded.includes(item)"
          >
            <i
              class="fa fa-caret-up"
              aria-hidden="true"
            ></i>
          </v-btn>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="table_name">
            <div
              class="icon"
              v-bind:style="
                item.avatar
                  ? {
                      background: `url(${item.avatar})`,
                      'background-size': 'cover',
                      'background-position-x': 'center',
                      'background-position-y': 'center'
                    }
                  : null
              "
            >
              <i
                class="fa fa-user"
                v-if="item.avatar == null"
              ></i>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="table_status">
            <div
              class="tiny-light"
              v-bind:class="item.checked_in ? 'green' : 'red'"
            ></div>
            <div class="name">{{ item.checked_in ? $t("Checked-In") : $t("Checked-Out") }}</div>
          </div>
        </template>

        <template v-slot:[`item.machine_status`]="{ item }">
          <div class="table_machine_status">
            <p :style="{ color: item.machine_status ? item.machine_status.color : '' }">
              {{ item.machine_status ? item.machine_status.name : "" }}
            </p>
          </div>
        </template>

        <template v-slot:[`item.target_units`]="{ item }">
          {{ numberWithCommas(item.target_units) }}
        </template>

        <template v-slot:[`item.actual`]="{ item }">
          {{ numberWithCommas(item.actual) }}
        </template>

        <template v-slot:[`item.average`]="{ item }">
          {{ numberWithCommas(item.average) }}
        </template>

        <template v-slot:[`item.gap`]="{ item }">
          <div
            class="table_gap"
            v-bind:class="GetGapColor(item.gap, item.target_units, item.actual)"
          >
            {{ numberWithCommas(item.gap) }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            style="padding: 0"
          >
            <div class="nestedTable">
              <realtime-table-expand
                :machines="item.machines"
                :table_headers="table_headers"
                :now="now"
                :role="item.role"
              />
            </div>
          </td>
        </template>
      </v-data-table>
    </div>

    <v-progress-linear
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <div
      class="charts"
      v-else-if="view == 'chart'"
    >
      <AnalyticsTimeSeries
        v-if="'name' in filters"
        class="operators chartCard card"
        :DataFormatter="Shorten"
        :title="filters['name'] + ' - ' + this.$t('Production Trend')"
        :dates="{ from_date: from_date, to_date: to_date }"
        :filters="filters"
        :reload="reload"
        :labels="[$t('Target'), $t('Produced')]"
        ChartType="production"
        ChartName="operator"
      />
      <PaginatedChart
        v-else
        class="operators chartCard card"
        :chartOptions="[
          {
            color: colors.peachPuff,
            borderColor: colors.peachPuff,
            fill: false,
            stack: false,
            keys: ['efficiencyVal'],
            uom: '%',
            label: this.$t('Efficiency'),
            type: 'line',
            yAxisID: 'percentage'
          },
          {
            color: colors.tiffanyBlue,
            keys: ['target_units'],
            label: this.$t('Target'),
            stack: false,
            type: 'bar',
            stack: 'stack1'
          },
          {
            color: colors.coralReef,
            keys: ['actual'],
            label: this.$t('Produced'),
            stack: false,
            type: 'bar',
            stack: 'stack2'
          }
        ]"
        :secondAxis="{
          id: 'percentage',
          type: 'linear',
          position: 'right',
          gridLines: {
            color: 'rgba(0,0,0,0)',
            zeroLineColor: 'rgba(0,0,0,0)',
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            callback: (item) => {
              return Shorten(item) + '%';
            }
          }
        }"
        showLegend="true"
        :DataFormatter="Shorten"
        label_name="name"
        sortOn="actual"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItems"
        :useNumberRecordsPicker="true"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('Operators')"
      />
      <AnalyticsTimeSeries
        v-if="'name' in filters"
        class="chartCard card"
        :DataFormatter="seconds"
        :title="filters['name'] + ' - ' + this.$t('Production Time Trend')"
        :dates="{ from_date: from_date, to_date: to_date }"
        :filters="filters"
        :reload="reload"
        :labels="[$t('Total Time'), $t('Runtime')]"
        ChartType="runtime"
        ChartName="operator"
      />
      <PaginatedChart
        v-else
        class="chartCard card"
        :chartOptions="[
          {
            color: colors.tiffanyBlue,
            keys: ['event_duration'],
            label: this.$t('Production Time'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="seconds"
        label_name="name"
        sortOn="event_duration"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItems"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('Operators - Production Time')"
      />
      <AnalyticsTimeSeries
        v-if="'part' in filters"
        class="chartCard card"
        :DataFormatter="Shorten"
        :title="filters['part'] + ' - ' + this.$t('Production Trend')"
        :dates="{ from_date: from_date, to_date: to_date }"
        :filters="filters"
        :reload="reload"
        :labels="[$t('Target'), $t('Produced')]"
        ChartType="production"
        ChartName="part"
      />
      <PaginatedChart
        v-else
        class="chartCard card"
        :chartOptions="[
          {
            color: colors.tiffanyBlue,
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: colors.coralReef,
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="part_number"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsPartNumbers"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('Part Numbers')"
      />
      <AnalyticsTimeSeries
        v-if="'machine' in filters"
        class="chartCard card"
        :DataFormatter="Shorten"
        :title="filters['machine'] + ' - ' + this.$t('Production Trend')"
        :dates="{ from_date: from_date, to_date: to_date }"
        :filters="filters"
        :reload="reload"
        :labels="[$t('Target'), $t('Produced')]"
        ChartType="production"
        ChartName="machine"
      />
      <PaginatedChart
        v-else
        class="chartCard card"
        :chartOptions="[
          {
            color: colors.tiffanyBlue,
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: colors.coralReef,
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="machine_name"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsMachines"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        title="Machines"
      />
      <AnalyticsTimeSeries
        v-if="'zone' in filters"
        class="chartCard card"
        :DataFormatter="Shorten"
        :title="filters['zone'] + ' - ' + this.$t('Production Trend')"
        :dates="{ from_date: from_date, to_date: to_date }"
        :filters="filters"
        :reload="reload"
        :labels="[$t('Target'), $t('Produced')]"
        ChartType="production"
        ChartName="zone"
      />
      <PaginatedChart
        v-else
        class="chartCard card"
        :chartOptions="[
          {
            color: colors.tiffanyBlue,
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: colors.coralReef,
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="zone"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsZone"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('By Zone')"
      />
    </div>
    <div
      v-else-if="view === 'scorecard'"
      style="width: 100%; height: 100%"
    >
      <DevelopmentFeature>
        <template v-slot:edge>
          <OperatorScorecard
            v-if="sorted_items.length === 1"
            :sorted-items="sorted_items"
            :from_date="from_date"
            :to_date="to_date"
            :preset="preset"
          />
        </template>
      </DevelopmentFeature>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
.operator-analytics {
  height: calc(100% - 4rem);
  display: flex;
  .filter-btn {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    z-index: 999;
    i {
      font-size: 1rem;
    }
    @media screen and (max-width: 1200px) {
      grid-column: 1 / 3;
    }
  }
  .filters {
    min-width: 350px;
    height: calc(100% - 2rem);
    padding: 0.01rem 1rem;
    .card {
      margin: 1rem 0;
      padding: 0.5rem 1rem;
    }
    .view {
      min-height: unset;
      margin: 0;
      margin-top: 1rem;
      .v-input {
        margin: 0;
      }
      .v-messages {
        display: none;
      }
      .v-radio {
        margin: 0.5rem;
      }
    }
    .dates {
      h3 {
        font-weight: 600;
        color: $blue;
      }
      button {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .searchbars {
      .v-text-field {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      background-color: $cardColor;
      box-shadow: $cardBoxShadow;
      height: unset;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      position: absolute;
      top: 3rem;
      left: 1rem;
      bottom: 1rem;
      overflow-y: auto;
      right: 1rem;
      z-index: 9999;
      .v-input {
        width: 100%;
      }
    }
  }
  .table {
    margin-left: 0.5rem;
    max-width: calc(100% - 350px);
    @media screen and (max-width: 1200px) {
      margin-top: 4rem;
      width: 100vw;
      max-width: unset;
      flex-grow: 1;
      .v-data-table {
        width: 100vw;
      }
    }
    th {
      vertical-align: middle;
    }

    tr {
      cursor: pointer;
      position: relative;
      .fa-caret-up,
      .fa-caret-down {
        font-size: 1.15rem;
      }
    }

    .v-data-table {
      padding-bottom: 50px;
      margin-bottom: 20px;

      .v-data-table__wrapper {
        height: unset !important;
        overflow: auto;
      }
    }
    .table_name {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        padding: 10px;
        margin: 10px;
        width: 40px;
        height: 40px;
        background: $blue;
        border-radius: 100%;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .name {
        flex: 1;
      }
    }
    .table_status {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tiny-light {
        background: green;
        border-radius: 100%;
        padding: 10px;
        margin: 10px;
        width: 20px;
        height: 20px;
      }
    }
    .table_gap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 30px;
      text-align: center;
      border-radius: 20px;
      &.good {
        background: green;
      }

      &.bad {
        background: red;
      }
    }

    .v-data-table__mobile-row {
      border-bottom: 1px rgba(255, 255, 255, 0.05) solid;
      justify-content: flex-start;
      display: flex !important;

      .v-data-table__mobile-row__header {
        color: $blue;
        width: 150px;
        text-align: left;
        flex: unset;
      }

      .v-data-table__mobile-row__cell {
        flex: 1;
        text-align: left;

        p {
          text-align: left;
        }
        div {
          text-align: left;
        }
      }
    }
  }
  .charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    overflow: auto;
    padding-bottom: 50px;
    margin: 1rem;
    padding-right: 1rem;
    margin-right: 0;
    flex-grow: 1;
    @media screen and (max-width: 1200px) {
      margin: 0;
      padding-top: 0.5rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
    .chartCard {
      min-height: unset;
      padding: 1rem;
      padding-top: 0;
      min-width: 0;
      position: unset;
      &:first-child {
        grid-column: 1 / 3;
      }
      @media screen and (max-width: 1200px) {
        grid-column: 1 / 3;
        &:first-child {
          margin-top: 60px;
          .pageSize {
            right: 0;
            padding-bottom: 10px;
          }
        }
      }
    }
    .trendChart {
      width: 100%;
      h3 {
        color: $blue;
        font-weight: 600;
      }
    }
    .paginatedChart {
      &.operators {
        width: 100%;
      }
      @media screen and (max-width: 1200px) {
        width: 100%;
        .pageSize {
          background-color: $background;
          top: 0;
          padding-left: 5rem;
        }
      }
    }
  }
}
</style>
<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart";
import DatetimePicker from "@/components/form/DatetimePicker";
import { coralReef, peachPuff, tiffanyBlue } from "@/scss/_variables.scss";
import { numberWithCommas, seconds, shorten } from "@/utils/filters";

import DevelopmentFeature from "../../../components/DevelopmentFeature.vue";
import { GetAveragePerHour } from "../../../utils/timeFormat";
import AnalyticsTimeSeries from "./AnalyticsTimeSeries";
import OperatorScorecard from "./OperatorScorecard.vue";
import PaginatedChart from "./PaginatedChart";
import RealtimeTableExpand from "./realtimeTableExpand";

export default {
  components: {
    DevelopmentFeature,
    OperatorScorecard,
    PaginatedChart,
    AnalyticsTimeSeries,
    DatetimePicker,
    RealtimeTableExpand,
    BarChart
  },
  data() {
    return {
      loading: true,
      view: "chart",
      now: new moment(),
      fetchDataDebounce: null,
      items: [],
      textFilter: "",
      filteredItems: [],
      numberRecords: 10,
      numberRecordsPerPage: 25,
      filteredItemsPartNumbers: [],
      filteredItemsMachines: [],
      filteredItemsZone: [],
      filters: {},
      zoneSet: new Set(),
      machineSet: new Set(),
      partSet: new Set(),
      operatorSet: new Set(),
      topOperators: {},
      slicedTopOperators: {},
      seconds: seconds,
      Shorten: shorten,
      numberWithCommas: numberWithCommas,
      showSearch: false,
      preset: "day",
      shift: false,
      from_date: moment().startOf("hour").subtract(23, "h").toDate(),
      to_date: moment().endOf("hour").toDate(),
      intervals: [
        { name: this.$t("Shift"), value: "shift" },
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      table_headers: [
        { text: "", value: "expand", align: "end" },
        { text: this.$t("Name"), value: "name", width: 160, class: "header_name" },
        { text: this.$t("Role"), value: "role", width: 130, class: "header_role" },
        { text: this.$t("Status"), value: "status", width: 160, class: "header_status" },
        { text: this.$t("Machine"), value: "machine", width: 110, class: "header_machine" },
        {
          text: this.$t("Machine Status"),
          value: "machine_status",
          width: 115,
          class: "header_machine_status"
        },
        { text: this.$t("Zone"), value: "zone", width: 110, class: "header_zone" },
        { text: this.$t("Part"), value: "part", width: 110, class: "header_part" },
        {
          text: this.$t("Production Time"),
          value: "production_time",
          width: 110,
          class: "header_production_time"
        },
        {
          text: this.$t("Target (units)"),
          value: "target_units",
          width: 95,
          class: "header_target_units"
        },
        { text: this.$t("Actual (units)"), value: "actual", width: 95, class: "header_actual" },
        { text: this.$t("Gap"), value: "gap", width: 95, class: "header_gap", align: "center" },
        { text: this.$t("Average (UPH)"), value: "average", width: 95, class: "header_average" },
        { text: this.$t("Scrap (units)"), value: "scrap", width: 95, class: "header_scrap" },
        {
          text: this.$t("Efficiency (UPH)"),
          value: "efficiency",
          width: 95,
          class: "header_efficiency"
        }
      ],
      expanded: [],
      header_options: { "sort-icon": "mdi-minus" },
      sorted_items: [],
      reload: 0
    };
  },
  created() {
    this.loadShift();
    this.getOperatorData();
  },
  computed: {
    ...mapGetters({
      vw: "app/VW",
      user: "session/User"
    }),
    colors() {
      return {
        peachPuff,
        tiffanyBlue,
        coralReef
      };
    }
  },
  methods: {
    setFilter(filter, e) {
      if (e != null) {
        this.filters[filter] = e;
      } else {
        delete this.filters[filter];
      }
      this.getOperatorData();
      this.reload = !this.reload;
    },
    getOperatorData: async function () {
      if (this.fetchDataDebounce) {
        clearTimeout(this.fetchDataDebounce);
      }
      this.items = [];
      this.filteredItems = [];
      this.loading = true;
      this.fetchDataDebounce = setTimeout(async () => {
        const from = this.from_date ? this.from_date.toISOString() : null;
        const to = this.to_date ? this.to_date.toISOString() : null;
        let params = {
          from_date: from,
          to_date: to
        };
        if ("name" in this.filters) {
          params["username"] = this.filters["name"];
        }
        if ("part" in this.filters) {
          params["part_number"] = this.filters["part"];
        }
        if ("machine" in this.filters) {
          params["machine_name"] = this.filters["machine"];
        }
        if ("zone" in this.filters) {
          params["zone"] = this.filters["zone"];
        }

        const result = await this.$http.get("/operator/metrics/", { params });

        this.items = this.CreateItemsData(result.data.results);
        this.filteredItems = this.filterItems(this.textFilter);
        this.sorted_items = this.filteredItems.slice();
        this.filterSets();
        this.loading = false;
        this.createDropdownElements();
      }, 500);
    },
    CreateItemsData(data) {
      const items = [];
      data.forEach((d, i) => {
        const item = {
          key: i,
          name: d.last_record.username,
          avatar: d.last_record.avatar,
          role: d.last_record.role,
          status: "1",
          machine: d.last_record.machine_name,
          machine_status: d.current_machine_status || "",
          end_time: d.last_record.end_time,
          zone: d.last_record.zone,
          part: d.last_record.part_number,
          production_time: seconds(d.event_duration),
          event_duration: d.event_duration,
          target_units: d.production_target,
          actual: d.produced,
          gap: d.production_target - d.produced,
          average: GetAveragePerHour(d.produced, d.event_duration),
          scrap: d.scrapped,
          efficiencyVal:
            d.production_target > 0 ? Math.round((d.produced / d.production_target) * 100) : 0,
          efficiency: `${d.production_target > 0 ? Math.round((d.produced / d.production_target) * 100) + "%" : "--"}`,
          machines: d.machines
        };

        items.push(item);
      });
      return items;
    },
    createDropdownElements() {
      this.zoneSet = new Set();
      this.machineSet = new Set();
      this.partSet = new Set();
      this.operatorSet = new Set();

      if (this.items == null) {
        return;
      }

      for (const item of this.items) {
        this.zoneSet.add(item.zone);
        this.machineSet.add(item.machine);
        this.partSet.add(item.part);
        this.operatorSet.add(item.name);
      }
    },
    filterItems(filter) {
      if (!this.textFilter || this.textFilter == "") {
        return this.items;
      }
      const filteredItems = this.items.filter((item) => {
        return (
          (item.name && item.name.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.status && item.status.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.machine && item.machine.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.machine_status &&
            item.machine_status.name &&
            item.machine_status.name.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.zone && item.zone.toLowerCase().includes(this.textFilter.toLowerCase())) ||
          (item.part && item.part.toLowerCase().includes(this.textFilter.toLowerCase()))
        );
      });

      return filteredItems;
    },
    filterSets() {
      const keys = Object.keys(this.filters);
      const filteredOut = new Set();
      this.items.forEach((item) => {
        for (const k of keys) {
          if (item[k] != this.filters[k]) {
            filteredOut.add(item);
          }
        }
      });

      this.filteredItems = this.items.filter((item) => {
        return !filteredOut.has(item);
      });

      const filteredItemsPartNumbersMap = new Map();
      const filteredItemsMachinesMap = new Map();
      const filteredItemsZoneMap = new Map();

      this.filteredItems.forEach((operator) => {
        operator.machines.forEach((machine) => {
          const part_number = machine.last_record.part_number;
          const produced = machine.produced;
          const production_target = machine.production_target;

          const machine_id = machine.last_record.machine_id;
          const machine_name = machine.last_record.machine_name;
          const zone = machine.last_record.zone;
          /* console.log('zone', !this.filters['zone'], this.filters['zone']==zone)
          console.log('machine', !this.filters['machine'], this.filters['machine']==machine_name)
          console.log('part', !this.filters['part'],  this.filters['part']==part_number)
          console.log(zone, machine_name, part_number) */
          if (
            (!this.filters["zone"] || this.filters["zone"] == zone) &&
            (!this.filters["machine"] || this.filters["machine"] == machine_name) &&
            (!this.filters["part"] || this.filters["part"] == part_number)
          ) {
            if (filteredItemsPartNumbersMap.has(part_number)) {
              filteredItemsPartNumbersMap.get(part_number).produced += produced;
              filteredItemsPartNumbersMap.get(part_number).production_target += production_target;
            } else {
              filteredItemsPartNumbersMap.set(part_number, {
                produced: produced,
                part_number: part_number,
                production_target: production_target
              });
            }

            if (filteredItemsMachinesMap.has(machine_id)) {
              filteredItemsMachinesMap.get(machine_id).produced += produced;
              filteredItemsMachinesMap.get(machine_id).production_target += production_target;
            } else {
              filteredItemsMachinesMap.set(machine_id, {
                produced: produced,
                machine_name: machine_name,
                production_target: production_target
              });
            }

            if (filteredItemsZoneMap.has(zone)) {
              filteredItemsZoneMap.get(zone).produced += produced;
              filteredItemsZoneMap.get(zone).production_target += production_target;
            } else {
              filteredItemsZoneMap.set(zone, {
                produced: produced,
                zone: zone,
                production_target: production_target
              });
            }
          }
        });
      });

      this.filteredItemsPartNumbers = [];
      this.filteredItemsMachines = [];
      this.filteredItemsZone = [];

      filteredItemsPartNumbersMap.forEach((value, key) => {
        this.filteredItemsPartNumbers.push(value);
      });

      filteredItemsMachinesMap.forEach((value, key) => {
        this.filteredItemsMachines.push(value);
      });

      filteredItemsZoneMap.forEach((value, key) => {
        this.filteredItemsZone.push(value);
      });
    },
    ChangeNumberRecords(e) {
      const index = { 0: 10, 1: 25, 2: 50, 3: null };
      this.numberRecords = index[e];

      let start = this.recordRange.start;
      let end = this.recordRange.start - 1 + this.numberRecordsPerPage;

      if (index[e] != null && end >= index[e] - 1) {
        end = index[e] - 1;
        start = Math.max(index[e] - this.numberRecordsPerPage, 0);
      }

      if (index[e] == null && end > this.filteredItems.length - 1) {
        end = this.filteredItems.length - 1;
      }

      this.recordRange = { start: start, end: end };
    },
    loadShift() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{shifts(current:true){
            name
            shiftDays(current:true){
              lastStartDatetime
              nextStartDatetime
              nextEndDatetime
            }
          }
        }`
            }
          })
          .then((res) => {
            if (res.data.data.shifts.length > 0) {
              this.shift = res.data.data.shifts[0];
            }
          })
          .catch((e) => {
            reject();
          });
      });
    },
    presetClick(preset) {
      let from_date = moment();
      let to_date = moment();
      switch (preset) {
        case "shift":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          if (this.shift && this.shift.shiftDays.length == 1) {
            from_date = moment(this.shift.shiftDays[0].nextStartDatetime);
            to_date = moment(this.shift.shiftDays[0].nextEndDatetime);
          }
          break;
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          break;
        case "year":
          from_date = moment().endOf("month").subtract(12, "months");
          to_date = moment().endOf("month");
          break;
      }
      this.from_date = from_date.toDate();
      this.to_date = to_date.toDate();
      this.preset = preset;
    },
    rowClick(item) {
      if (this.expanded.includes(item)) {
        this.expanded = [];
      } else {
        this.expanded = [];
        //Bcole: For some reason setting expanded on
        //same frame without clearing it first results
        //in a visual glitch
        window.requestAnimationFrame(() => {
          this.expanded = [item];
        });
      }
    },
    GetGapColor(gap, target_units, actual) {
      if (actual < target_units) {
        return "bad";
      } else {
        return "good";
      }
    }
  },
  watch: {
    from_date: function () {
      if (this.from_date && this.to_date) {
        this.getOperatorData();
        this.reload++;
      }
    },
    to_date: function () {
      if (this.from_date && this.to_date) {
        this.getOperatorData();
        this.reload++;
      }
    }
  }
};
</script>
