<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="slide-fade">
    <div class="quality-chart metric-component">
      <h2
        class="text-subtitle-2 color--text-primary font-weight-medium text-uppercase d-block mb-3"
      >
        {{ $t("Quality") }}
      </h2>

      <v-progress-linear
        v-if="!(production && production.length > 0)"
        indeterminate
      ></v-progress-linear>

      <div
        v-else
        class="charts"
      >
        <div class="mb-5 text-h5 color--text-primary font-weight-medium">
          {{ Math.round(quality_percentage) }}{{ "%".trim() }}
        </div>
        <div class="bar-container">
          <bar-chart
            v-if="chartData"
            chart-id="qualityChart"
            chart-name="qualityChart"
            class="qualityChart"
            uom="%"
            :decimal="2"
            :chart-data="chartData"
            :chart-options="chartOptions"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BarChart from "@/ui/charts/old/BarChart.js";

export default {
  components: {
    BarChart
  },
  props: {
    options: {
      type: Object,
      default: undefined
    },
    production: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      chartData: false,
      quality_percentage: false
    };
  },
  computed: {
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    },
    theme: {
      handler: "format_data"
    }
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    format_data() {
      let percentage = [];
      let targets = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let total_scrapped = 0;
      let total_produced = 0;
      if (this.production) {
        this.production.forEach((interval) => {
          let scrapped = interval.scrapped.value;
          let produced = interval.produced.value;
          let quality_percentage = interval.quality ? interval.quality.value * 100 : 0;
          percentage.push(quality_percentage);
          targets.push(this.options.targets.quality);
          if (quality_percentage >= this.options.targets.quality) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          footers.push([
            this.$t("Scrapped") + " : " + scrapped,
            this.$t("Produced") + " : " + produced
          ]);

          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          total_scrapped += scrapped;
          total_produced += produced;
        });
        this.quality_percentage = ((total_produced - total_scrapped) / total_produced) * 100;
      }
      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 0,
            hoverPointRadius: 0,
            borderColor: this.theme == "dark" ? "white" : "#44B2F3",
            titles: titles,
            fill: false,
            label: this.$t("Target")
          },
          {
            data: percentage,
            gradients: gradients,
            titles: titles,
            footers: footers,
            label: this.$t("Quality")
          }
        ],
        labels: labels
      };
    }
  }
};
</script>

<style scoped lang="scss">
.quality-chart {
  .charts {
    display: block;
    height: auto;

    .bar-container {
      width: 100%;

      .qualityChart {
        width: 100%;
        padding: 0 !important;
        height: 250px;
        display: block;
        position: relative;

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>
