var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card full-height"},[_c('div',{staticClass:"header"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Major Losses")))]),_vm._v(" "),_c('div',{staticClass:"icons"},[_c('i',{staticClass:"fa fa-table",class:_vm.chartType === _vm.chartTypes.TABLE ? 'selected' : null,on:{"click":() => {
            _vm.chartType = _vm.chartTypes.TABLE;
          }}}),_vm._v(" "),_c('i',{staticClass:"fa fa-bar-chart",class:_vm.chartType === _vm.chartTypes.CHART ? 'selected' : null,on:{"click":() => {
            _vm.chartType = _vm.chartTypes.CHART;
          }}})])]),_vm._v(" "),(_vm.chartType == _vm.chartTypes.CHART && !_vm.loading)?_c('div',{staticClass:"chart-container"},[(_vm.majorLosses.length > 0)?_c('div',{staticClass:"bar-container"},[_c('div',{staticClass:"bar-ticks"},[_c('div',{staticClass:"tick-holder"},_vm._l((11),function(i){return _c('div',{staticClass:"tick"},[_c('span',[_vm._v(_vm._s((i - 1) * 10))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"horizontal-bar"},_vm._l((_vm.majorLosses.filter(
            (majorLoss) => this.localFiltered.indexOf(majorLoss.status_codes[0]) < 0
          )),function(majorLoss,i){return _c('div',{key:i,staticClass:"major_loss_bar",class:_vm.machineStatusClassName(majorLoss.status),style:({
            flex: (majorLoss.value / (_vm.totalFilteredDowntime || 1)) * 100
          }),on:{"mouseenter":(e) => {
              _vm.tooltip(e, true);
            },"mouseleave":(e) => {
              _vm.tooltip(e, false);
            },"mousemove":(e) => {
              _vm.tooltip(e, true);
            },"click":() => {
              _vm.handleClick(majorLoss.status_codes);
            }}},[_c('div',{staticClass:"tooltip"},[_c('div',{staticClass:"tooltip-content"},[_vm._v("\n              "+_vm._s(majorLoss.name)+" :\n              "+_vm._s(((majorLoss.value / (_vm.totalDowntime || 1)) * 100).toFixed(2))+"%\n            ")]),_vm._v(" "),_c('div',{staticClass:"tooltip-pointer"})]),_vm._v(" "),_c('div',{staticClass:"major_loss_text"},[_c('div',[_vm._v(_vm._s(((majorLoss.value / (_vm.totalDowntime || 1)) * 100).toFixed(2))+"%")])])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"legend"},_vm._l((_vm.majorLosses),function(majorLoss,i){return _c('div',{staticClass:"legend-container",on:{"click":() => {
            _vm.handleClickLegend(majorLoss.status_codes[0]);
          }}},[_c('div',{staticClass:"swatch",class:_vm.machineStatusClassName(majorLoss.status)},[_c('i',{staticClass:"cross-out mdi mdi-close",style:({
              visibility:
                _vm.localFiltered.indexOf(majorLoss.status_codes[0]) < 0 ? 'hidden' : 'visible'
            })})]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n          "+_vm._s(majorLoss.name)+" "+_vm._s(((majorLoss.value / (_vm.totalDowntime || 1)) * 100).toFixed(2))+"%\n        ")])])}),0)]):(_vm.chartType == _vm.chartTypes.TABLE && !_vm.loading)?_c('div',{staticClass:"table-container"},[_c('GenericTable',{attrs:{"columns":[
        { title: '', key: 'name' },
        { title: _vm.$t('Duration'), key: 'duration', format: _vm.seconds, sortable: true },
        { title: _vm.$t('Total Downtime'), key: 'total_downtime', format: _vm.seconds, sortable: false },
        {
          title: _vm.$t('Downtime Percent'),
          key: 'downtime_percentage',
          format: _vm.percent,
          sortable: true
        },
        { title: _vm.$t('Production Loss'), key: 'loss', format: _vm.percent, sortable: true }
      ],"data":_vm.tableData}})],1):_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }