var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aivision_datagrid"},[_c('div',{staticClass:"datagrid"},[_c('generic-table',{attrs:{"columns":[
        { title: _vm.$t('Equipment'), key: 'equipment', sortable: true },
        { title: _vm.$t('Part Number'), key: 'part_name', sortable: true },
        {
          title: _vm.$t('Total Processed'),
          key: 'total_processed',
          format: _vm.numberWithCommas,
          sortable: true
        },
        { title: _vm.$t('Passed'), key: 'passed', format: _vm.numberWithCommas, sortable: true },
        { title: _vm.$t('Failed'), key: 'failed', format: _vm.numberWithCommas, sortable: true },
        { title: _vm.$t('FPY(%)'), key: 'passed_percent', format: (e) => `${e}%`, sortable: true },
        { title: _vm.$t('Reject %'), key: 'reject_percent', format: (e) => `${e}%`, sortable: true }
      ],"nested_columns":[
        { key: '' },
        { key: 'part_name' },
        { key: 'total_processed', format: _vm.numberWithCommas },
        { key: 'passed', format: _vm.numberWithCommas },
        { key: 'failed', format: _vm.numberWithCommas },
        { key: 'passed_percent', format: (e) => `${e}%` },
        { key: 'reject_percent', format: (e) => `${e}%` }
      ],"data":_vm.table_data}})],1),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"csv_button_container"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createCSV}},[_c('div',{staticClass:"buttoncontent"},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t("CSV"))+"\n          ")])])])],1),_vm._v(" "),_c('div',{staticClass:"paginator_container"},[_c('div',{staticClass:"paginator"},[_c('div',{staticClass:"selector"},[_c('div',{staticClass:"rows-title"},[_vm._v(_vm._s(_vm.$t("Rows per page")))]),_vm._v(" "),_c('select',{on:{"change":_vm.handleRequestCountChange}},[_c('option',{attrs:{"value":"10"},domProps:{"selected":_vm.count === 10}},[_vm._v("\n              10\n            ")]),_vm._v(" "),_c('option',{attrs:{"value":"50"},domProps:{"selected":_vm.count === 50}},[_vm._v("\n              50\n            ")]),_vm._v(" "),_c('option',{attrs:{"value":"100"},domProps:{"selected":_vm.count === 100}},[_vm._v("\n              100\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"range-indicator"},[_c('div',[_c('span',[_vm._v("\n              "+_vm._s(_vm.currentStart !== null ? _vm.currentStart + 1 : "---")+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v(" - ")]),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.currentStart !== null ? Math.min(_vm.currentStart + Number(_vm.count), _vm.totalcount) : "---")+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v(" of "+_vm._s(_vm.totalcount || "---")+" ")])])]),_vm._v(" "),_c('div',{staticClass:"back-next-select"},[_c('i',{staticClass:"fa fa-chevron-left",class:`${this.currentStart <= 0 && 'disabled'}`,on:{"click":() => {
                this.handlePage(-1, this.currentStart <= 0);
              }}}),_vm._v(" "),_c('i',{staticClass:"fa fa-chevron-right",class:`${this.currentStart + Number(_vm.count) >= _vm.totalcount && 'disabled'}`,on:{"click":() => {
                this.handlePage(1, this.currentStart + Number(_vm.count) >= _vm.totalcount);
              }}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }