<template>
  <v-switch
    v-model="currentTheme"
    inset
    class="switch-theme my-0 py-0 align-center"
    true-value="dark"
    false-value="light"
    hide-details
    @change="updateTheme(currentTheme)"
  >
    <template #prepend>
      <i
        class="switch-theme-icon"
        :class="{ [themeIcon]: themeIcon }"
        aria-hidden="true"
      ></i>
    </template>
    <template #label>
      <span
        v-if="label"
        class="text-subtitle-1 color--text-primary"
        >{{ label }}
      </span>
    </template>
  </v-switch>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from "vue";
import { useThemeSwitcher } from "@/shared/useThemeSwitcher";

export default {
  name: "ThemeSwitcher",
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const store = vm.$store;
    const theme = computed(() => store.getters["app/Theme"]);
    const currentTheme = ref(theme.value);
    const themeIcon = computed(() => {
      if (currentTheme.value === "dark") {
        return "fa fa-moon-o";
      }
      return "fa fa-sun-o";
    });

    const { updateTheme } = useThemeSwitcher();

    watch(theme, (val) => {
      currentTheme.value = val;
    });

    return {
      currentTheme,
      themeIcon,
      updateTheme
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";

.navigation #sidenav .nav-item.nav-item-settings .switch-theme ::v-deep .v-label {
  margin-left: rem(8px) !important;
}

.switch-theme {
  position: relative;

  &::v-deep:not(.v-input--is-label-active) .v-input__prepend-outer {
    left: rem(23px);
  }

  ::v-deep {
    .v-input__prepend-outer {
      position: absolute;
      left: rem(2px);
      margin: 0;
    }
  }
}

.switch-theme-icon {
  color: var(--accent-700) !important;
  margin: 0 !important;
  font-size: rem(18px) !important;
}
</style>
