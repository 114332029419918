<template>
  <div
    @mouseover="toggleHover(true)"
    @mouseleave="toggleHover(false)"
  >
    {{ hover ? text : formattedText }}
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "TextOverflow",
  props: {
    text: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: 300
    }
  },
  setup(props) {
    const hover = ref(false);
    const formattedText = computed(() => {
      if (props.text.length > props.maxLength) {
        return `${props.text.substring(0, props.maxLength)}...`;
      } else {
        return props.text;
      }
    });

    const toggleHover = (value) => {
      hover.value = value;
    };

    return { formattedText, toggleHover, hover };
  }
};
</script>

<style scoped></style>
