<template>
  <Card
    class="mb-4 pa-5 rounded-lg"
    custom-padding
  >
    <MobileOnlyExpansion>
      <template #header>
        <span class="text-body-1 font-weight-medium color--text-primary">{{ $t("Details") }}</span>
      </template>
      <template #content>
        <div class="type color--text-primary mt-6">
          <span class="color--text-secondary">{{ $t("Ref ID") }}</span>
          <div class="d-flex align-center">
            <span
              v-if="ticket.reference"
              class="mr-7"
              >{{ ticket.reference }}</span
            >
            <Btn
              text
              class="pa-0 ml-n5"
              @click="showSubscribeDialog = true"
              @close="showSubscribeDialog = false"
            >
              {{ ticket.reference ? $t("Edit") : $t("Add") }}
            </Btn>
          </div>
          <span class="color--text-secondary">{{ $t("Created by") }}</span>
          <span>{{ ticket.ticketedByName }}</span>
          <span class="color--text-secondary">{{ $t("Date") }}</span>
          <span>{{ ticketCreatedDate }}</span>
          <span class="color--text-secondary">{{ $t("Machine") }}</span>
          <span>{{ ticket.machineName }}</span>
          <span class="color--text-secondary">{{ $t("Part") }}</span>
          <span>{{ ticket.partName }}</span>
          <span class="color--text-secondary">{{ $t("Factory Order") }}</span>
          <span>{{ ticket.factoryOrder }}</span>
        </div>
      </template>
    </MobileOnlyExpansion>

    <TicketDetailsSubscribeDialog
      :open="showSubscribeDialog"
      :ticket="ticket"
      @close="showSubscribeDialog = false"
      @success="$emit('success')"
    />
  </Card>
</template>

<script setup>
import moment from "moment-timezone";
import { computed, defineProps, ref } from "vue";

import Btn from "@/ui/Btn";
import Card from "@/ui/Card";
import MobileOnlyExpansion from "@/ui/MobileOnlyExpansion";
import TicketDetailsSubscribeDialog from "@/features/ticket-details/components/TicketDetailsSubscribeDialog.vue";

const props = defineProps({
  ticket: {
    type: Object,
    default: () => ({})
  }
});

const FULL_DATE_FORMAT = "DD MMM YYYY, HH:mm A";

const showSubscribeDialog = ref(false);
const ticketCreatedDate = computed(() =>
  moment(props.ticket.ticketedDate).format(FULL_DATE_FORMAT)
);

defineExpose({
  ticketCreatedDate,
  showSubscribeDialog
});
</script>

<style lang="scss" scoped>
.type {
  display: grid;
  gap: 24px 60px;
  grid-template-columns: auto 1fr;

  span {
    margin: auto 0;
  }
}
</style>
