var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-parts-cost metric-component"},[_c('h2',{staticClass:"card-title text-subtitle-2 color--text-primary text-uppercase font-weight-medium mb-16"},[_vm._v("\n    "+_vm._s(_vm.$t("Top 10 Product Codes"))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"charts_container"},[_c('div',{staticClass:"chart"},[(_vm.chart_data[_vm.metric.node.name])?_c('HBarChart',{staticClass:"dynamicChart",attrs:{"chartID":_vm.metric.node.name + 'parts',"chart-name":_vm.metric.node.name + 'parts',"chart-data":_vm.chart_data[_vm.metric.node.name],"chart-options":{
          scales: {
            xAxes: [
              {
                ticks: {
                  minRotation: 90,
                  maxRotation: 90
                }
              }
            ]
          }
        }}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }