<template>
  <fragment>
    <slot name="default"></slot>
  </fragment>
</template>

<script>
import { provide, ref, watch } from "vue";
import { Fragment } from "vue-fragment";
import { KIOSK_SCREEN_HIDE, KIOSK_SCREEN_KEY, KIOSK_SCREEN_SHOW } from "@/common/kiosk/constants";

export default {
  name: "KioskProvider",
  components: {
    Fragment
  },
  setup() {
    const kioskScreenKey = ref("");
    const showKioskScreen = (key) => {
      kioskScreenKey.value = key;
    };
    const hideKioskScreen = () => {
      kioskScreenKey.value = "";
    };

    watch(kioskScreenKey, (key) => {
      if (key) {
        document.body.classList.add("kiosk");
      } else {
        document.body.classList.remove("kiosk");
      }
    });

    provide(KIOSK_SCREEN_KEY, kioskScreenKey);
    provide(KIOSK_SCREEN_SHOW, showKioskScreen);
    provide(KIOSK_SCREEN_HIDE, hideKioskScreen);

    return {
      showKioskScreen,
      hideKioskScreen
    };
  }
};
</script>

<style lang="scss">
.kiosk {
  .banner {
    display: none;
  }
  .content {
    top: 0;
  }
}
</style>
