<template>
  <div class="pa-6 d-flex flex-column page">
    <div class="text-body-2 font-weight-medium mb-4">
      <a @click="getBackToTickets">{{ $t("Tickets") }}</a>
      <span class="mx-1 font-weight-regular">/</span>
      <span class="">{{ ticket.machineName }}</span>
    </div>

    <div class="composition">
      <div class="ticket-header d-flex align-center mb-1">
        <h1 class="text-h5 font-weight-medium">{{ ticket.machineName }}</h1>

        <!-- TODO: Add edit and similar solutions functionality -->
        <!-- <Btn
          large
          class="ml-auto"
          ><v-icon
            size="24"
            class="mr-1"
            >mdi-pencil-outline</v-icon
          >{{ $t("Edit") }}</Btn
        >
        <Btn
          large
          class="ml-3"
          ><v-icon
            size="24"
            class="mr-1"
            >mdi-arrow-decision-outline</v-icon
          >{{ $t("Similar solutions") }}</Btn
        > -->
      </div>

      <div class="ticket-body mb-10">
        <div class="type text-body-1 color--text-secondary mb-3">
          <span>{{ $t("Category") }}</span>
          <span>{{ categoryName }}</span>
          <span class="text-subtitle-1 color--text-primary">{{ $t("Quality") }}</span>
          <span>
            <v-chip
              v-for="(issue, issueIndex) in issues"
              :key="issueIndex"
              :color="issue.color"
              class="color--text-contrast"
              label
              >{{ issue.name }}</v-chip
            >
          </span>
        </div>

        <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-2">{{ $t("Description") }}</h2>
          <p class="text-subtitle-1">
            {{ ticket.details }}
          </p>
        </div>

        <!-- TODO: Add solution functionality -->
        <!-- <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-2">{{ $t("Solution") }}</h2>
          <p class="text-subtitle-1">
            {{ ticket.details }}
          </p>
        </div> -->
      </div>

      <TicketsDetailsControl
        class="ticket-control"
        :ticket="ticket"
        :users="users"
        @assign="refetch"
      />

      <TicketsDetailsActivity
        class="ticket-comments"
        :filters="filters"
        :new-comment-params="commentParams"
        :tabs="[
          {
            label: $t('All')
          },
          {
            label: $t('Comments')
          },
          { label: $t('History') }
        ]"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";

import TicketsDetailsActivity from "./components/TicketDetailsActivity.vue";
import TicketsDetailsControl from "./components/TicketDetailsControl.vue";
import { useTicketDetails } from "./useTicketDetails";

const $route = useRoute();
const $router = useRouter();

const query = $route.query;

// TODO: tech debt MIM-5019
const categoryName = computed(() => query.categoryName);
const categoryColor = computed(() => query.categoryColor);
const issues = computed(() => {
  const itemsName = Object.keys(query).filter((key, index) =>
    key.startsWith(`items_${index}_name`)
  );
  return itemsName.map((_, index) => {
    const name = query[`items_${index}_name`];
    const color = query[`items_${index}_color`];
    return { name, color };
  });
});

const variables = computed(() => ({
  id: $route.params.ticket_id
}));
const { ticket, users, refetch } = useTicketDetails(variables);

const getBackToTickets = () => {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    $router.push({ name: "tickets" });
  }
};

const filters = computed(() => ({
  ticketId: { value: $route.params.ticket_id }
}));

const commentParams = computed(() => ({
  ticketId: $route.params.ticket_id
}));

defineExpose({
  categoryName,
  categoryColor,
  issues,
  commentParams,
  filters,
  ticket,
  users,
  getBackToTickets,
  refetch
});
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/mq";

.type {
  display: grid;
  gap: 6px 60px;
  grid-template-columns: auto 1fr;
}

.composition {
  display: grid;
  grid-template-areas:
    "header control"
    "body control"
    "comments control";
  column-gap: 32px;
  grid-template-columns: auto 460px;
  grid-template-rows: auto auto 1fr;

  @include media_below(1100px) {
    grid-template-areas:
      "header"
      "body"
      "control"
      "comments";
    column-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.ticket-header {
  grid-area: header;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.ticket-body {
  grid-area: body;
}

.ticket-body-item {
  & ~ .ticket-body-item {
    margin-top: 16px;
  }
}

.ticket-control {
  grid-area: control;
}

.ticket-comments {
  grid-area: comments;
}
</style>
