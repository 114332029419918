<template>
  <div>
    <v-row class="mx-1 my-1">
      <v-col>
        <div class="card">
          <v-row class="mx-1 my-1">
            <v-col cols="3">
              <v-row>
                <v-col
                  class="d-flex justify-center align-center mx-2 my-2"
                  cols="3"
                >
                  <user-avatar :user="selectedOperator" />
                </v-col>
                <v-col
                  ><h1>{{ name }}</h1>
                </v-col>
              </v-row>
              <v-row class="mb-1">
                <v-col>
                  <v-row
                    v-for="reaction in reactions"
                    :key="reaction.image"
                  >
                    <v-col
                      cols="2"
                      class="d-flex align-center my-1 py-0"
                    >
                      <v-avatar
                        min-height="33px"
                        min-width="33px"
                        height="33px"
                        width="33px"
                      >
                        <img
                          :src="reaction.image"
                          :alt="`${reaction.type}`"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col class="d-flex align-center py-0">{{ reaction.count }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-col class="text-center">
                  <p class="mb-2">{{ $t("Efficiency") }}</p>
                  <div class="gauge-container mx-auto">
                    <v-gauge
                      class="mx-auto my-auto"
                      gaugeValueClass="gaugeValue"
                      :value="(100 * produced) / productionTarget"
                      :donut="true"
                      :options="gaugeOpts"
                    />
                  </div>
                </v-col>
                <v-col class="text-center">
                  <p class="mb-2">{{ $t("All Time Best") }}</p>
                  <v-responsive aspect-ratio="1">
                    <v-sheet
                      color="#FFC794"
                      class="mx-auto d-flex align-center justify-center"
                      style="
                        border-radius: 50%;
                        width: 125px;
                        height: 125px;
                        flex-direction: column;
                      "
                    >
                      <div class="black--text headline font-weight-bold">
                        {{ bestUnitsPerHourValue.toLocaleString() }}
                      </div>
                      <div class="black--text headline font-weight-bold">
                        {{ $t("UPH") }}
                      </div>
                    </v-sheet>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="operatorSummary !== {}">
              <v-row>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Production Time") }}
                  </p>
                  <p>
                    {{ seconds(operatorSummary["total_run_time"]) }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Target (units)") }}
                  </p>
                  <p>
                    {{ productionTarget.toLocaleString() }}
                  </p>
                </v-col>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Actual (units)") }}
                  </p>
                  <p>
                    {{ produced.toLocaleString() }}
                  </p>
                </v-col>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Gap (units)") }}
                  </p>
                  <p>
                    {{ (productionTarget - produced).toLocaleString() }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <p class="blue-text">{{ $t("Target (units/hour)") }}</p>
                  <p>
                    {{ ((productionTarget / operatorSummary["total_run_time"]) * 3600).toFixed(0) }}
                  </p>
                </v-col>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Actual (units/hour)") }}
                  </p>
                  <p>
                    {{ ((produced / operatorSummary["total_run_time"]) * 3600).toFixed(0) }}
                  </p>
                </v-col>
                <v-col class="text-center">
                  <p class="blue-text">
                    {{ $t("Scrap (units)") }}
                  </p>
                  <p>{{ scrap.toLocaleString() }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <v-row class="mt-0">
              <v-col>
                <div class="card">
                  <production-trend-chart :api-response="productionTrendData" />
                </div>
              </v-col>
              <v-col>
                <div class="card">
                  <machine-break-down-chart :api-response="machineBreakdownData" />
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col>
                <div class="card">
                  <part-break-down-chart :api-response="partBreakdownData" />
                </div>
              </v-col>
              <v-col>
                <div class="card">
                  <zone-break-down-chart :api-response="zoneBreakdownData" />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "@lottiefiles/lottie-player";
import VGauge from "vgauge";

import person from "../../../assets/lottie/person-profile.json";
import { seconds } from "../../../utils/filters";
import MachineBreakDownChart from "./MachineBreakdownChart.vue";
import PartBreakDownChart from "./PartBreakdownChart.vue";
import ProductionTrendChart from "./ProductionTrendChart.vue";
import ZoneBreakDownChart from "./ZoneBreakdownChart.vue";
import UserAvatar from "@/ui/UserAvatar";

export default {
  name: "OperatorScorecard",
  props: ["sortedItems", "from_date", "to_date", "preset"],
  components: {
    ZoneBreakDownChart,
    PartBreakDownChart,
    MachineBreakDownChart,
    ProductionTrendChart,
    UserAvatar,
    VGauge
  },
  data() {
    return {
      person: JSON.stringify(person),
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      operatorSummary: {},
      bestUnitsPerHour: null,
      operatorID: null,
      productionTrendData: {},
      machineBreakdownData: {},
      partBreakdownData: {},
      zoneBreakdownData: {},
      selectedOperator: null,
      zoneLevel: null
    };
  },
  mounted() {
    if (this.sortedItems.length === 1) {
      this.selectedOperator = this.sortedItems[0];
    }
    this.getOperatorSummary();
    this.getBestUnitsPerHour();
    this.getProductionTrendData();
    this.getMachineBreakdownData();
    this.getPartBreakdownData();
    this.getZoneBreakdownData();
    this.getUserReactions();
  },
  methods: {
    seconds,
    async getOperatorSummary() {
      try {
        const res = await this.$http.get("/operator/summary/", {
          params: {
            username: this.selectedOperator.name,
            to_date: this.to_date,
            from_date: this.from_date
          }
        });
        this.operatorSummary = res.data.results;
      } catch (error) {
        console.error(error);
      }
    },
    async getUserID() {
      const query = `
    {
      users(username: "${this.selectedOperator.name}") {
        pk
      }
    }
  `;
      try {
        const res = await this.$http.post("/graphql/", { query });
        this.operatorID = res.data.data.users[0].pk;
      } catch (error) {
        console.error(error);
      }
    },
    async getBestUnitsPerHour() {
      await this.getUserID();
      const query = `
    {
      user(userId: ${this.operatorID}) {
        bestUnitsPerHour
      }
    }
  `;
      try {
        const res = await this.$http.post("/graphql/", { query });
        this.bestUnitsPerHour = res.data.data.user.bestUnitsPerHour;
      } catch (error) {
        console.error(error);
      }
    },
    async getProductionTrendData() {
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        interval: this.preset,
        username: this.selectedOperator.name
      };

      try {
        const response = await this.$http.get("/operator/production_trend/", {
          params
        });
        this.productionTrendData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMachineBreakdownData() {
      const machine_ids = this.selectedOperator.machines.map((item) => item.last_record.machine_id);
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        username: this.selectedOperator.name,
        machine_ids: machine_ids
      };
      try {
        const response = await this.$http.get("/operator/machine_breakdown/", {
          params
        });
        this.machineBreakdownData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPartBreakdownData() {
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        username: this.selectedOperator.name
      };
      try {
        const response = await this.$http.get("/operator/part_breakdown/", {
          params
        });
        this.partBreakdownData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getZoneLevel() {
      const query = `
        {
          settings {
            operatorScorecardZoneLevel {
              level
            }
          }
        }
      `;
      try {
        const res = await this.$http.post("/graphql/", { query });
        this.zoneLevel = res.data.data.settings[0].operatorScorecardZoneLevel.level;
      } catch (error) {
        console.error(error);
      }
    },
    async getZoneBreakdownData() {
      await this.getZoneLevel();
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        username: this.selectedOperator.name,
        zone_level: this.zoneLevel
      };
      try {
        const response = await this.$http.get("/operator/zone_breakdown/", {
          params
        });
        this.zoneBreakdownData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUserReactions() {
      const params = {
        username: this.selectedOperator.name
      };
      try {
        const response = await this.$http.get("/reaction/reaction_count/", {
          params
        });
        this.$set(this.selectedOperator, "reactions", response.data);
      } catch (error) {
        console.error(error);
      }
    },
    gaugeValue(value) {
      if (!value) {
        return "gv";
      }
      if (value.value.includes("%")) {
        return "gv percent";
      }
      if (value.value.includes("$")) {
        return "gv dollar";
      }
      return "gv";
    }
  },
  computed: {
    avatar() {
      if (this.selectedOperator) {
        return this.selectedOperator.avatar;
      }
      return "";
    },
    name() {
      if (this.selectedOperator) {
        return this.selectedOperator.name;
      }
      return "";
    },
    reactions() {
      if (this.selectedOperator && this.selectedOperator.reactions) {
        return this.selectedOperator.reactions;
      }
      return [];
    },
    bestUnitsPerHourValue() {
      if (this.bestUnitsPerHour && this.bestUnitsPerHour.value) {
        return this.bestUnitsPerHour.value;
      }
      return 0;
    },
    productionTarget() {
      if (this.operatorSummary && this.operatorSummary.production_target) {
        return this.operatorSummary.production_target;
      }
      return 0;
    },
    produced() {
      if (this.operatorSummary && this.operatorSummary.produced) {
        return this.operatorSummary.produced;
      }
      return 0;
    },
    scrap() {
      if (this.operatorSummary && this.operatorSummary.scrap) {
        return this.operatorSummary.scrap;
      }
      return 0;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables.scss";
@import "../../../scss/metric";
.blue-text {
  color: $blue;
}
</style>
