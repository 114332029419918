<template>
  <v-theme-provider>
    <div class="root redesign">
      <div class="d-flex flex-wrap align-center justify-space-between">
        <div class="mb-4 buttons-container">
          <span class="mr-4 font-weight-medium text-subtitle-1 color--text-primary">{{
            $t("Activity")
          }}</span>
          <Btn
            v-for="(tabItem, tabIndex) in tabs"
            :key="tabIndex"
            class="rounded-pill mr-3 color--text-secondary"
            :variant="tab === tabIndex ? 'primary' : 'tertiary'"
            @click="
              tab = tabIndex;
              commentPageCurrent = 1;
            "
          >
            {{ tabItem.label }}
          </Btn>
        </div>

        <div class="search-container d-flex align-center mb-4 flex-grow-1 justify-space-between">
          <v-text-field
            v-model="searchComments"
            class="search pt-0 mt-0 rounded-lg"
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            single-line
            filled
            outlined
            hide-details
            clearable
          />

          <Btn
            v-if="hasUserRole(user, userRoles.Supervisor)"
            icon
            @click="editComments = !editComments"
          >
            <i
              class="fa fa-pencil"
              aria-hidden="true"
            ></i>
          </Btn>

          <Btn
            text
            class="text-uppercase ml-6 font-weight-bold"
            @click="sort"
          >
            <svg
              v-if="isDescDirection"
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="20px"
              width="18px"
            >
              <path
                fill="currentColor"
                d="M14.233 16.321v2.826L18 15.379l-3.767-3.767v2.825H0v1.884M0 .728h2.512v9.209H0zM5.023 3.24h2.512v6.698H5.023zM10.047 5.751h2.512v4.186h-2.512zM15.07 8.263h2.512v1.674H15.07z"
              />
            </svg>

            <svg
              v-if="!isDescDirection"
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="20px"
              width="18px"
            >
              <path
                fill="currentColor"
                d="M14.233 16.412v2.825L18 15.47l-3.767-3.768v2.826H0v1.884M0 8.354h2.512v1.674H0zM5.023 5.842h2.512v4.186H5.023zM10.047 3.33h2.512v6.698h-2.512zM15.07.819h2.512v9.209H15.07z"
              />
            </svg>

            {{ isDescDirection ? $t("Newest first") : $t("Oldest first") }}
          </Btn>
        </div>
      </div>

      <p class="text-body-1 color--text-secondary mb-2">{{ $t("Comment") }}</p>
      <div class="mb-4">
        <div class="comment-form rounded-lg">
          <v-textarea
            v-model="comment"
            filled
            outlined
            hide-details
            rows="1"
            name="input-7-4"
            class="text-form"
          />
          <FileUpload
            ref="fileUpload"
            class="file-upload"
            :acceptable-formats="uploadFormats"
            @select-file="selectFile"
            @delete-file="() => (imageBase64 = null)"
          />
        </div>

        <div
          v-if="comment !== ''"
          class="d-flex align-center mt-3"
        >
          <Btn
            large
            class="mr-3"
            variant="primary"
            width="150"
            :loading="savingComment"
            :disabled="comment == ''"
            @click="submitComment"
          >
            {{ $t("Add") }}
          </Btn>

          <Btn
            large
            variant="secondary"
            width="150"
            :disabled="comment == ''"
            @click="clearComment"
          >
            {{ $t("Cancel") }}
          </Btn>
        </div>

        <div
          v-if="errors && errors.length"
          class="mt-1 mb-4"
        >
          <p
            v-for="error in errors"
            :key="error"
            class="error--text"
          >
            {{ error }}
          </p>
        </div>
      </div>

      <v-data-iterator
        class="comments-wrapper"
        :items="comments"
        :items-per-page.sync="commentsPerPage"
        :page.sync="commentPageCurrent"
        :server-items-length="commentsTotal"
        :no-data-text="$t('No data')"
        :footer-props="{ class: 'footer', 'items-per-page-options': [5, 10, 25, 50] }"
        :loading="commentsLoading"
        :hide-default-footer="comments.length === 0"
      >
        <template #default="props">
          <div
            v-for="item in props.items"
            :key="item.node.id"
            class="comment px-4 py-3 rounded-lg"
          >
            <div class="d-flex">
              <div>
                <Btn
                  v-if="editComments && hasUserRole(user, userRoles.Supervisor)"
                  color="error"
                  light
                  fab
                  x-small
                  @click="hideComment(item)"
                >
                  <i
                    class="fa fa-minus"
                    aria-hidden="true"
                  ></i>
                </Btn>
                <p class="mr-2">
                  <span
                    v-if="item.node.userName.trim()"
                    class="mr-2 font-weight-medium text-subtitle-1 color--text-primary"
                  >
                    {{ item.node.userName }}
                  </span>
                  {{ item.node.body }}
                </p>
              </div>
              <time class="text-body-2 color--text-secondary ml-auto">
                {{ moment(item.node.date).format($datetime.default) }}
              </time>
            </div>
            <div
              v-if="item.node.imageUrl || item.node.videoUrl"
              class="comment-content mt-2"
            >
              <a
                v-if="item.node.imageUrl"
                class="preview py-1"
                :href="item.node.imageUrl || null"
                :target="item.node.imageUrl ? '_blank' : null"
              >
                <img
                  :src="item.node.imageUrl"
                  class="rounded-lg"
                />
              </a>
              <VideoPlayer
                v-if="item.node.videoUrl"
                class="rounded-lg"
                :file="item.node.videoUrl"
              />
            </div>
          </div>
        </template>
        <template #noData>
          {{ $t("No data") }}
        </template>
      </v-data-iterator>
    </div>
  </v-theme-provider>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import Btn from "@/ui/Btn";
import FileUpload from "@/ui/FileUpload";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { Dispatcher } from "@/utils/eventbus";
import { hasUserRole, userRoles } from "@/utils/user";
import {
  MACHINE_DELAY_BETWEEN_UPDATE_AND_GET,
  MACHINE_STATUS_CHANGE_EVENT
} from "@/features/machine/constants";
import { UPLOAD_IMAGE_VIDEO_FORMATS } from "@/features/constants";

export default {
  name: "CommentControlRestyled",
  components: {
    Btn,
    FileUpload,
    VideoPlayer
  },
  props: {
    filters: {
      type: Object,
      default: undefined
    },
    newCommentParams: {
      type: Object,
      default: undefined
    },
    special: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editComments: false,
      imageBase64: null,
      video: null,
      comments: [],
      commentsTotal: 0,
      commentPageCurrent: 1,
      commentsPerPage: 5,
      loadPage: null,
      nextPage: null,
      prevPage: null,
      searchComments: "",
      comment: "",
      newCommentSpecial: false,
      savingComment: false,
      commentsLoading: false,
      direction: "DESC",
      tab: 0,
      userRoles: userRoles,
      errors: [],
      uploadFormats: UPLOAD_IMAGE_VIDEO_FORMATS
    };
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    isDescDirection() {
      return this.direction === "DESC";
    }
  },
  watch: {
    reload: function () {
      let loadComments = this.loadComments;
      setTimeout(loadComments, 500);
    },
    filters: {
      deep: true,
      handler: function () {
        this.comments = [];
        this.loadComments();
      }
    },
    direction: function () {
      this.loadComments();
    },
    searchComments: function () {
      if (this.searchDebouncerComments) {
        clearTimeout(this.searchDebouncerComments);
      }
      let load = this.loadComments;
      this.searchDebouncerComments = setTimeout(load, 500);
    },
    commentsPerPage: function () {
      this.commentCurrentPage = 1;
      this.loadComments();
    },
    commentPageCurrent: function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.loadPage = {
          after: this.nextPage,
          before: false,
          first: this.commentsPerPage
        };
        this.loadComments();
      } else if (newPage < oldPage) {
        this.loadPage = {
          after: false,
          before: this.prevPage,
          last: this.commentsPerPage
        };
        this.loadComments();
      }
    },
    tab: function (tabIndex) {
      if (tabIndex === 2) {
        return;
      }
      this.loadComments();
    },
    video: function (newVal) {
      /*
        The server limit is 20 MB, but base64 encoding increases the file size by up to 33%.
        Therefore, we do not allow users to upload files larger than 15 MB
        (as stated in the error message).
      */
      if (newVal) {
        if (newVal.length > 20 * 1024 * 1024) {
          this.setFileSizeError();
          this.video = null;
        } else {
          this.errors = [];
        }
      }
    }
  },
  created() {
    this.loadComments();

    /* hard fix delay between submit request and date update */
    Dispatcher.$listen(MACHINE_STATUS_CHANGE_EVENT, () =>
      setTimeout(() => this.loadComments(), MACHINE_DELAY_BETWEEN_UPDATE_AND_GET)
    );
  },
  methods: {
    moment,
    hasUserRole,
    selectFile(file) {
      if (!file) {
        console.error("No file selected.");
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (file.type.startsWith("image")) {
            this.imageBase64 = reader.result;
          } else if (file.type.startsWith("video")) {
            this.video = reader.result;
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
        reader.readAsDataURL(file);
      }
    },
    loadComments() {
      const query = `query (
        $filters: GrapheneElasticFilterCommentSearchConnectionBackendFilter!,
        $direction: Direction!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String){
        comments(
          filter: $filters,
          ordering: {date: $direction},
          after: $after,
          before: $before,
          first: $first,
          last: $last,
          simpleQueryString: $search,
          facets: [user]){
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              special,
              userName,
              user,
              date,
              body,
              id,
              hidden
              imageUrl,
              videoUrl
            }
          }
        }
      }`;
      //TODO switch filter to use globalid instead of PK
      // Check creation too
      let filters = {
        // ...(this.tab === 1 ? { special: { value: this.tab === 1 } } : {}),
        hidden: { exclude: [true] }
      };
      const variables = {
        filters: _.merge(filters, this.filters),
        direction: this.direction,
        before: false,
        after: false,
        search: ""
      };

      if (this.searchComments) {
        variables["search"] = this.searchComments + "*";
      }
      if (this.loadPage) {
        Object.assign(variables, this.loadPage);
      } else {
        variables["first"] = this.commentsPerPage;
      }
      this.commentsLoading = true;
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.loadPage = null;
          this.nextPage = res.data.data.comments.pageInfo.endCursor;
          this.prevPage = res.data.data.comments.pageInfo.startCursor;
          this.comments = res.data.data.comments.edges;
          this.commentsTotal = res.data.data.comments.facets.user.doc_count;
        })
        .catch((res) => {
          this.errors = res.errors;
        })
        .finally(() => {
          this.commentsLoading = false;
        });
    },
    clearComment() {
      this.$refs.fileUpload.deleteFile();
      this.comment = "";
      this.imageBase64 = false;
      this.newCommentSpecial = false;
      this.video = null;
      this.errors = [];
    },
    submitComment() {
      this.errors = [];
      this.savingComment = true;
      const query = `mutation ($commentData: CommentInput!){
        createComment(commentData:$commentData) {
          id,
          body,
          image,
          video
        }
      }`;
      let variables = { commentData: _.clone(this.newCommentParams) };
      variables["commentData"]["body"] = this.comment;
      if (this.imageBase64) {
        variables["commentData"]["image"] = this.imageBase64;
      }
      if (this.video) {
        variables["commentData"]["video"] = this.video;
      }
      if (this.newCommentSpecial) {
        variables["commentData"]["special"] = true;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          var context = this;
          this.clearComment();
          setTimeout(() => {
            context.savingComment = false;
            //this emitted$ value is not used by all comment feeds (some have websocket updates)
            this.loadComments();
            context.$emit("commentSaved");
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.setFileSizeError();
          } else {
            this.errors = [this.$t("Something goes wrong. Please retry later.")];
          }
          this.savingComment = false;
        })
        .finally(() => {
          this.savingComment = false;
        });
    },
    hideComment(comment) {
      comment.saving = true;
      const query = `mutation ($commentData: HideCommentInput!){
        hideComment(commentData:$commentData) {
          comment{
            id
            hidden
          }
        }
      }`;
      let variables = { commentData: { id: comment.node.id } };
      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          setTimeout(function () {
            comment.saving = false;
          }, 500);

          this.loadComments();
        })
        .catch((res) => {
          this.errors = res.errors;
          comment.saving = false;
        });
    },
    setFileSizeError() {
      this.errors = [this.$t("The attached file should not exceed 15 MB.")];
    },
    sort() {
      this.direction = this.isDescDirection ? "ASC" : "DESC";
      this.commentPageCurrent = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/vuetify-variables";
@import "~@/scss/mq";

.root {
  max-height: 100%;
  height: 100%;

  ::v-deep .v-data-footer {
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #393742;
    margin: 16px 0 0;
  }

  ::v-deep .theme--light.v-application {
    .v-data-footer {
      background-color: $light-cardColor;
    }
  }
}

.buttons-container {
  flex-grow: 9999;

  ::v-deep .v-btn__content {
    text-transform: none;
    font-weight: 400 !important;
  }
}

.search {
  max-width: 260px;

  ::v-deep &.v-input:hover input,
  ::v-deep &.v-input.v-input--is-focused input {
    color: var(--text-primary);
  }

  ::v-deep &.v-input:hover fieldset,
  ::v-deep &.v-input.v-input--is-focused fieldset {
    border-color: var(--border-input);
    border-width: 1px;
    background-color: var(--bg-card-white);
  }

  ::v-deep {
    input {
      height: 100%;
      caret-color: var(--text-primary);
      padding-left: 6px;
    }

    fieldset {
      border-color: var(--border-primary);
      background-color: var(--bg-card-white);
    }

    .v-input__append-inner {
      width: 28px;
    }

    .v-input__slot {
      height: 44px !important;
      min-height: 0 !important;
      background: none !important;

      &:hover {
        background: none !important;
      }
    }

    .v-text-field__slot .v-label {
      height: 100%;
      line-height: 44px;
      top: 0 !important;
      color: var(--text-tertiary) !important;
    }

    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-bottom: auto !important;
      margin-top: auto !important;

      .v-icon {
        color: var(--text-secondary) !important;
      }
    }

    /* @media (min-width: map-get($grid-breakpoints, "lg")) {
      .v-input__slot {
        height: 36px !important;

        .v-label {
          line-height: 36px;
        }

        .v-icon {
          font-size: 20px;
        }
      }
    } */
  }
}

.comments-wrapper {
  overflow-y: auto;
  padding: 0;
  padding-bottom: 58px;
  height: calc(100% - 152px);
  min-height: 200px;
  position: relative;

  ::v-deep & > div:not(.comment, .v-data-footer) {
    height: 100%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  ::v-deep {
    .v-data-footer {
      font-size: 14px !important;
      position: sticky;
      bottom: -58px;
      margin-bottom: -58px;
    }
  }
}

.comment {
  position: relative;
  z-index: 8;
  background-color: var(--bg-card-white);

  & ~ .comment {
    margin-top: 1rem;
  }
}

.comment-content {
  flex-wrap: wrap;
  position: relative;

  p {
    width: 100%;
  }
}

.comment-form {
  border: 1px solid var(--border-primary);
  background-color: var(--bg-card-white);
  position: relative;

  &:hover {
    color: var(--text-primary);
    border-color: var(--border-input);
  }
}

.text-form {
  border: none;

  ::v-deep {
    .v-input__slot {
      background: none !important;
      padding: 0 16px 0 0 !important;
      min-height: 44px;
    }

    fieldset {
      border: none !important;
      top: 0;
    }

    textarea {
      max-height: 145px;
      min-height: 44px;
      line-height: 24px;
      margin-top: 0 !important;
      padding: 10px 44px 10px 16px !important;
      caret-color: var(--text-primary);
      color: var(--text-primary);
    }
  }
}

.videoPlayer {
  margin-top: 12px;
  height: 250px;
  border-radius: 0.25rem;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  ::v-deep {
    #video-controls {
      border-radius: 4px;
    }
    #play-pause:not(.maxBtn) {
      color: var(--bg-brand-contrast);
    }
    input[type="range"] {
      border-radius: 4px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background: var(--text-accent);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }

      &::-ms-track {
        appearance: none;
        background: var(--text-accent);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.preview {
  max-width: 100%;
  height: 150px;
  display: block;

  img {
    height: 100%;
  }
}

.file-upload {
  ::v-deep {
    .v-btn {
      position: absolute;
      right: 10px;
      top: 4px;
    }

    .v-icon {
      color: var(--color-primary) !important;
    }

    .file-card {
      margin: 0 16px 16px !important;
      display: inline-flex !important;
    }

    .file-name {
      padding-right: 16px;
    }
  }
}
</style>

<style>
.theme--light .root .v-data-footer {
  background: #f4f4f4;
}
</style>
