import axios from "axios";
import qs from "qs";

export const ticketsApi = {
  getTicketsP911: (params) => {
    return axios
      .get("/help_tickets/dashboard/", {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
      .then((res) => {
        return res.data;
      });
  },

  downloadCsvP911: (params) => {
    return axios.get("help_tickets/csv/", {
      params: params,
      responseType: "blob"
    });
  },

  getFiltersP911: (params, data) =>
    axios
      .post(`/help_tickets/filters/`, data, {
        params: { ...params, ...data },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
      .then((res) => res.data),

  getTicketsLdms: (params) => {
    return axios
      .get("/issues/items/", {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
      .then((res) => {
        return res.data;
      });
  },

  getTicketsLdmsZoneControlSummary: (params) => {
    return axios
      .get("/zone_control/summary/", {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        }
      })
      .then((res) => {
        return res.data;
      });
  },

  getTicketsLdmsSummary: (params) => {
    return axios.get("/issues/summary/", { params }).then((res) => {
      return res.data;
    });
  },

  downloadCsvLdms: (params) => {
    return axios.get("/issues/csv/", {
      params: params,
      responseType: "blob"
    });
  },

  getFiltersLdms: (params, data) =>
    axios.post(`/issues/field_filters/`, data, { params }).then((res) => res.data),

  closeTicket: ({ ticket_id, solutionType, solutionDetails }) => {
    const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
      updateHelpTicket(globalId:$id, helpData:$helpTicketData){
        ok,
        helpTicket{
          closedDate,
          closedBy,
          closedByName
        }
      }
    }`;

    const variables = {
      id: ticket_id,
      helpTicketData: {
        close: true,
        solutionType,
        solutionDetails
      }
    };

    return axios.post(`graphql/`, { query, variables }).then((res) => {
      if (res.data.errors) {
        throw res.data.errors;
      }

      return res.data;
    });
  }
};
