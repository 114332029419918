export const MACHINE_UPDATE_BUTTON_DEBOUNCE_TIME = 400;

export const GRID_CHART_TABS = {
  GRID: "grid",
  CHART: "chart"
};

export const rootIrisBreadcrumb = {
  text: "Iris",
  href: "/iris",
  link: true,
  disabled: false,
  exact: true
};

export const UPLOAD_IMAGE_VIDEO_FORMATS = "image/png, image/jpeg, video/mp4, video/x-m4v, video/";
