<template>
  <v-expansion-panels
    v-model="expand"
    class="expansion"
    flat
  >
    <v-expansion-panel :disabled="!isSm">
      <v-expansion-panel-header
        class="pa-0"
        :class="headerClass"
        :hide-actions="!isSm"
      >
        <template #default>
          <slot name="header"></slot>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        :class="contentClass"
        color="transparent"
      >
        <template #default>
          <slot name="content"></slot>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { ref, watch } from "vue";

export default {
  name: "MobileOnlyExpansion",
  props: {
    headerClass: {
      type: String,
      default: ""
    },
    contentClass: {
      type: String,
      default: ""
    }
  },
  setup() {
    const { isSm } = useDeviceResolution();
    const expand = ref(0);

    watch(
      isSm,
      (isMobile) => {
        return !isMobile && (expand.value = 0);
      },
      { immediate: true, deep: true }
    );

    return { isSm, expand };
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  background-color: transparent !important;

  &:before {
    display: none;
  }
}

.v-expansion-panel-header {
  padding: 0;
  min-height: 0;
}

.expansion ::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
