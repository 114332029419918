import moment from "moment-timezone";
import { Presets, Scales } from "@/shared/constants";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";

export const useGroupParams = () => {
  const defaultQuery = {
    dateFrom: moment().startOf("day").toISOString(),
    dateTo: moment().endOf("day").toISOString(),
    preset: Presets.DAY,
    scale: Scales.HOUR
  };

  const route = useRoute();
  const machine_group_id = computed(() => route.params.machine_group_id);
  const machine_group_pk = computed(() => Number(route.params.machine_group_pk));
  const machine_group_level = computed(() => Number(route.params.level));

  let params = computed(() => ({
    ...defaultQuery,
    ...(route.query || {}),
    preset: route.query.preset ? Number(route.query.preset) : defaultQuery.preset,
    scale: route.query.scale || defaultQuery.scale
  }));

  return {
    params,
    machine_group_id,
    machine_group_pk,
    machine_group_level
  };
};
