<template>
  <div class="d-flex align-center">
    <v-switch
      v-model="redirectToggle"
      class="my-0 py-0"
      hide-details
      @change="updateRedirectToggle"
    />
    <span
      v-if="label"
      class="view-switcher-label ml-1 text-subtitle-1 color--text-primary"
      :class="labelClass"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useRedirects } from "@/shared/useRedirects";

export default {
  name: "ViewSwitcher",
  props: {
    label: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      default: ""
    }
  },
  setup() {
    const { updateRedirectToggle, isRedirectToggleEnabled } = useRedirects();
    const redirectToggle = ref(isRedirectToggleEnabled().value);

    watch(redirectToggle, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        window.location.reload();
      }
    });

    return {
      updateRedirectToggle,
      redirectToggle
    };
  }
};
</script>
