import { computed, ref } from "vue";
import { isEmpty } from "lodash";

export const useFiltersController = (filters) => {
  const isFilterOpen = ref(false);

  const handleFiltersPanel = (e) => {
    isFilterOpen.value = Boolean(e);
  };

  const handleAcceptFilters = (filtersVal) => {
    isFilterOpen.value = false;
    return Object.assign(filters.value, filtersVal);
  };

  const activeFiltersLength = computed(() =>
    Object.keys(filters.value).reduce((acc, cur) => {
      const filterValue = filters.value[cur];

      if (!isEmpty(filterValue)) {
        acc += 1;
      }

      return acc;
    }, 0)
  );

  return {
    isFilterOpen,
    handleFiltersPanel,
    handleAcceptFilters,
    activeFiltersLength
  };
};
