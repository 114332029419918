<template>
  <div class="availability-chart metric-component">
    <h2 class="card-title">{{ $t("Availability") }}</h2>
    <v-progress-linear
      indeterminate
      v-if="!(production && production.length > 0)"
    ></v-progress-linear>
    <div
      class="charts"
      v-else
    >
      <div class="gauge-container">
        <v-gauge
          v-if="availabilityPer"
          gaugeValueClass="gaugeValue"
          :value="availabilityPer"
          :donut="true"
          :options="gaugeOpts"
        />
      </div>
      <div class="bar-container">
        <bar-chart
          v-if="chartData"
          chartName="availabilityChart"
          class="availabilityChart"
          :decimal="0"
          uom="time"
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.availability-chart {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .charts {
    display: flex;
    justify-content: center;
    align-items: center;
    .bar-container {
      width: calc(100% - 150px);
      padding-left: 0.5rem;
      .availabilityChart {
        width: 100%;
        height: 18vh;
        padding-left: 0.5rem;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import VGauge from "vgauge";
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart.js";
import { seconds } from "@/utils/filters";

export default {
  components: {
    BarChart,
    VGauge
  },
  props: ["options", "production", "interactiveFilters"],
  data() {
    return {
      loaded: false,
      availabilityPer: 1,
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      }
    };
  },
  created() {
    if (this.production && this.production.length > 0) {
      this.format_data();
    }
  },
  methods: {
    moment,
    format_data() {
      let datasets = [];
      let gradients = [];
      let runtimeData = [];
      let targets = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let colors = [];
      let total_availability = 0;
      let total_time = 0;

      if (this.production) {
        const statusMap = {};
        for (const status of this.statuses) {
          //bool
          const running = status.running;
          const plannedDown = status.plannedDown;
          for (const set of status.statuscodeSet) {
            statusMap[set.code] = {
              ...set,
              running,
              plannedDown
            };
          }
        }

        this.production.forEach((interval) => {
          const runningBuckets = {
            running: 0,
            total: 0,
            target: 0
          };

          const by_machine = interval.by_machine;

          for (const m of by_machine) {
            for (const p of m.by_part) {
              if (
                this.interactiveFilters.machines.length === 0 ||
                this.interactiveFilters.machines.indexOf(m.machine.machine_id) >= 0
              ) {
                if (
                  this.interactiveFilters.parts.length === 0 ||
                  this.interactiveFilters.parts.indexOf(p.part.part_number) >= 0
                ) {
                  runningBuckets.running += p.runtime.duration;
                  total_availability += p.runtime.duration;

                  runningBuckets.target += p.runtime_target;
                  total_time += p.planned_production_time;
                }
              }
            }
          }

          labels.push(this.options.labelFromString(interval.key_as_string));

          runtimeData.push(runningBuckets.running);
          targets.push(runningBuckets.target);
          titles.push([`${this.$t(`Target`)}: ${seconds(runningBuckets.target)}`]);
          if (runningBuckets.running >= runningBuckets.target) {
            gradients.push(this.$colors.chart.good_gradient);
          } else {
            gradients.push(this.$colors.chart.bad_gradient);
          }
          runningBuckets.target = Math.round(runningBuckets.target);
        });

        this.chartData = {
          datasets: [
            {
              type: "line",
              data: targets,
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: this.theme == "dark" ? "white" : "#44B2F3",
              hoverPointRadius: 0,
              borderColor: this.theme == "dark" ? "white" : "#44B2F3",
              fill: true,
              label: this.$t("Target"),
              showLine: false,
              titles: []
            },
            {
              data: runtimeData,
              gradients: gradients,
              titles: titles,
              //footers: footers,
              label: this.$t("Actual")
            }
          ],
          labels: labels
        };
      }
      this.availabilityPer = (total_availability / total_time) * 100;
    }
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false, stacked: true }],
          xAxes: [{ stacked: true }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    production: {
      deep: true,
      handler: "format_data"
    },
    interactiveFilters: {
      handler: "format_data"
    },
    theme: {
      handler: "format_data"
    }
  }
};
</script>
