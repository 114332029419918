import { getCurrentInstance } from "vue";

export const useIntl = () => {
  const root = getCurrentInstance().proxy;
  const $t = root.$t;

  return {
    $t
  };
};
