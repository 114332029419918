<template>
  <div class="mb-8">
    <div class="text-subtitle-1 text-uppercase">
      {{ title }}
    </div>

    <MachineTableControl
      :on-click-filters="handleFiltersPanel"
      :active-filters-length="activeFiltersLength"
      class="tickets-control"
    >
      <template #leftNavigation>
        <card
          v-if="summary"
          :custom-padding="true"
          class="d-flex px-1 py-0 text-center tickets-left"
        >
          <div class="pa-3">
            <div class="text-body-2 text--secondary">{{ $t("Total open") }}</div>
            <div class="text-body-1 font-weight-medium">
              {{ unit(summary.total_open, UnitTypes.NUMBER) }} {{ $t("tickets") }}
            </div>
          </div>
          <div class="pa-3">
            <div class="text-body-2 text--secondary">{{ $t("In process") }}</div>
            <div class="text-body-1 font-weight-medium">
              {{ unit(summary.in_progress, UnitTypes.NUMBER) }} {{ $t("tickets") }}
            </div>
          </div>
          <div class="pa-3">
            <div class="text-body-2 text--secondary">
              {{ $t("Latest ticket opened") }}
              {{ moment(summary.latest_open).format($datetime.date) }}
            </div>
            <div class="text-body-1 font-weight-medium">
              {{ unit(summary.latest, UnitTypes.NUMBER) }} {{ $t("days") }}
            </div>
          </div>
          <div class="pa-3">
            <div class="text-body-2 text--secondary">
              {{ $t("Oldest ticket opened") }}
              {{ moment(summary.oldest_open).format($datetime.date) }}
            </div>
            <div class="text-body-1 font-weight-medium">
              {{ unit(summary.oldest, UnitTypes.NUMBER) }} {{ $t("days") }}
            </div>
          </div>
        </card>
        <div v-else></div>
      </template>

      <template #rightBlock>
        <Btn
          icon
          large
          class="ml-3 color--primary"
          primary
          @click="$emit('click')"
        >
          <v-icon>mdi-arrow-expand</v-icon>
        </Btn>
      </template>

      <template
        v-if="isAddIssueEnabled"
        #leftBlock
      >
        <ZoneControlIssue
          :machine_group_id="machine_group_id"
          :machine_group_pk="machine_group_pk"
          @submitted="refetch"
        />
      </template>
    </MachineTableControl>

    <div>
      <TicketsTableLdms
        name="table"
        :is-show-machine-name="true"
        :filters="filters"
        :machine_group_pk="machine_group_pk"
        :machine_group_id="machine_group_id"
        :params="params"
        :items="items"
        :is-ready="isReady"
        :is-fetching-table="isFetchingTable"
        :total-items="totalItems"
        :config="tableConfig"
        :refresh="refresh"
      ></TicketsTableLdms>
    </div>

    <TicketsFiltersLdms
      name="filters"
      :portal-node-id="portalNodeId"
      :is-open="isFilterOpen"
      :machine_group_pk="machine_group_pk"
      :filters="filters"
      :params="filtersParams"
      @input="handleFiltersPanel"
      @acceptFilters="handleAcceptFilters"
    >
    </TicketsFiltersLdms>
  </div>
</template>

<script>
import { computed, readonly, ref, toRefs, watch } from "vue";
import { pickBy } from "lodash";
import MachineTableControl from "@/features/machine/components/MachineTableControl.vue";

import { AppRoutes } from "@/shared/app-routes";
import { useFiltersController } from "@/common/filters/useFiltersController";
import { useTicketsLdms } from "@/features/tickets/useTicketsLdms";
import { useGroupParams } from "@/features/group/useGroupParams";
import TicketsTableLdms from "@/features/tickets/components/TicketsTableLdms.vue";
import TicketsFiltersLdms from "@/features/tickets/components/TicketsFiltersLdms.vue";
import Card from "@/ui/Card/Card.vue";
import Btn from "@/ui/Btn/Btn.vue";
import { useTicketsLdmsZoneControlSummary } from "@/features/tickets/useTicketsLdmsZoneControlSummary";
import { unit, UnitTypes } from "@/utils/unit";
import moment from "moment-timezone";
import ZoneControlIssue from "@/features/zone-control/components/ZoneControlIssue.vue";

export default {
  name: "ZoneControlTickets",
  components: {
    ZoneControlIssue,
    Card,
    TicketsFiltersLdms,
    TicketsTableLdms,
    MachineTableControl,
    Btn
  },

  props: {
    title: { type: String, default: "" },
    portalNodeId: { type: String, default: "" },
    from_date: { type: String, default: undefined },
    to_date: { type: String, default: undefined },
    dashboard: { type: String, default: "ldms" },
    type: { type: String, default: "group" },
    isAddIssueEnabled: { type: Boolean, required: true }
  },

  emits: ["click"],

  setup(props) {
    const { dashboard, type, from_date, to_date } = toRefs(props);

    const { params, machine_group_id, machine_group_pk, machine_group_level } = useGroupParams();

    const tableConfig = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: []
    });

    const filters = ref({
      issues: [],
      machine_ids: [],
      statuses: null,
      category: [],
      assigned_to: []
    });

    const { isFilterOpen, handleFiltersPanel, handleAcceptFilters, activeFiltersLength } =
      useFiltersController(filters);

    watch(
      [params, filters],
      () => {
        tableConfig.value.page = 1;
      },
      { deep: true }
    );

    const filtersParams = computed(() => ({
      ...params.value,
      dateFrom: from_date.value,
      dateTo: to_date.value
    }));

    const paramsApi = computed(() => {
      const result = pickBy(
        {
          from_date: from_date.value,
          to_date: to_date.value,
          size: tableConfig.value.itemsPerPage,
          offset: tableConfig.value.itemsPerPage * (tableConfig.value.page - 1),
          ordering: tableConfig.value.sortBy.length
            ? (tableConfig.value.sortDesc[0] ? "-" : "") + tableConfig.value.sortBy[0]
            : null,
          not_closed: true,
          dashboard: dashboard.value,
          ...filters.value
        },
        Boolean
      );

      if (result.statuses) {
        result.statuses = [result.statuses];
      }

      if (type.value === "group") {
        result["machine_group_id"] = machine_group_pk.value;
      }

      return result;
    });

    const {
      items,
      isFetching: isFetchingTable,
      isSuccess: isSuccessTable,
      isError: isErrorTable,
      total: totalItems,
      categories,
      refetch
    } = useTicketsLdms(paramsApi, false);

    const { summary } = useTicketsLdmsZoneControlSummary(paramsApi);

    const isReady = computed(() => {
      return isSuccessTable.value || isErrorTable.value;
    });

    const refresh = () => {
      setTimeout(() => {
        refetch();
      }, 500);
    };

    return {
      filters,
      isFilterOpen,
      handleFiltersPanel,
      handleAcceptFilters,
      activeFiltersLength,
      items,
      isReady,
      isFetchingTable,
      totalItems,
      tableConfig,
      categories,
      refresh,
      params: readonly(params),
      filtersParams,
      AppRoutes,
      machine_group_id,
      machine_group_pk,
      machine_group_level,
      refetch,
      summary,
      unit,
      UnitTypes,
      moment
    };
  }
};
</script>

<style scoped>
@media (max-width: 1100px) {
  .tickets-control {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }

  .tickets-left {
    margin-top: 16px;
  }
}
</style>
