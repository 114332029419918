import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { ticketsApi } from "@/features/tickets/tickets-api";

export const useTicketsLdmsZoneControlSummary = (variables, loadSummary = true) => {
  const summaryQuery = useQuery({
    queryKey: ["tickets-ldms-zome-control-summary", variables],
    queryFn: () =>
      loadSummary ? ticketsApi.getTicketsLdmsZoneControlSummary(variables.value) : null,
    keepPreviousData: true
  });

  const summary = computed(() => summaryQuery.data.value);

  return {
    ...summaryQuery,
    summary
  };
};
