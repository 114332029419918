var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ai-vision-list-view"},[_c('div',{staticClass:"top-cards"},[_c('div',{staticClass:"summary-card total-processed"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("Total Processed (pcs)")))])]),_vm._v(" "),_c('div',{staticClass:"value"},[_c('h1',{staticClass:"right"},[_vm._v(_vm._s(_vm.numPass + _vm.numFail))])])]),_vm._v(" "),_c('div',{staticClass:"summary-card total-passed"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("Total Passed (pcs)")))])]),_vm._v(" "),_c('div',{staticClass:"value"},[_c('h1',{staticClass:"right"},[_vm._v(_vm._s(_vm.numPass))])])]),_vm._v(" "),_c('div',{staticClass:"summary-card total-failed"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("Total Failed (pcs)")))])]),_vm._v(" "),_c('div',{staticClass:"value"},[_c('h1',{staticClass:"right"},[_vm._v(_vm._s(_vm.numFail))])])]),_vm._v(" "),_c('div',{staticClass:"summary-card first-pass-yield"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("First Pass Yield")))])]),_vm._v(" "),_c('div',{staticClass:"value"},[_c('h1',{staticClass:"right"},[_vm._v("\n          "+_vm._s(_vm.numPass + _vm.numFail !== 0
              ? `${((_vm.numPass / (_vm.numPass + _vm.numFail)) * 100).toFixed(2)}%`
              : "---")+"\n        ")])])])]),_vm._v(" "),_c('div',{staticClass:"bottom-table"},[_c('generic-table',{attrs:{"columns":[
        { title: _vm.$t('Time'), key: 'time_stamp', format: _vm.formatDate },
        { title: _vm.$t('Model Name'), key: 'part_name' },
        { title: _vm.$t('Model Version'), key: 'model_version_number' },
        { title: _vm.$t('Result'), key: 'inspection_result' },
        { title: _vm.$t('Inferencing Time (secs)'), key: 'inferencing_time' },
        { title: _vm.$t('Accuracy'), key: 'confidence', format: (val) => val.toFixed(2) },
        { title: _vm.$t('Image Link'), html: 'image_location_html', click: _vm.openImage }
      ],"data":_vm.data}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }