<template>
  <KioskProvider>
    <UppyProvider v-if="loaded">
      <AppStyleController />
      <router-view :key="routerViewKey"></router-view>
      <DigitalFormsEntry :user="user" />
    </UppyProvider>
  </KioskProvider>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { hasUserRole, userRoles } from "./utils/user";
import moment from "moment";

import { Dispatcher } from "@/utils/eventbus";
import UppyProvider from "./features/iris-ingestion/UppyProvider.vue";
import KioskProvider from "@/common/kiosk/KioskProvider.vue";
import AppStyleController from "@/components/AppStyleController.vue";

const generateRouterViewKey = () => {
  const now = new Date();
  return `${now.getDate()}${now.getHours()}`;
};

export default {
  components: { KioskProvider, AppStyleController, UppyProvider },
  created() {
    this.setVW(window.innerWidth);
    // handle redirect to login whenever a request is unauthorized
    axios.interceptors.response.use(undefined, (error) => {
      console.log(error.response);
      if (error.response && error.response.status == 401) {
        const currentPath = this.$router.currentRoute.fullPath;
        if (this.$router.currentRoute.name != "login") {
          document.location.href = `/login?next=${encodeURIComponent(currentPath)}`;
        }
      }
      return Promise.reject(error);
    });
    document.body.setAttribute("theme", this.theme);
    Dispatcher.$on("NOTIFY", this.notify);
    this.$listen("NOTIFY", this.notify);
    this.setTimezone();
    setInterval(() => {
      if (hasUserRole(this.user, userRoles.Kiosk)) {
        this.routerViewKey = generateRouterViewKey();
      }
    }, moment.duration(1, "minutes").asMilliseconds());
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    Dispatcher.$off("NOTIFY", this.notify);
  },
  data() {
    return {
      alerts: [],
      scrollTop: 0,
      fixed: true,
      loaded: true,
      routerViewKey: generateRouterViewKey()
    };
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme",
      expire: "session/AuthExpiration",
      useNotifications: "app/CanNotify",
      user: "session/User"
    })
  },
  methods: {
    ...mapActions({
      setTimezone: "app/SetTimezone",
      setVW: "app/SetVW"
    }),
    handleResize() {
      this.setVW(window.innerWidth);
    },
    notify(text) {
      if (!this.useNotifications) {
        console.log("notifications are not available on this device.");
        return;
      }
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
        return;
      }

      const options = {
        body: text,
        icon: "/img/icon.png"
      };

      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          new Notification("IntelyCX", options);
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "./scss/variables";
@import "./scss/processscontrol";
@import "./scss/component";
@import "./scss/metric";
@import "./scss/mq";
@import "./scss/flexboxgrid";
@import "./scss/light_theme";
@import "./assets/fonts/fonts";
@import "./scss/transition";
@import "./scss/flex";
@import "./scss/_mobile.scss";

html {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
}
.v-application {
  font-family: $regular, sans-serif !important;
  &.theme--dark {
    background: $background !important;
  }
  p {
    margin-bottom: 0;
  }
  .primary--text {
    color: $white !important;
  }
}
body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: $regular;
  color: white;
  font-weight: 400;
  font-weight: normal;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  button,
  span,
  input {
    font-weight: 400;
  }
  p,
  label {
    font-size: 1rem;
  }
}
textarea {
  font-family: $regular;
}
#app {
  min-height: 100%;
  display: block;
}
div {
  box-sizing: border-box;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

.overlay {
  position: fixed;
  display: block;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  // background: rgba(0,0,0,1);
  z-index: 998;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s linear;
  &.show {
    opacity: 0.5;
    pointer-events: fill;
  }
}

@media screen and (max-width: 1200px) {
  .title-container {
    p {
      font-size: 10px;
    }
  }
}
</style>
