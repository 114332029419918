// useTLAFilters.js
import { computed, ref } from "vue";

let interactiveFilters;
let filterApplied;
let priorityApplied;

export default function () {
  if (!interactiveFilters) {
    interactiveFilters = ref({});
  }
  if (!filterApplied) {
    filterApplied = ref("");
  }
  if (!priorityApplied) {
    priorityApplied = ref(0);
  }

  const hasFilters = computed(() => {
    const keys = Object.keys(interactiveFilters.value);
    for (const k of keys) {
      if (interactiveFilters.value[k].length > 0) {
        return true;
      }
    }

    return false;
  });

  function addFilter(filterName, filter, priority) {
    filterApplied.value = filterName;
    if (!interactiveFilters.value[filterName]) {
      interactiveFilters.value[filterName] = [];
    }
    if (interactiveFilters.value[filterName].indexOf(filter) < 0) {
      interactiveFilters.value[filterName].push(filter);
      interactiveFilters.value = { ...interactiveFilters.value };
      priorityApplied.value = priority;
    }
  }
  function removeFilter(filterName, filter, priority) {
    filterApplied.value = filterName;
    if (interactiveFilters.value[filterName]) {
      const filterIndex = interactiveFilters.value[filterName].indexOf(filter);
      if (filterIndex >= 0) {
        interactiveFilters.value[filterName].splice(filterIndex, 1);
        interactiveFilters.value = { ...interactiveFilters.value };
        priorityApplied.value = priority;
      }
    }
  }

  function toggleFilter(filterName, filter, priority) {
    filterApplied.value = filterName;
    let filterIndex = -1;
    if (interactiveFilters.value[filterName]) {
      filterIndex = interactiveFilters.value[filterName].indexOf(filter);
    }
    if (filterIndex >= 0) {
      interactiveFilters.value[filterName].splice(filterIndex, 1);
      interactiveFilters.value = { ...interactiveFilters.value };
    } else {
      if (!interactiveFilters.value[filterName]) {
        interactiveFilters.value[filterName] = [];
      }
      interactiveFilters.value[filterName].push(filter);
      interactiveFilters.value = { ...interactiveFilters.value };
    }
    priorityApplied.value = priority;
  }

  function removeAllFilters(keepFilters, priorityLevel) {
    if (priorityLevel !== undefined) {
      priorityApplied.value = priorityLevel;
    }

    filterApplied.value = "";
    const kept = {};
    if (keepFilters) {
      for (const f of keepFilters) {
        if (interactiveFilters.value[f]) {
          kept[f] = interactiveFilters.value[f];
        }
      }
    }
    interactiveFilters.value = { ...kept };
  }

  function copyFilterTo(from, to) {
    const temp = interactiveFilters.value[from];
    if (temp && interactiveFilters.value[to]) {
      interactiveFilters.value[to] = [...temp];
    }
  }

  return {
    interactiveFilters,
    filterApplied,
    priorityApplied,
    addFilter,
    removeFilter,
    toggleFilter,
    removeAllFilters,
    copyFilterTo,
    hasFilters
  };
}
