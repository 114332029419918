<template>
  <div :class="{ 'kiosk-container px-6 py-1': enabled }">
    <div
      v-if="enabled"
      class="py-6 d-flex justify-end"
    >
      <btn
        icon
        transparent
        @click="hideKioskScreen"
      >
        <v-icon class="white--text">mdi-close</v-icon>
      </btn>
    </div>
    <slot name="default"></slot>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import Btn from "@/ui/Btn/Btn.vue";
import { KIOSK_SCREEN_KEY, KIOSK_SCREEN_HIDE } from "@/common/kiosk/constants";

export default {
  name: "KioskContainer",
  components: { Btn },
  props: {
    name: { type: String, required: true }
  },
  setup(props) {
    const hideKioskScreen = inject(KIOSK_SCREEN_HIDE);
    const kioskScreenKey = inject(KIOSK_SCREEN_KEY);

    const enabled = computed(() => kioskScreenKey.value === props.name);

    return {
      enabled,
      hideKioskScreen
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
.kiosk-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-main);
  overflow: auto;
}
</style>
