var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-6 d-flex flex-column page"},[_c('div',{staticClass:"text-body-2 font-weight-medium mb-4"},[_c('a',{on:{"click":_setup.getBackToTickets}},[_vm._v(_vm._s(_vm.$t("Tickets")))]),_vm._v(" "),_c('span',{staticClass:"mx-1 font-weight-regular"},[_vm._v("/")]),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(_setup.ticket.machineName))])]),_vm._v(" "),_c('div',{staticClass:"composition"},[_c('div',{staticClass:"ticket-header d-flex align-center mb-1"},[_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v(_vm._s(_setup.ticket.machineName))])]),_vm._v(" "),_c('div',{staticClass:"ticket-body mb-10"},[_c('div',{staticClass:"type text-body-1 color--text-secondary mb-3"},[_c('span',[_vm._v(_vm._s(_vm.$t("Category")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_setup.categoryName))]),_vm._v(" "),_c('span',{staticClass:"text-subtitle-1 color--text-primary"},[_vm._v(_vm._s(_vm.$t("Quality")))]),_vm._v(" "),_c('span',_vm._l((_setup.issues),function(issue,issueIndex){return _c('v-chip',{key:issueIndex,staticClass:"color--text-contrast",attrs:{"color":issue.color,"label":""}},[_vm._v(_vm._s(issue.name))])}),1)]),_vm._v(" "),_c('div',{staticClass:"ticket-body-item"},[_c('h2',{staticClass:"text-body-1 color--text-secondary mb-2"},[_vm._v(_vm._s(_vm.$t("Description")))]),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1"},[_vm._v("\n          "+_vm._s(_setup.ticket.details)+"\n        ")])])]),_vm._v(" "),_c(_setup.TicketsDetailsControl,{staticClass:"ticket-control",attrs:{"ticket":_setup.ticket,"users":_setup.users},on:{"assign":_setup.refetch}}),_vm._v(" "),_c(_setup.TicketsDetailsActivity,{staticClass:"ticket-comments",attrs:{"filters":_setup.filters,"new-comment-params":_setup.commentParams,"tabs":[
        {
          label: _vm.$t('All')
        },
        {
          label: _vm.$t('Comments')
        },
        { label: _vm.$t('History') }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }