<template>
  <div class="videoPlayer">
    <transition name="fade">
      <div class="video-container">
        <div
          class="player"
          v-show="!finished"
        >
          <video
            ref="video"
            :src="file"
            :id="id"
            :muted="muted"
            @timeupdate="updateSeekBar"
            @play="play"
            @pause="pause"
            @ended="complete"
            @click="play_pause"
            v-show="!finished"
          >
            <source type="video/mp4" />
            {{ $t("Your browser does not support the video tag.") }}
          </video>
          <div
            id="video-controls"
            v-show="!finished"
          >
            <button
              class="button"
              type="button"
              id="play-pause"
              @click="play_pause"
            >
              <span
                class="fa fa-play"
                v-show="!playing"
              ></span>
              <span
                class="fa fa-pause"
                v-show="playing"
              ></span>
            </button>
            <input
              type="range"
              :value="seek_value"
              ref="seekBar"
              id="seek-bar"
              @change="changeVideoTime"
              @mousedown="seeking = true"
              @mouseup="seeking = false"
              min="0"
              :max="duration"
              step="0.01"
            />
            <button
              class="button"
              type="button"
              id="mute"
              @click="toggleMute()"
            >
              <span
                class="fa fa-volume-up"
                v-show="muted"
              ></span>
              <span
                class="fa fa-volume-off"
                v-show="!muted"
              ></span>
            </button>
            <input
              ref="volumeBar"
              v-model="volume_value"
              type="range"
              id="volume-bar"
              min="0"
              max="1"
              step="0.1"
            />
            <button
              class="button maxBtn"
              type="button"
              id="play-pause"
              @click="toggleMax"
            >
              <span class="fa fa-arrows-alt"></span>
            </button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="center-text video-buttons"
        v-show="finished"
      >
        <div
          @click="restart"
          class="card restart"
        >
          {{ $t("Click to Restart Video") }}
        </div>
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
.videoPlayer {
  position: relative;
  height: 65vh;
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
    min-height: 50%;
    font-size: 1.5rem;
    &.restart {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .light {
    background-color: #ececec;
  }
  .dark {
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    color: #fff;
    overflow: hidden;
    display: block;
  }
  .content-background {
    background-color: #ececec;
  }
  .center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .quiz-name {
    text-align: center;
  }

  .img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .light .img,
  .light video {
    background-color: #ececec;
  }
  video {
    width: 100%;
    height: 65vh;
    max-height: 100%;
  }
  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .player {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      background: black;
    }
  }
  .video-buttons {
    margin: 1rem;
  }
  .back-third {
    width: 33%;
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .front-third {
    width: 66%;
    display: inline-block;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .arrow-right {
    width: 20px;
    height: 20px;
    border-top: 4px solid;
    border-right: 4px solid;
    transform: rotate(45deg);
    position: fixed;
    right: 20px;
    top: 50%;
    opacity: 0.4;
  }
  .arrow-left {
    width: 20px;
    height: 20px;
    border-bottom: 4px solid;
    border-left: 4px solid;
    transform: rotate(45deg);
    position: fixed;
    left: 20px;
    top: 50%;
    opacity: 0.4;
  }
  .arrow-right:hover,
  .arrow-left:hover {
    height: 30px;
    width: 30px;
    opacity: 1;
    transition: 1s;
  }
  input[type="range"] {
    -webkit-appearance: none;
    background-color: silver;
    width: 200px;
    height: 20px;
    border-radius: 20px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: white;
    width: 10px;
    height: 20px;
    border-radius: 10px;
  }
  #video-controls {
    z-index: 99999;
    width: calc(100% - 20px);
    background-color: black;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    margin-top: -24px;
    #seek-bar {
      width: calc(100% - 120px);
      margin: 0 10px;
    }
    #volume-bar {
      width: 60px;
      margin: 0 10px;
    }
    .button {
      width: 20px;
      vertical-align: bottom;
      border-radius: 4px;
      border: none;
      display: inline-block;
      color: white;
      margin: 0 5px;
    }
    .maxBtn {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  #video-controls:hover {
    opacity: 1;
  }
}
</style>
<script>
import { v4 as uuidv4 } from "uuid";

export default {
  methods: {
    setup: function () {
      this.finished = false;
      this.duration = this.$refs.video.duration;
    },
    next: function () {
      this.$emit("next");
    },
    play: function () {
      this.$emit("play", this.pageView);
    },
    pause: function () {
      this.$emit("pause", this.pageView);
    },
    complete: function () {
      this.finished = true;
    },
    restart: function (page_view) {
      this.finished = false;
      this.$refs.video.play();
    },
    play_pause: function () {
      this.setup();
      if (!this.$refs.video.paused) {
        this.$refs.video.pause();
        this.playing = false;
      } else {
        this.$refs.video.play();
        this.playing = true;
      }
    },
    toggleMute: function () {
      //refer to watch for the rest
      if (this.muted) {
        this.volume_value = this.lastVolume;
      } else {
        this.volume_value = 0;
      }
    },
    changeVolume: function (event) {
      //refer to watch for the rest
      this.volume_value = event.target.value;
    },
    updateSeekBar: function (event) {
      if (this.seeking) {
        return;
      }
      let video = event.target;
      this.seek_value = video.currentTime;
    },
    changeVideoTime: function (event) {
      this.seek_value = event.target.value;
      // Update the video time
      this.$refs.video.currentTime = this.seek_value;
    },
    toggleMax() {
      var element = document.getElementById(this.id);
      if (!this.maximized) {
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      }

      this.maxized = !this.maxized;
    }
  },
  props: ["file"],
  mounted() {},
  beforeDestroy() {
    this.pause();
  },
  watch: {
    volume_value: function (newValue, old_value) {
      let video = this.$refs.video;
      video.volume = newValue;
      this.lastVolume = old_value;
      this.muted = newValue == 0;
      video.muted = this.muted;
    }
  },
  data: function () {
    return {
      id: uuidv4(),
      pageView: null,
      finished: false,
      playing: false,
      muted: false,
      seeking: false,
      lastVolume: 1,
      seek_value: 0,
      volume_value: 1,
      duration: 0, // duration in ms
      maxized: false
    };
  }
};
</script>
