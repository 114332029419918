import { render, staticRenderFns } from "./AiVisionAnomalies.vue?vue&type=template&id=26e2cccc&scoped=true&"
import script from "./AiVisionAnomalies.vue?vue&type=script&lang=js&"
export * from "./AiVisionAnomalies.vue?vue&type=script&lang=js&"
import style0 from "./AiVisionAnomalies.vue?vue&type=style&index=0&id=26e2cccc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26e2cccc",
  null
  
)

export default component.exports