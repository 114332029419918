var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableDiv"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("Performance")))]),_vm._v(" "),_c('generic-table',{attrs:{"columns":[
      { title: _vm.$t('Time'), key: 'time' },
      { title: _vm.$t('Machine'), key: 'machine' },
      { title: _vm.$t('Part Number'), key: 'part_number' },
      { title: _vm.$t('Target'), key: 'target' },
      { title: _vm.$t('Produced'), key: 'produced' }
    ],"nested_columns":[
      { key: '' },
      { key: 'machine' },
      { key: 'part_number' },
      { key: 'target' },
      { key: 'produced' }
    ],"footer":[
      { type: 'csv', csv: _vm.downloadCsv },
      { type: 'text', text: _vm.$t('Total') },
      {},
      {
        type: 'text',
        text: this.numberWithCommas(
          Math.round(_vm.sumValue(_vm.production, ['production_target', 'value']))
        )
      },
      { type: 'text', text: this.numberWithCommas(_vm.sumValue(_vm.production, ['produced', 'value'])) }
    ],"data":_vm.tableData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }