var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radial-fab"},[_c('div',_vm._g({class:`fab ${_vm.isOpen && 'open'}`},{ click: _vm.isMachineRoute ? _vm.openMenu : _vm.openP911 }),[_c('i',{staticClass:"fa plus-icon",class:{ 'fa-plus': _vm.isMachineRoute, 'fa-exclamation': !_vm.isMachineRoute }}),_vm._v(" "),_c('div',{class:`background_expand ${_vm.isOpen && 'open'}`,on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"button-list"},[_c('div',{staticClass:"button-item",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.isOpen = false;
              _vm.openScrapEntry();
            }).apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("Scrap Data Entry")))])]),_vm._v(" "),_c('div',{staticClass:"button-item",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.isOpen = false;
              _vm.openStatusEntry();
            }).apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("Update Status")))])]),_vm._v(" "),_c('div',{staticClass:"button-item",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.isOpen = false;
              _vm.openEdocs();
            }).apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-file-text-o"}),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("E-Docs")))])]),_vm._v(" "),_c('div',{staticClass:"button-item",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.isOpen = false;
              _vm.openP911();
            }).apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-bell-o"}),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("P911")))])]),_vm._v(" "),_c('div',{staticClass:"button-item",on:{"click":function($event){$event.stopPropagation();return (() => {
              _vm.isOpen = false;
              _vm.openComment();
            }).apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-comment-o"}),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t("Comment")))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }