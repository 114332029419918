import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { ticketsApi } from "@/features/tickets/tickets-api";

export const useTicketClose = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto) => ticketsApi.closeTicket(dto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tickets-details"] });

      if (onSuccess) {
        onSuccess();
      }
    }
  });
};
