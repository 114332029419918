<template>
  <div class="application-issue-tickets">
    <div class="header">
      <div class="text-search-container">
        <v-text-field
          v-model="machineStateSearchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          class="text-search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </div>
    </div>

    <v-data-table
      item-key="id"
      :headers="machineStateTable.headers"
      :items="sortedMachinesWithIssues"
      :loading="machineStateTable.loadingData"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Machine States... Please wait')"
      @click:row="goToTicket"
      :item-disabled="isRowDisabled"
      fixed-header
    >
      <template v-slot:item.state="{ item }">
        <v-progress-circular
          v-if="machineStateTable.loadingData"
          indeterminate
          color="primary"
          size="20"
        />
        <v-chip
          v-else
          :class="{
            bad: item.state === $t('Disabled'),
            good: item.state === $t('Active'),
            Decomissioned: item.state === $t('Decommissioned')
          }"
        >
          {{ item.state }}
        </v-chip>
      </template>
      <template v-slot:item.issueStartDate="{ item }">
        {{ formatDate(item.issueStartDate) }}
      </template>
      <template v-slot:item.issueEndDate="{ item }">
        {{ formatDate(item.issueEndDate) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";

export default {
  name: "Tickets",
  data() {
    return {
      machineStateTable: new TableData([
        { text: this.$t("Machine"), value: "machineName" },
        { text: this.$t("State"), value: "state" },
        { text: this.$t("Disabled On"), value: "issueStartDate" },
        { text: this.$t("Last Update"), value: "issueEndDate" }
      ]),
      machineStateSearchText: "",
      searchDebouncerIssueTickets: null
    };
  },
  created() {
    this.getApplicationIssueTickets();
  },
  computed: {
    ...mapGetters({
      machines: "dbCache/machines"
    }),
    filteredMachines() {
      if (!this.machineStateSearchText) {
        return this.machines;
      }
      const searchTerm = this.machineStateSearchText.toLowerCase();
      return this.machines.filter((machine) => machine.name.toLowerCase().includes(searchTerm));
    },
    machinesWithIssues() {
      return this.filteredMachines.map((machine) => {
        if (machine.active === false) {
          return {
            id: machine.pk,
            machineName: machine.name,
            state: this.$t("Decomissioned"),
            issueStartDate: null,
            issueEndDate: null,
            ticketId: null
          };
        }
        const matchingIssue = this.machineStateTable.data.find(
          (issue) => parseInt(issue.machineId) === machine.pk
        );

        return {
          id: machine.pk,
          machineName: machine.name,
          state: matchingIssue ? this.$t("Disabled") : this.$t("Active"),
          issueStartDate: matchingIssue ? matchingIssue.issueStartDate : null,
          issueEndDate: matchingIssue ? matchingIssue.issueEndDate : null,
          ticketId: matchingIssue ? matchingIssue.id : null
        };
      });
    },
    sortedMachinesWithIssues() {
      const items = [...this.machinesWithIssues];

      items.sort((a, b) => {
        const dateA = a.issueEndDate ? new Date(a.issueEndDate).getTime() : 0;
        const dateB = b.issueEndDate ? new Date(b.issueEndDate).getTime() : 0;

        if (dateA !== dateB) {
          return dateB - dateA;
        }
        return a.machineName.localeCompare(b.machineName);
      });

      return items;
    }
  },
  methods: {
    goToTicket(item) {
      if (item.ticketId) {
        this.$router.push({
          name: "application-issue-ticket",
          params: { ticketId: item.ticketId }
        });
      }
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format(this.$datetime.default);
    },
    async getApplicationIssueTickets() {
      this.machineStateTable.loadingData = true;
      const query = `
        query($disabled: String!) {
          applicationIssues(
            queryString: $disabled
            filter: { closedDate: { isNull: true } }
          ) {
            edges {
              cursor
              node {
                id
                machineId
                machineName
                issueStartDate
                issueEndDate
                reason
              }
            }
          }
        }
      `;
      const variables = { disabled: "disabled" };

      try {
        const response = await this.$http.post("graphql/", {
          query,
          variables
        });
        const applicationIssuesData = response?.data?.data?.applicationIssues;
        if (applicationIssuesData && applicationIssuesData.edges) {
          this.machineStateTable.data = applicationIssuesData.edges.map((item) => ({
            ...item.node
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.machineStateTable.loadingData = false;
      }
    },
    isRowDisabled(item) {
      return item.state === this.$t("Disabled");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./Tickets.scss";
.v-chip {
  border-radius: 5px;
  padding: 4px 8px;

  ::v-deep .v-chip__content {
    min-width: 100px;
    justify-content: center;
  }

  &.bad {
    background-color: var(--bg-red) !important;
    color: var(--text-red);
  }

  &.good {
    background-color: var(--bg-green) !important;
    color: var(--text-green);
  }

  &.Decomissioned {
    background-color: var(--bg-secondary) !important;
    color: var(--text-secondary);
  }
}
</style>
