<template>
  <div class="ticket-timeline">
    <v-timeline
      dense
      clipped
    >
      <TicketDetailsWorkflowItem
        stage="waiting"
        :ticket="ticket"
      />
      <TicketDetailsWorkflowItem
        stage="active"
        :ticket="ticket"
      />
      <TicketDetailsWorkflowItem
        stage="resolved"
        :ticket="ticket"
      />
    </v-timeline>
  </div>
</template>

<script setup>
import TicketDetailsWorkflowItem from "@/features/ticket-details/components/TicketDetailsWorkflowItem.vue";

defineProps({
  ticket: {
    type: Object,
    required: true,
    default: () => ({})
  }
});
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import "~@/scss/mq";

.ticket-timeline {
  all: unset;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: translateX(-30px);

  ::v-deep {
    .v-timeline {
      position: relative;
    }

    .v-timeline-item:first-of-type:before,
    .v-timeline-item:last-of-type:after {
      content: "";
      position: absolute;
      top: 0;
      left: 20px;
      width: 50px;
      height: 27px;
      background-color: var(--bg-card);
    }

    .v-timeline-item:last-of-type:after {
      bottom: 0;
      top: unset;
    }

    .v-timeline-item__dot {
      box-shadow: none !important;
    }
  }

  .theme--dark.v-timeline .v-timeline-item__dot {
    background: none;
  }
  .v-timeline {
    height: 100%;
    width: 100%;
    .v-timeline-item {
      align-items: center;
      height: auto;
      &.duration {
        height: 0%;
        text-align: right;
      }
      .v-timeline-item__body,
      .itemDetails {
        .duration {
          min-width: 8rem;
        }
      }
    }
  }
  .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
    left: calc(30% - 1px) !important;
  }
  .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
    .v-timeline-item:nth-child(odd):not(.v-timeline-item--before)
    .v-timeline-item__body {
    max-width: calc(70% - 48px);
  }
}

.timeline-item {
  transform: translateY(10px);
}
</style>
