<template>
  <ModalDialog
    v-model="visibleProp"
    max-width="576px"
    height="416px"
    full-screen-mobile
    persistent
  >
    <template #title>
      {{ $t("Action Login") }}
    </template>

    <template #body>
      <div v-if="!loginError">
        <div class="text-subtitle-2 text--secondary">
          {{ $t("Fill in your information to respond to this ticket") }}
        </div>
        <div class="mt-4">
          <v-autocomplete
            v-model="username"
            :items="users"
            :loading="!loadedUsers"
            :menu-props="{
              closeOnClick: true,
              closeOnContentClick: true
            }"
            item-text="Username"
            :label="$t('User')"
            outlined
            hide-details
          ></v-autocomplete>
        </div>

        <div class="mt-4">
          <v-text-field
            v-model="pin"
            :label="$t('PIN')"
            outlined
          ></v-text-field>
        </div>
      </div>

      <div v-else>
        <div class="loginPadding loginDialog">
          {{ loginError }}
        </div>
      </div>
    </template>

    <template #actions>
      <div v-if="loginError">
        <Btn
          class="pin-btn"
          @click="loginError = null"
          >{{ $t("Ok") }}</Btn
        >
      </div>
      <div v-else>
        <Btn
          class="pin-btn"
          primary
          @click="$emit('update:visibleProp', false)"
          >{{ $t("Cancel") }}</Btn
        >
        <Btn
          class="pin-btn ml-3"
          variant="primary"
          @click="tryLogin"
          >{{ $t("Login") }}</Btn
        >
      </div>
    </template>
  </ModalDialog>
</template>
<style lang="scss" scoped>
@import "../scss/variables";
.pin-btn {
  min-width: 168px !important;
}
</style>
<script>
import DialogCloseButton from "@/components/DialogCloseButton";
import PinPad from "./PinPad";
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";

export default {
  props: ["loginCallback", "visibleProp", "userRole", "callbackData"],
  components: { ModalDialog, PinPad, DialogCloseButton, Btn },
  data() {
    return {
      loginBlock: false,
      loginError: null,
      pin: "",
      username: "",
      users: [],
      loadedUsers: false
    };
  },
  created() {},
  watch: {
    visibleProp: function (v) {
      if (v) {
        if (this.userRole) {
          this.getUsers("Machine Admin");
        } else {
          this.getUsers();
        }
      }
      this.$emit("update:visibleProp", v);
    }
  },
  methods: {
    tryLogin: function () {
      this.loginBlock = true;
      this.$http
        .post("pinlogin", { username: this.username, pin: this.pin })
        .then((res) => {
          this.loginBlock = false;
          this.$emit("update:visibleProp", false);
          this.loginCallback(res.data.token, this.callbackData, this.username);
        })
        .catch((e) => {
          this.loginBlock = false;
          this.loginError = this.$t("Incorrect Username/Password");
        });
    },
    pinCallback(v) {
      this.pin = v;
    },
    getUsers(role) {
      let query;
      if (role) {
        query = `query{
        users(role:"${role}"){
            username
        }
      }`;
      } else {
        query = `query{
        users{
            username
        }
      }`;
      }
      let variables = {
        order: {
          username: "ASC"
        }
      };

      this.$http.post("graphql/", { query, variables }).then((res) => {
        if (res.data.data.users) {
          this.users = res.data.data.users.map((u) => u.username);
        }
        this.loadedUsers = true;
      });
    }
  }
};
</script>
