<template>
  <div class="chart-container">
    <div class="chart-title">
      <div>{{ $t("Production") }}</div>
    </div>
    <bar-chart
      chartName="availabilityChart"
      class="availabilityChart"
      :decimal="0"
      :chartData="chartData"
      :options="options"
      :click-function="clickEvent"
      :axisType="AXISTYPE.SHORTEN"
      :commas="true"
      :chart-options="{
        dataset: {
          maxBarThickness: 50 // number (pixels)
        }
      }"
    />
    <div class="footer">
      <div class="picker">
        <div>
          {{ $t("items per page") }}
          <select @change="sliceResult">
            <option
              value="5"
              :selected="numberSelected === 5"
            >
              5
            </option>
            <option
              value="10"
              :selected="numberSelected === 10"
            >
              10
            </option>
            <option
              value="25"
              :selected="numberSelected === 25"
            >
              25
            </option>
            <option
              value="50"
              :selected="numberSelected === 50"
            >
              50
            </option>
          </select>
        </div>
        <div>
          <i
            class="fa fa-chevron-left"
            @click="previousPage"
            v-bind:class="currentPage <= 0 && 'disabled'"
          />
          {{ `${currentPage + 1}` }} <span>of {{ numberPages }}</span>
          <i
            class="fa fa-chevron-right"
            @click="nextPage"
            v-bind:class="currentPage >= numberPages - 1 && 'disabled'"
          />
        </div>
      </div>
      <i
        class="fa sort"
        @click="toggleSort"
        v-bind:class="sortOrder === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BarChart, { AXISTYPE } from "../../../../components/charts/BarChart";

export default {
  name: "SummaryMachineGroupPerformanceChart",
  components: { BarChart },
  props: ["options", "machineGroupData", "setNewMachineGroup"],
  data() {
    return {
      AXISTYPE: AXISTYPE,
      chartData: [],
      machineGroups: [],
      start: 1,
      end: 1,
      currentPage: 0,
      numberPages: 1,
      numberSelected: 10,
      sortOrder: "desc" //asc
    };
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    })
  },
  created() {
    this.buildChart();
  },
  methods: {
    clickEvent(evt, clickedElements) {
      const machineGroup = this.machineGroups[clickedElements[0]._index];
      if (machineGroup.has_next_group_level) {
        this.setNewMachineGroup(
          machineGroup.grouplevel_pk,
          machineGroup.grouplevel_level_id,
          machineGroup.grouplevel
        );
      }
    },
    toggleSort() {
      this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc";
      this.buildChart();
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.buildChart();
      }
    },
    nextPage() {
      if (this.currentPage < this.numberPages - 1) {
        this.currentPage++;
        this.buildChart();
      }
    },
    sliceResult(e) {
      this.numberSelected = parseInt(e.target.value);
      this.currentPage = 0;
      this.buildChart();
    },
    buildChart() {
      let productionData = [];
      let productionGradients = [];
      let footers = [];
      let titles = [];
      let labels = [];
      let targets = [];
      this.machineGroups = [];

      let sortedArray = [];

      for (const mg of this.machineGroupData) {
        const obj = {
          num: 0,
          target: 0,
          threshold: 0,
          gradient: null,
          footer: "",
          title: "",
          label: ""
        };
        obj.num = mg.produced;
        obj.target = mg.production_target;
        obj.gradient = this.$colors.chart.neutral_gradient;
        obj.footer = "";
        obj.title = mg.grouplevel;
        obj.label = mg.grouplevel;
        obj.machineGroup = mg;

        sortedArray.push(obj);
      }

      sortedArray.sort((a, b) => {
        return this.sortOrder === "desc" ? b.num - a.num : a.num - b.num;
      });

      this.numberPages = Math.ceil(sortedArray.length / this.numberSelected);
      this.start = this.currentPage * this.numberSelected + 1;
      this.end = Number(this.currentPage * this.numberSelected) + Number(this.numberSelected);
      sortedArray = sortedArray.slice(this.start - 1, this.end);

      for (const element of sortedArray) {
        productionData.push(element.num);
        targets.push(element.target);
        productionGradients.push(element.gradient);
        footers.push(element.footer);
        titles.push(element.title);
        labels.push(element.label);
        this.machineGroups.push(element.machineGroup);
      }

      this.chartData = {
        datasets: [
          {
            type: "line",
            data: targets,
            borderWidth: 2,
            pointRadius: 3,
            pointBackgroundColor: this.theme === "dark" ? "white" : "#44B2F3",
            hoverPointRadius: 0,
            borderColor: this.theme === "dark" ? "white" : "#44B2F3",
            titles: titles,
            fill: true,
            label: this.$t("Target"),
            showLine: false
          },
          {
            data: productionData,
            gradients: productionGradients,
            footers: footers,
            titles: titles,
            label: this.$t("Produced")
          }
        ],
        labels: labels
      };
    }
  },
  watch: {
    machineGroupData: function () {
      this.buildChart();
    },
    theme: function () {
      this.buildChart();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";
@import "../../../../scss/mq";

.chart-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .footer {
    display: flex;
    flex: unset;
    align-items: center;
    justify-content: flex-end;

    .sort {
      padding: 0 10px;
      transform: rotate(-90deg);
      cursor: pointer;
    }

    .picker {
      font-size: 12px;
      flex: unset;
      justify-content: flex-end;
      display: flex;

      i {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
        }
      }

      div {
        padding: 0 10px;
        flex: unset;
      }

      select {
        color: white;
        text-decoration: underline;

        .theme--light & {
          color: black;
        }
        option {
          color: black;
        }
      }
    }
  }

  .chart-title {
    color: $blue;
    padding-bottom: 3px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    div {
      flex: unset;
      display: flex;
    }
  }

  .chart-title {
    flex: unset;
  }

  .availabilityChart {
    flex: 1;
    display: grid;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}
</style>
