<template>
  <div class="application-issue-tickets">
    <div class="header">
      <div class="text-search-container">
        <v-text-field
          v-model="applicationIssueSearchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          class="text-search"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </div>
    </div>
    <v-data-table
      item-key="id"
      :headers="applicationIssueTicketsTable.headers"
      :items="applicationIssueTicketsTable.data"
      :page.sync="applicationIssueTicketsTable.page.current"
      :sortBy.sync="applicationIssueTicketsTable.sortBy"
      :sortDesc.sync="applicationIssueTicketsTable.sortDesc"
      :serverItemsLength="applicationIssueTicketsTable.total"
      :loading="applicationIssueTicketsTable.loadingData"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Application Issue Tickets... Please wait')"
      @click:row="goToTicket"
      @update:options="handleUpdatePageOptions"
      disable-pagination
      fixed-header
    >
      <template v-slot:item.issueStartDate="{ item }">
        {{ formatDate(item.issueStartDate) }}
      </template>
      <template v-slot:item.issueEndDate="{ item }">
        {{ formatDate(item.issueEndDate) }}
      </template>
      <template v-slot:item.duration="{ item }">
        {{ formatDuration(item.duration) }}
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn
          class="hidden-xs-only launch-button"
          small
          color="primary"
          fab
          @click.stop.prevent="launchTicket(item)"
        >
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          class="hidden-sm-and-up launch-button"
          small
          color="primary"
          @click.stop.prevent="launchTicket(item)"
        >
          {{ $t("Launch") }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";
import { seconds } from "@/utils/filters";

export default {
  name: "Tickets",
  data() {
    return {
      applicationIssueTicketsTable: new TableData([
        { text: this.$t("Issue Start Date"), value: "issueStartDate" },
        { text: this.$t("Issue End Date"), value: "issueEndDate" },
        { text: this.$t("Duration"), value: "duration" },
        { text: this.$t("Machine"), value: "machineName" },
        { text: this.$t("Reason"), value: "reason" },
        { text: "", value: "link", sortable: false }
      ]),
      applicationIssueSearchText: "",
      searchDebouncerIssueTickets: null
    };
  },
  created() {
    this.getApplicationIssueTickets();
  },
  methods: {
    goToTicket(item) {
      this.$router.push({ name: "application-issue-ticket", params: { ticketId: item.id } });
    },
    launchTicket(item) {
      let routeData = this.$router.resolve({
        name: "application-issue-ticket",
        params: { ticketId: item.id }
      });
      window.open(routeData.href, "_blank");
    },
    formatDate(date) {
      return moment(date).format(this.$datetime.default);
    },
    formatDuration(time) {
      return seconds(time, time > 59 ? false : true);
    },
    async getApplicationIssueTickets() {
      this.applicationIssueTicketsTable.loadingData = true;
      const query = `query(
        ${this.applicationIssueSearchText ? "$search: String!," : ""}
        $ordering: GrapheneElasticOrderingApplicationIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String) {
          applicationIssues(
            ${this.applicationIssueSearchText ? "queryString: $search," : ""}
            filter: { closedDate: {isNull: true}}
            ordering: $ordering,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            facets: [issue_identifier],
          ) {
            facets,
            pageInfo {
              startCursor,
              endCursor,
              hasNextPage,
              hasPreviousPage,
            }
            edges {
              cursor,
              node {
                id,
                machineName,
                issueStartDate,
                issueEndDate,
                reason,
                duration,
                issueIdentifier,
              }
            }
          }
      }`;
      let ordering = { issueEndDate: "DESC" };
      if (this.applicationIssueTicketsTable.sortBy.length > 0) {
        ordering = {};
        this.applicationIssueTicketsTable.sortBy.forEach((sortBy, index) => {
          ordering[sortBy] = this.applicationIssueTicketsTable.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        ordering: ordering,
        before: false,
        after: false
      };
      if (this.applicationIssueSearchText) {
        variables["search"] = this.applicationIssueSearchText;
      }
      if (this.applicationIssueTicketsTable.page.load) {
        Object.assign(variables, this.applicationIssueTicketsTable.page.load);
      } else {
        variables["first"] = this.applicationIssueTicketsTable.page.itemsPerPage;
      }
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.applicationIssues &&
          response.data.data.applicationIssues.edges
        ) {
          this.applicationIssueTicketsTable.data = response.data.data.applicationIssues.edges.map(
            (item) => ({
              ...item.node
            })
          );
        }
        this.applicationIssueTicketsTable.total =
          response.data.data.applicationIssues.facets.issue_identifier.doc_count;
        this.applicationIssueTicketsTable.page.next =
          response.data.data.applicationIssues.pageInfo.endCursor;
        this.applicationIssueTicketsTable.page.prev =
          response.data.data.applicationIssues.pageInfo.startCursor;
        this.applicationIssueTicketsTable.page.load = false;
        this.applicationIssueTicketsTable.loadingData = false;
      } catch (error) {
        this.applicationIssueTicketsTable.loadingData = false;
        console.error(error);
      }
    },
    handleUpdatePageOptions(pageInfo) {
      if (pageInfo.itemsPerPage !== this.applicationIssueTicketsTable.page.itemsPerPage) {
        this.applicationIssueTicketsTable.page.itemsPerPage = pageInfo.itemsPerPage;
        this.getApplicationIssueTickets();
        return;
      }
      if (pageInfo.page > this.applicationIssueTicketsTable.page.current) {
        this.applicationIssueTicketsTable.page.load = {
          after: this.applicationIssueTicketsTable.page.next,
          before: false,
          first: this.applicationIssueTicketsTable.page.itemsPerPage
        };
        this.getApplicationIssueTickets();
        return;
      }
      if (pageInfo.page < this.applicationIssueTicketsTable.page.current) {
        this.applicationIssueTicketsTable.page.load = {
          after: false,
          before: this.applicationIssueTicketsTable.page.prev,
          last: this.applicationIssueTicketsTable.page.itemsPerPage
        };
        this.getApplicationIssueTickets();
      }
    }
  },
  watch: {
    "applicationIssueTicketsTable.sortDesc": function () {
      this.getApplicationIssueTickets();
    },
    applicationIssueSearchText() {
      if (this.searchDebouncerIssueTickets) {
        clearTimeout(this.searchDebouncerIssueTickets);
      }
      this.searchDebouncerIssueTickets = setTimeout(this.getApplicationIssueTickets, 500);
    }
  }
};
</script>
<style lang="scss">
@import "./Tickets.scss";
</style>
