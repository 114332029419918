import Vue from "vue";
import { dateDuration } from "@/ui/DateDuration/utils";

export const UnitTypes = {
  MONEY: "money",
  PCS: "pcs",
  PERCENTAGE: "percentage",
  NUMBER: "number",
  DATE_DURATION: "date-duration"
};

export const unit = (
  amount = 0,
  type,
  {
    locale = "en",
    percentageSignificantDigits = 0,
    maximumSignificantDigits = 3,
    showPercentageSymbol = true
  } = {}
) => {
  const formatOptions = {};
  if (maximumSignificantDigits instanceof Number) {
    formatOptions.maximumSignificantDigitss = maximumSignificantDigits;
  }
  let result = Intl.NumberFormat(locale, formatOptions).format(amount);

  const compact = (number) =>
    Intl.NumberFormat(locale, { maximumSignificantDigits, notation: "compact" }).format(number);

  if (type === UnitTypes.PCS) {
    result = compact(amount);
    return `${result} ${Vue.i18n.translate("pcs")}`;
  }

  if (type === UnitTypes.MONEY) {
    result = compact(amount);
    return `$${result}`;
  }

  if (type === UnitTypes.NUMBER) {
    result = compact(amount);
    return `${result}`;
  }

  if (type === UnitTypes.PERCENTAGE) {
    return `${(result * 100).toFixed(percentageSignificantDigits)}${showPercentageSymbol ? "%" : ""}`;
  }

  if (type === UnitTypes.DATE_DURATION) {
    return dateDuration({ durationValue: amount, type: "short" });
  }

  return result;
};
