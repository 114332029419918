<template>
  <div class="control mb-4">
    <div
      v-if="showTicketControl"
      class="mb-4 btn-wrapper"
    >
      <Btn
        large
        @click="showAssignDialog = true"
      >
        <v-icon size="24">mdi-plus</v-icon>
        {{ $t("Assign") }}
      </Btn>

      <Btn
        large
        class="btn-close"
        variant="error"
        :disable="ticket.closedDate"
        @click="showCloseTicketDialog = true"
      >
        {{ $t("Close") }}
      </Btn>
    </div>

    <Card
      class="timeline mb-4 pa-5 rounded-lg"
      custom-padding
    >
      <MobileOnlyExpansion class="timeline-container d-flex flex-column">
        <template #header>
          <span class="text-body-1 font-weight-medium color--text-primary">{{
            $t("Workflow")
          }}</span>
        </template>
        <template #content>
          <TicketDetailsTWorkflow
            class="mt-n1"
            :ticket="ticket"
          />

          <div
            v-if="!ticket.closedDate"
            class="btn-wrapper mt-auto pt-5"
          >
            <Btn
              v-if="respondFunc && hasntResponded"
              large
              @click="showRespondDialog = true"
            >
              <v-icon
                size="24"
                class="mr-1"
                >mdi-hand-back-left-outline
              </v-icon>
              {{ $t("Respond") }}
            </Btn>
            <Btn
              v-if="respondFunc"
              large
              @click="showPinDialog = true"
            >
              <v-icon
                size="24"
                class="mr-1"
                >mdi-dialpad</v-icon
              >
              {{ $t("Action login") }}
            </Btn>
          </div>
        </template>
      </MobileOnlyExpansion>
    </Card>

    <TicketDetailsSpec
      class="spec mb-4"
      :ticket="ticket"
    />

    <PinLogin
      ref="pinlogin"
      :visible-prop.sync="showPinDialog"
      :login-callback="loginCallback"
    />

    <TicketCloseDialog
      :open="showCloseTicketDialog"
      :ticket="ticket"
      @close="showCloseTicketDialog = false"
    />

    <TicketRespondConfirmation
      :ticket="ticket"
      :open="showRespondDialog"
      :loading="respondDialogLoading"
      @submit="respondToTicket"
      @close="showRespondDialog = false"
    />

    <TicketAssignDialog
      :open="showAssignDialog"
      :ticket="ticket"
      @close="showAssignDialog = false"
      @success="$emit('assign')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { computed, ref, toRefs } from "vue";

import Btn from "@/ui/Btn";
import Card from "@/ui/Card";
import MobileOnlyExpansion from "@/ui/MobileOnlyExpansion";
import PinLogin from "@/components/PinLogin.vue";
import TicketAssignDialog from "@/features/tickets/components/TicketAssignDialog.vue";
import TicketCloseDialog from "@/features/ticket-details/components/TicketDetailsCloseDialog.vue";
import TicketDetailsSpec from "./TicketDetailsSpec.vue";
import TicketDetailsTWorkflow from "./TicketDetailsWorkflow.vue";
import TicketRespondConfirmation from "@/features/ticket-details/components/TicketRespondConfirmation.vue";

export default {
  name: "TicketDetailsControl",
  components: {
    Btn,
    Card,
    MobileOnlyExpansion,
    PinLogin,
    TicketAssignDialog,
    TicketCloseDialog,
    TicketDetailsSpec,
    TicketDetailsTWorkflow,
    TicketRespondConfirmation
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
      required: true
    },
    users: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  emits: ["assign"],

  setup(props) {
    const showAssignDialog = ref(false);
    const showRespondDialog = ref(false);
    const respondDialogLoading = ref(false);
    const showPinDialog = ref(false);

    const showCloseTicketDialog = ref(false);

    const { ticket } = toRefs(props);
    const showTicketControl = computed(() => {
      return !ticket.value.closedDate;
    });

    return {
      showCloseTicketDialog,
      showAssignDialog,
      showRespondDialog,
      showPinDialog,
      respondDialogLoading,
      showTicketControl
    };
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    hasntResponded() {
      let ids = this.ticket.responding
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("'", "")
        .split(",");
      return !ids.includes(this.user.id.toString());
    }
  },
  methods: {
    assignFunc(users) {
      let newUserIds = [];
      users.forEach((user) => {
        newUserIds.push(user.id);
      });
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: this.ticket_id, // ?????
        helpTicketData: {
          assignNew: newUserIds
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.assignedTo = res.data.data.updateHelpTicket.helpTicket.assignedTo;
            context.ticket.assignedToNames =
              res.data.data.updateHelpTicket.helpTicket.assignedToNames;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    respondFunc(pinJwt) {
      let oldJwt = this.$http.defaults.headers["Authorization"];
      if (pinJwt) {
        this.$http.defaults.headers["Authorization"] = `JWT ${pinJwt}`;
      }
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            firstResponseBy,
            firstResponseByName,
            firstResponseDate,
            responding,
            respondingNames,
          }
        }
      }`;

      const variables = {
        id: this.ticket.id, // ?????
        helpTicketData: {
          responding: true
        }
      };

      this.respondDialogLoading = true;
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          var context = this;
          setTimeout(function () {
            context.ticket.firstResponseBy =
              res.data.data.updateHelpTicket.helpTicket.firstResponseBy;
            context.ticket.firstResponseByName =
              res.data.data.updateHelpTicket.helpTicket.firstResponseByName;
            context.ticket.firstResponseDate =
              res.data.data.updateHelpTicket.helpTicket.firstResponseDate;
            context.ticket.responding = res.data.data.updateHelpTicket.helpTicket.responding;
            context.ticket.respondingNames =
              res.data.data.updateHelpTicket.helpTicket.respondingNames;
            context.respondingToTicket = false;
            context.respondingToDialog = false;
          }, 500);
        })
        .catch((res) => {
          this.errors = res.errors;
        })
        .finally(() => {
          this.$http.defaults.headers["Authorization"] = oldJwt;
          this.pinJwt = null; // Clear pinJwt after use

          this.respondDialogLoading = false;

          this.showRespondDialog = false;
        });
    },
    respondToTicket() {
      this.respondingToTicket = true;
      this.respondFunc(this.pinJwt);
    },
    loginCallback(v) {
      this.pinJwt = v;
      this.respondToTicket();
      this.pinJwt = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 700px) and (max-width: 1100px) {
  .control {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 12px;
    grid-template-areas:
      "btns nothing"
      "timeline spec";
  }

  .btn-wrapper {
    grid-area: btns;
  }

  .timeline {
    grid-area: timeline;
  }

  .spec {
    grid-area: spec;
  }
}

.btn-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.timeline-container {
  height: 100%;

  ::v-deep {
    .v-expansion-panel {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .v-expansion-panel-content {
      flex: 1;
    }

    .v-expansion-panel-content__wrap {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
</style>
