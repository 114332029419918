<template>
  <portal to="side-drawer">
    <sidebar
      :value="isOpen"
      :title="data && data.groups[0].name + ' ' + $t('Drill-In')"
      class="sidebar-container"
      width="90%"
      v-on="$listeners"
    >
      <template #beforeClose>
        <slot name="beforeClose"></slot>
      </template>
      <slot name="default">
        <div>
          <ZoneControlWeeklyPagination
            v-if="data && data.groups[0].id"
            :group_id="data.groups[0].id"
            @click="$emit('click-drill-in', $event)"
          />

          <div class="mt-5">
            <PreloaderPanel v-if="loading" />
            <ZoneControlWeeklyGroup
              v-for="group in groups"
              v-else
              :key="group.name"
              :enable-expand="true"
              :dates="dates"
              :is-machine="group.isMachine"
              class="mb-11"
              :shifts="shifts"
              :data="group"
              :show-change-button="false"
              @click-drill-in="$emit('click-drill-in', $event)"
            />
          </div>
        </div>
      </slot>

      <template #append>
        <div></div>
      </template>
    </sidebar>
  </portal>
</template>

<script>
import Sidebar from "@/ui/Sidebar/Sidebar.vue";
import ZoneControlWeeklyGroup from "@/features/zone-control-weekly/components/ZoneControlWeeklyGroup.vue";
import { computed, toRefs } from "vue";
import PreloaderPanel from "@/ui/PreloaderPanel/PreloaderPanel.vue";
import ZoneControlWeeklyPagination from "@/features/zone-control-weekly/components/ZoneControlWeeklyPagination.vue";

export default {
  name: "ZoneControlSidebar",
  components: {
    ZoneControlWeeklyPagination,
    PreloaderPanel,
    ZoneControlWeeklyGroup,
    Sidebar
  },
  props: {
    isOpen: { type: Boolean, default: false },
    portalNodeId: { type: String, default: "side-drawer" },

    shifts: {
      type: Object,
      default: undefined
    },
    dates: {
      type: Array,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  emits: ["apply", "cancel", "clear", "click-drill-in"],
  setup(props) {
    const { data } = toRefs(props);

    const groups = computed(() => {
      if (!data.value) {
        return null;
      }

      if (data.value.machines && data.value.machines.length) {
        return data.value.machines.map((item) => ({ ...item, isMachine: true }));
      }

      return data.value && data.value.groups.filter((_, index) => index !== 0);
    });

    return {
      groups
    };
  }
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  ::v-deep .sidebar {
    background: var(--bg-secondary) !important;
  }

  ::v-deep table thead tr:not(.v-data-table__progress) th {
    background-color: var(--bg-tertiary) !important;
  }
}
::v-deep {
  .v-input__slot:before {
    border-color: var(--border-input) !important;
  }

  .v-select .v-select__selection--comma {
    color: var(--text-primary);
  }
}
</style>
