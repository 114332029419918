<template>
  <div class="welcome">
    <div
      class="header"
      id="header"
    >
      <div class="card">
        <div class="user-info section">
          <user-avatar :user="user" />
          <div class="group1">
            <div class="personal">
              <div>{{ $t("Welcome ") }} {{ user.fullname }}</div>
              <div>{{ user.title }}</div>
              <div
                class="date"
                v-if="vw <= 640"
              >
                {{ moment(new Date()).format("MMM Do, YYYY, hh:mm A") }}
              </div>
            </div>
            <div
              class="settings"
              v-if="vw > 640"
            >
              <div
                class="circleBtn"
                @click="setRightPanel('search')"
              >
                <i class="icon fa fa-search" />
              </div>
              <v-dialog
                v-model="favoritesOpen"
                width="auto"
                :persistent="false"
              >
                <v-card class="favorite-dialog">
                  <DialogCloseButton :onClick="() => (favoritesOpen = false)" />
                  <v-card-title>
                    <div class="headline">{{ $t("Favorites") }}</div>
                  </v-card-title>
                  <v-card-text>
                    <div class="favorites">
                      <div
                        class="favorite"
                        v-for="favorite in favorites"
                      >
                        <a
                          :href="favorite.url"
                          @click.stop="close"
                        >
                          <div class="label">
                            {{ favorite.name ? favorite.name : favorite.url }}
                          </div>
                        </a>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="close"
                      color="warning"
                      @click="favoritesOpen = false"
                      >{{ $t("Close") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <router-link
                class="circleBtn"
                :to="{ name: 'settings' }"
                @click.stop="close"
              >
                <i class="icon fa fa-gear" />
              </router-link>
              <div class="circleBtn">
                <i
                  @click="logoutUser()"
                  class="icon fa fa-power-off"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="settings"
          v-if="vw <= 640"
        >
          <div class="circleBtn">
            <i
              @click="setRightPanel('search')"
              class="icon fa fa-search"
            />
          </div>
          <a
            class="circleBtn"
            :href="favorites[0].url"
            @click.stop="close"
            v-if="favorites.length > 0"
          >
            <i class="icon fa fa-heart" />
          </a>
          <router-link
            class="circleBtn"
            :to="{ name: 'settings' }"
            @click.stop="close"
          >
            <i class="icon fa fa-gear" />
          </router-link>
          <div class="circleBtn">
            <i
              @click="logoutUser()"
              class="icon fa fa-power-off"
            />
          </div>
        </div>
        <div
          class="local_info section"
          v-if="vw > 640"
        >
          <div class="date">
            {{ moment(new Date()).format("MMM Do, YYYY, hh:mm A") }}
          </div>
          <div class="weather section">
            <div
              class="row"
              v-if="weather"
            >
              <div class="left">
                <p>{{ location.lookup }}</p>
                <p>
                  <span class="label">{{ $t("High") }}</span> :
                  <span
                    >{{ parseFloat(weather.forecast.forecastday[0].day.maxtemp_f).toFixed(1) }} F
                  </span>
                </p>
                <p>
                  <span class="label">{{ $t("Low") }}</span> :
                  <span
                    >{{ parseFloat(weather.forecast.forecastday[0].day.mintemp_f).toFixed(1) }} F
                  </span>
                </p>
              </div>
              <div class="right">
                <img
                  :src="weather.current.condition.icon"
                  width="100%"
                />
                <p>{{ $t(weather.current.condition.text) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="weather section mobile"
          v-else
        >
          <div class="weather section">
            <div
              class="row"
              v-if="weather"
            >
              <div class="item">
                <p class="label">{{ $t("Location") }}</p>
                <p>{{ location.lookup }}</p>
              </div>
              <div class="item">
                <p class="label">{{ $t("Weather") }}</p>
                <p>
                  <span>{{ $t("High") }}</span> :
                  <span
                    >{{ parseFloat(weather.forecast.forecastday[0].day.maxtemp_f).toFixed(1) }} F
                  </span>
                </p>
                <p>
                  <span>{{ $t("Low") }}</span> :
                  <span
                    >{{ parseFloat(weather.forecast.forecastday[0].day.mintemp_f).toFixed(1) }} F
                  </span>
                </p>
              </div>
              <div class="item center">
                <img
                  :src="weather.current.condition.icon"
                  width="100%"
                />
                <p class="center">{{ weather.current.condition.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="metrics"
      id="metrics"
    >
      <div class="metricRow">
        <div class="card safety">
          <div class="card-header">
            <h2>{{ $t("Safety (YTD)") }}</h2>
          </div>
          <div class="card-content">
            <div class="info-section">
              <router-link
                class="left clickable"
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/zone_control/safety`"
                v-if="topMachineGroup"
              >
                <h2>{{ safety.daysSince }}</h2>
                <p>{{ $t("Days Since Last Recordable") }}</p>
              </router-link>
              <div
                v-else
                class="left"
              >
                <h2>{{ safety.daysSince }}</h2>
                <p>{{ $t("Days Since Last Recordable") }}</p>
              </div>
              <div class="right">
                <router-link
                  class="clickable"
                  :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/zone_control/safety`"
                  v-if="topMachineGroup"
                >
                  <p>
                    <span class="redColor">{{ safety.recordable }}</span> {{ $t("Recordable YTD") }}
                  </p>
                  <p>
                    <span class="yellowColor">{{ safety.firstAid }}</span> {{ $t("First Aid YTD") }}
                  </p>
                </router-link>
                <div v-else>
                  <p>
                    <span class="redColor">{{ safety.recordable }}</span> {{ $t("Recordable YTD") }}
                  </p>
                  <p>
                    <span class="yellowColor">{{ safety.firstAid }}</span> {{ $t("First Aid YTD") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="card quality" v-if="vw>640">-->
        <!--          <div class="card-header">-->
        <!--            <h2>{{$t('Quality (YTD)')}}</h2>-->
        <!--          </div>-->
        <!--          <div class="card-content">-->
        <!--            <div class="info-section">-->
        <!--              <div class="full">-->
        <!--                <p><span class="redColor">06</span> {{$t('Customer Complaints')}}</p>-->
        <!--                <p><span class="blueColor">20,000</span> {{$t('Internal PPM')}}</p>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="card iot">
          <div class="card-header">
            <h2>{{ $t("IoT (Last 7 Days)") }}</h2>
          </div>
          <div class="card-content">
            <div class="info-section">
              <div
                class="left clickable"
                @click="$router.push('/iot/exceptions')"
              >
                <h2 class="redColor">{{ iot.tickets }}</h2>
                <p>{{ $t("Tickets") }}</p>
              </div>
              <div
                class="right clickable"
                @click="$router.push('/iot/anomalies')"
              >
                <p>
                  <span class="yellowColor">{{ numberWithCommas(iot.limit) }}</span>
                  {{ $t("Limit Anomalies") }}
                </p>
                <p>
                  <span class="yellowColor">{{ numberWithCommas(iot.xbar) }}</span>
                  {{ $t("XBAR Anomalies") }}
                </p>
                <p>
                  <span class="yellowColor">{{ numberWithCommas(iot.pc) }}</span>
                  {{ $t("PC Anomalies") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card ldms">
          <div class="card-header">
            <h2>{{ $t("LDMS MTD") }}</h2>
          </div>
          <div class="card-content">
            <div
              class="info-section"
              v-if="ldmsSummary"
            >
              <router-link
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/tickets/1`"
                class="left clickable"
                v-if="topMachineGroup"
              >
                <h2 class="redColor">{{ ldmsSummary.open }}</h2>
                <p>{{ $t("Open Tickets") }}</p>
              </router-link>
              <div
                v-else
                class="left"
              >
                <h2 class="redColor">{{ ldmsSummary.open }}</h2>
                <p>{{ $t("Open Tickets") }}</p>
              </div>
              <router-link
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/tickets/1`"
                class="right clickable"
                v-if="topMachineGroup"
              >
                <h2 class="greenColor">{{ ldmsSummary.closed }}</h2>
                <p>{{ $t("Closed Tickets") }}</p>
              </router-link>
              <div
                v-else
                class="right"
              >
                <h2 class="greenColor">{{ ldmsSummary.closed }}</h2>
                <p>{{ $t("Closed Tickets") }}</p>
              </div>
              <router-link
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/tickets/1`"
                class="bottom clickable"
                v-if="topMachineGroup"
              >
                <h2 class="blueColor">{{ $t(seconds(ldmsSummary.avg_open_time)) }}</h2>
                <p>{{ $t("Avg Open Time") }}</p>
              </router-link>
              <div
                v-else
                class="bottom"
              >
                <h2 class="blueColor">{{ $t(seconds(ldmsSummary.avg_open_time)) }}</h2>
                <p>{{ $t("Avg Open Time") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card operations">
          <div class="card-header">
            <h2>{{ $t("Operations (Real Time)") }}</h2>
          </div>
          <div
            class="card-content"
            v-if="statusSummary"
          >
            <div class="info-section">
              <router-link
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/summary`"
                class="left half clickable"
                v-if="topMachineGroup"
              >
                <h2 class="greenColor label">{{ statusSummary.production }}</h2>
                <p>{{ $t("In Production") }}</p>
              </router-link>
              <div
                v-else
                class="left half"
              >
                <h2 class="greenColor label">{{ statusSummary.production }}</h2>
                <p>{{ $t("In Production") }}</p>
              </div>
              <router-link
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/summary`"
                class="right half clickable"
                v-if="topMachineGroup"
              >
                <h2 class="redColor label">{{ statusSummary.non }}</h2>
                <p>{{ $t("Non Production") }}</p>
              </router-link>
              <div
                v-else
                class="right half"
              >
                <h2 class="redColor label">{{ statusSummary.non }}</h2>
                <p>{{ $t("Non Production") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card helpMetric">
          <div class="card-header">
            <h2>{{ $t("P911 (Current Week)") }}</h2>
          </div>
          <div class="card-content">
            <div
              class="info-section"
              v-if="helpSummary"
            >
              <router-link
                class="left clickable"
                :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/tickets/0`"
                v-if="topMachineGroup"
              >
                <h2>{{ helpSummary.open.doc_count }}</h2>
                <p>{{ $t("Open Tickets") }}</p>
              </router-link>
              <div
                v-else
                class="left"
              >
                <h2>{{ helpSummary.open.doc_count }}</h2>
                <p>{{ $t("Open Tickets") }}</p>
              </div>
              <div class="right">
                <div
                  v-for="(tag, tag_index) in helpSummary.tags"
                  :key="tag_index"
                >
                  <router-link
                    :to="`/group/${topMachineGroup.level.level}/${topMachineGroup.id}/${topMachineGroup.pk}/tickets-new/0`"
                    class="clickable"
                    v-if="topMachineGroup"
                  >
                    <span :style="{ color: tag.color }">{{ tag.count }}</span>
                    {{ tag.name }}
                  </router-link>
                  <div v-else>
                    <span :style="{ color: tag.color }">{{ tag.count }}</span>
                    {{ tag.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h2>{{ $t("Analytics") }}</h2>
          </div>
          <div class="card-content analytics-links">
            <div
              class="clickable btnLink"
              @click="$router.push('/analytics/operator/realtime')"
            >
              <i class="fa fa-user" />
              <p>{{ $t("Operator") }}</p>
            </div>
            <div
              class="clickable btnLink"
              @click="$router.push('/analytics/production_report')"
            >
              <i class="fa fa-file-text-o" />
              <p>{{ $t("Production") }}</p>
            </div>
          </div>
        </div>
        <!--        <div class="card ci" v-if="vw>640">-->
        <!--          <div class="card-header">-->
        <!--            <h2>{{$t('Continuous Improvement (YTD)')}}</h2>-->
        <!--          </div>-->
        <!--          <div class="card-content">-->
        <!--            <div class="info-section ">-->
        <!--              <div class="left">-->
        <!--                <h2 class="greenColor">$3.2M</h2>-->
        <!--                <p>{{$t('Cost Savings')}}</p>-->
        <!--              </div>-->
        <!--              <div class="right">-->
        <!--                <p><span class="greenColor">20</span> {{$t('Projects On-Time')}}</p>-->
        <!--                <p><span class="redColor">28</span> {{$t('Projects Delayed')}}</p>-->
        <!--                <p><span class="yellowColor">05</span> {{$t('Projects Cancelled')}}</p>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div
      class="bulletin"
      id="bulletin"
    >
      <div class="card">
        <div class="card-header">
          <h2>{{ $t("Bulletin Board") }}</h2>
        </div>
        <div class="card-content">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(section, section_index) in bulletinSections"
              :key="section_index"
              @change="loadBulletinRows(section)"
            >
              <v-expansion-panel-header
                >{{ section.count }} : {{ section.name }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-data-table
                  v-if="selectedSection.key == section.key"
                  height="calc(100% - 3.25rem)"
                  :loading="loadingBulletinRows"
                  :items="bulletinRows"
                  :sortBy.sync="section.sortBy"
                  :sortDesc.sync="section.sortDesc"
                  :headers="section.headers"
                  :page.sync="section.page.current"
                  :itemsPerPage.sync="section.page.itemsPerPage"
                  :serverItemsLength="section.count"
                  :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
                  :loading-text="section.loadingText"
                  @click:row="goToRow"
                  disable-pagination
                  fixed-header
                  multi-sort
                >
                  <template v-slot:item.ticketedDate="{ item }">
                    {{ moment(item.node.ticketedDate).format($datetime.default) }}
                  </template>
                  <template v-slot:item.title="{ item }">
                    {{ item.node.title }}
                  </template>
                  <template v-slot:item.details="{ item }">
                    {{ item.node.details }}
                  </template>
                  <template v-slot:item.launch="{ item }">
                    <v-btn
                      small
                      color="blue"
                      fab
                      v-if="section.launch"
                      @click.stop.prevent="section.launch(item)"
                    >
                      <v-icon>fa-external-link-square</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../scss/variables.scss";
@import "../scss/mq";
.favorite-dialog {
  .favorites {
    .favorite {
      min-width: 15rem;
      text-align: center;
      background-color: $blue;
      padding: 0.5rem 1rem;
      margin: 10px;
      border-radius: 0.5rem;
      cursor: pointer;
      a {
        cursor: pointer;
        color: white;
        text-decoration: none;
      }
    }
  }
  .close {
    color: $grey !important;
  }
}

.welcome {
  position: relative;
  margin: 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  #header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8rem;
  }
  #metrics {
    position: absolute;
    top: 9rem;
    left: 0;
    width: calc(50% - 0.5rem);
    height: calc(100% - 9rem);
  }
  #bulletin {
    position: absolute;
    top: 9rem;
    right: 0;
    width: calc(50% - 0.5rem);
    height: calc(100% - 9rem);
  }
  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8rem;
    height: 100%;
    overflow: hidden;
    .card-content {
      width: 100%;
    }
  }
  .header {
    .section {
      margin: 1rem;
    }
    .circleBtn {
      cursor: pointer;
    }
    .user-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .v-avatar {
        margin: 1rem;
      }
      .personal {
        width: fit-content;
        margin: 0.5rem;
      }
    }
    .settings {
      width: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .circleBtn {
        margin: 0.25rem;
        background-color: $blue;
        color: $white;
      }
    }
    .searchBox {
      width: 20rem;
      max-width: 20rem;
      margin-right: auto;
      margin-top: 0;
    }
    .local_info {
      .date {
        text-align: right;
      }
      .weather {
        overflow: hidden;
        margin: 0.5rem;
        .row {
          height: 100%;
          align-items: center;
          justify-content: center;
          .left,
          .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.5rem;
          }
          .left {
            p {
              margin: 0 1rem;
              span {
                width: 50%;
              }
            }
          }
        }
        img {
          max-height: 64px;
          max-width: 64px;
        }
      }
    }
  }
  .metrics {
    .clickable {
      cursor: pointer;
      text-decoration: none;
      color: inherit;
    }
    .metricRow {
      height: calc(100% + 1rem);
      display: flex;
      flex-wrap: wrap;
      margin: -0.5rem;
      .card {
        width: calc(50% - 1rem);
        height: calc(33% - 1rem);
        margin: 0.5rem;
        &.safety {
          border-left: 5px solid $green;
          .left {
            max-width: 126px;
          }
        }
        &.iot {
          border-left: 5px solid $blue;
        }
        &.ldms {
          border-left: 5px solid #06b199;
          .info-section {
            display: flex;
            flex-wrap: wrap;
            .left,
            .right {
              margin: 0;
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              p {
                text-align: center;
              }
            }
            .bottom {
              margin: 1rem 0;
              text-align: center;
              width: 100%;
              span {
                font-size: 1.15rem;
              }
            }
          }
        }
        &.operations {
          border-left: 5px solid $red;
        }
        &.supply {
          border-left: 5px solid $yellow;
          filter: blur(3px);
        }
        &.helpMetric {
          border-left: 5px solid #9cc33d;
        }
        &.ci {
          border-left: 5px solid pink;
          filter: blur(3px);
        }
        .analytics-links {
          justify-content: space-around !important;
          .btnLink {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .fa {
              background-color: $blue;
              height: 4rem;
              width: 4rem;
              text-align: center;
              font-size: 2rem;
              line-height: 4rem;
              border-radius: 2rem;
            }
          }
        }
        .card-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .info-section {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: -1rem;
            padding: 1rem;
          }
          .left,
          .right,
          .full,
          .bottom {
            text-align: center;
            margin: 0 1rem;
            p {
              width: 100%;
              font-size: 1.15rem;
              span {
                margin: 0.5rem;
                font-weight: 600;
              }
            }
            .redColor {
              color: $red;
              font-weight: 600;
            }
            .yellowColor {
              color: $yellow;
              font-weight: 600;
            }
            .blueColor {
              color: $blue;
              font-weight: 600;
            }
            .greenColor {
              color: $green;
              font-weight: 600;
            }
          }
          .full {
            width: 100%;
            text-align: left;
            span {
              width: 5rem;
            }
          }
          .right {
            text-align: left;
          }
          .half {
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
  .bulletin {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
    .v-expansion-panel--active > .v-expansion-panel-header {
      background-color: $blue;
    }
    .theme--dark.v-expansion-panels.v-expansion-panels--focusable
      .v-expansion-panel-header--active::before {
      opacity: 0;
    }
  }
}
@include media_below($smViewport) {
  .welcome {
    display: flex;
    flex-wrap: wrap;
    #header {
      position: relative;
      order: 1;
      width: calc(100vw - 2rem);
      height: auto;
      .card {
        flex-wrap: wrap;
        .user-info {
          .v-avatar {
            margin: 0;
          }
        }
        .settings {
          height: auto;
          width: 100%;
          margin: 0 1rem;
          padding-top: 0;
        }
        .weather {
          width: calc(100% - 0.5rem);
          margin: 0.75rem;
          .row {
            justify-content: space-between;
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            .item {
              width: 33%;
              .label {
                color: $blue;
              }
              &.center {
                display: flex;
                justify-content: center;
                flex-direction: column;
              }
              img {
                margin: 0 auto;
                margin-top: -1rem;
                width: 5rem;
              }
              .center {
                margin-top: -1rem;
                text-align: center;
              }
            }
          }
        }
        .local_info {
          .date {
            text-align: center;
          }
          .right {
            max-width: 50%;
            p {
              text-align: center;
            }
          }
        }
      }
    }
    #metrics {
      position: relative;
      order: 3;
      top: 2rem;
      width: 100%;
      .card {
        width: calc(100vw - 2rem);
        height: auto;
        min-height: unset;
      }
    }
    #bulletin {
      position: relative;
      order: 2;
      top: 1rem;
      height: auto;
      width: 100%;
      .card-content {
        padding-bottom: 1rem;
        .v-data-table__mobile-row__header {
          color: $blue;
          font-weight: bold;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import UserAvatar from "@/ui/UserAvatar";

import { numberWithCommas, seconds } from "../utils/filters";
import router from "@/router";

export default {
  components: { DialogCloseButton, UserAvatar },
  data() {
    return {
      favoritesOpen: false,
      favorites: [],
      weather: null,
      searchDash: "",
      safety: {
        firstAid: 0,
        recordable: 0,
        daysSince: 0
      },
      iot: {
        tickets: 0,
        limit: 0,
        xbar: 0,
        pc: 0
      },
      ldmsSummary: false,
      helpSummary: false,
      statusSummary: false,

      bulletinLoaded: false,
      loadingBulletinRows: false,
      bulletinRows: [],
      bulletinSections: [
        {
          key: "help",
          name: this.$t("P911 Tickets"),
          count: 0,
          headers: [],
          fields: "id,ticketedDate,details,machineName,title",
          esSearchName: "helpTickets",
          filterName: "GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter",
          filters: {},
          orderingName: "GrapheneElasticOrderingHelpTicketSearchConnectionBackendFilter",
          ordering: { ticketedDate: "DESC" },
          sortBy: [],
          sortDesc: false,
          routeName: "help_ticket"
        },
        {
          key: "anomalies",
          name: this.$t("Issue Tickets"),
          count: 0,
          headers: [],
          fields: "id,ticketedDate,title,machineName,title,reason",
          esSearchName: "issues",
          filterName: "GrapheneElasticFilterIssueSearchConnectionBackendFilter",
          filters: {
            ticketedDate: { exists: true },
            closedDate: { exists: false }
          },
          orderingName: "GrapheneElasticOrderingIssueSearchConnectionBackendFilter",
          ordering: { ticketedDate: "DESC" },
          sortBy: [],
          sortDesc: false,
          routeName: "ticket"
        },
        {
          key: "announcements",
          name: this.$t("Announcements"),
          count: 0,
          headers: [
            { text: this.$t("Title"), value: "title" },
            { text: "", value: "launch", sortable: false }
          ],
          fields: "id,ticketedDate,title",
          esSearchName: "issues",
          filterName: "GrapheneElasticFilterIssueSearchConnectionBackendFilter",
          filters: {
            ticketedDate: { exists: true },
            closedDate: { exists: false }
          },
          orderingName: "GrapheneElasticOrderingIssueSearchConnectionBackendFilter",
          ordering: { ticketedDate: "DESC" },
          sortBy: [],
          sortDesc: false,
          routeName: "ticket",
          launch: this.launchAnnouncement
        }
      ],
      selectedSection: false,
      loadTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      location: "session/Location",
      sharedDevice: "session/SharedDevice",
      vw: "app/VW",
      machineGroups: "dbCache/machineGroups"
    }),
    weatherIcon() {
      if (this.weather) {
        let cdn = this.weather.condition.icon.split(".com")[1];
        return "../assets" + cdn;
      }
    },
    bulletinChanges() {
      return this.bulletinSections.map((section) => section.sortBy + section.sortDesc);
    },
    topMachineGroup() {
      return this.machineGroups.find(
        (group) =>
          group.pk == this.machine_group_pk ||
          (group.parent === null && group.allChildGroups.includes(this.machine_group_pk))
      );
    },
    machine_group_pk() {
      if (this.$route.params.machine_group_pk) {
        return Number(this.$route.params.machine_group_pk);
      } else {
        let group = this.machineGroups.find((group) => group.parent === null);
        return group.pk;
      }
    }
  },
  watch: {
    topMachineGroup() {
      this.loadAll();
    },
    machine_group_pk() {
      this.loadAll();
    },
    bulletinChanges(newValue, oldValue) {
      newValue.forEach((section, section_index) => {
        if (section != oldValue[section_index]) {
          let sec = this.bulletinSections[section_index];
          if (this.loadTimeout) {
            clearTimeout(this.loadTimeout);
          }
          let func = this.loadBulletinRows;
          this.loadTimeout = setTimeout(function () {
            func(sec);
          }, 250);
        }
      });
    },
    bulletinOrderChanges(newValue, oldValue) {
      newValue.forEach((section, section_index) => {
        if (section != oldValue[section_index]) {
          /* empty */
        }
      });
    },
    vw() {
      if (this.vw <= 640) {
        this.bulletinSections[0]["headers"] = [
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Machine / Asset"), value: "node.machineName" },
          { text: this.$t("Details"), value: "details" }
        ];
        this.bulletinSections[1]["headers"] = [
          { text: this.$t("Ticket ID"), value: "title" },
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Machine / Asset"), value: "node.machineName" },
          { text: this.$t("Details"), value: "node.reason" }
        ];
      } else {
        this.bulletinSections[0]["headers"] = [
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Details"), value: "details" },
          { text: "", value: "launch", sortable: false }
        ];
        this.bulletinSections[1]["headers"] = [
          { text: this.$t("Ticket ID"), value: "title" },
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: "", value: "launch", sortable: false }
        ];
      }
    }
  },
  created() {
    this.loadAll();
  },
  methods: {
    loadAll() {
      this.loadWeather();
      this.loadBulletin();
      this.loadSafety();
      this.loadIot();
      this.loadLDMS();
      this.loadHelpMetrics();
      if (this.vw <= 640) {
        this.bulletinSections[0]["headers"] = [
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Machine / Asset"), value: "node.machineName" },
          { text: this.$t("Details"), value: "details" }
        ];
        this.bulletinSections[1]["headers"] = [
          { text: this.$t("Ticket ID"), value: "title" },
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Machine / Asset"), value: "node.machineName" },
          { text: this.$t("Details"), value: "node.reason" }
        ];
      } else {
        this.bulletinSections[0]["headers"] = [
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: this.$t("Details"), value: "details" },
          { text: "", value: "launch", sortable: false }
        ];
        this.bulletinSections[1]["headers"] = [
          { text: this.$t("Ticket ID"), value: "title" },
          { text: this.$t("Creation Date"), value: "ticketedDate" },
          { text: "", value: "launch", sortable: false }
        ];
      }
    },
    seconds,
    numberWithCommas,
    moment,
    ...mapActions({
      setRightPanel: "components/SetRightPanel",
      logout: "session/Logout"
    }),
    logoutUser() {
      if (this.sharedDevice) {
        if (this.$auth) {
          this.$auth.isAuthenticated().then((authenticated) => {
            if (authenticated) {
              this.$auth.signOut();
            } else {
              this.logout();
            }
          });
        }
      } else {
        this.logout();
      }
    },
    loadWeather() {
      this.$http
        .get("https://api.weatherapi.com/v1/forecast.json", {
          params: {
            key: "50d11ced3482453294012724212101",
            q: this.location.lookup,
            lang: this.$i18n.locale() || "en"
          }
        })
        .then((res) => {
          this.weather = res.data;
        });
    },
    loadSafety() {
      const params = {};
      if (this.topMachineGroup) {
        params["machine_group_id"] = this.topMachineGroup.pk;
      }
      this.$http
        .get("safety/safety_metric/", { params: { ...params, ...{ metric: "first_aid_ytd" } } })
        .then((res) => {
          this.safety.firstAid = res.data;
        });
      this.$http
        .get("safety/safety_metric/", { params: { ...params, ...{ metric: "recordable_ytd" } } })
        .then((res) => {
          this.safety.recordable = res.data;
        });
      this.$http
        .get("safety/safety_metric/", { params: { ...params, ...{ metric: "recordable_last" } } })
        .then((res) => {
          this.safety.daysSince = res.data;
        });
    },
    loadIot() {
      let params = {
        from_date: moment().startOf("day").subtract(7, "d").toISOString(),
        to_date: moment().toISOString()
      };
      if (this.topMachineGroup) {
        params["machine_group_id"] = this.topMachineGroup.pk;
      }
      this.$http.get("iot-exceptions/summary/", { params }).then((res) => {
        this.iot.tickets = res.data.open_tickets.doc_count;
        this.iot.limit = res.data.limit.doc_count;
        this.iot.xbar = res.data.xbar.doc_count;
        this.iot.pc = res.data.pc.doc_count;
      });
    },
    loadLDMS() {
      let params = {
        from_date: moment().startOf("month").toISOString(),
        to_date: moment().toISOString()
      };
      if (this.topMachineGroup) {
        params["machine_group_id"] = this.topMachineGroup.pk;
      }
      this.$http.get("exceptions/ldms_summary/", { params }).then((res) => {
        this.ldmsSummary = res.data;
      });
    },
    loadHelpMetrics() {
      let params = {
        from_date: moment().startOf("week").toDate(),
        to_date: moment().endOf("week").toDate()
      };
      if (this.topMachineGroup) {
        params["machine_group_id"] = this.topMachineGroup.pk;
      }
      this.$http.get("help/summary/", { params }).then((res) => {
        this.helpSummary = res.data;
      });
    },
    loadBulletin() {
      this.bulletinLoaded = false;
      let params = {};
      if (this.topMachineGroup) {
        params["machine_group_id"] = this.topMachineGroup.pk;
      }
      this.$http.get("welcome/summary/", { params }).then((res) => {
        this.bulletinSections.forEach((section) => {
          _.merge(section.filters, res.data[section.key].filters);
          section["count"] = res.data[section.key].count;
          section["page"] = {
            itemsPerPage: 10,
            current: 1,
            load: false,
            next: null,
            prev: null
          };
        });
        this.statusSummary = res.data["status"];
        this.bulletinLoaded = true;
      });
    },
    loadBulletinRows(section) {
      this.loadingBulletinRows = true;
      if (this.selectedSection != section) {
        this.selectedSection = section;
        this.bulletinRows = [];
      }
      if (section.key == "announcements") {
        this.loadAnnouncements();
      } else {
        const query = `query (
          $filters: ${section.filterName}!,
          $ordering: ${section.orderingName}!,
          $first: Int,
          $last: Int,
          $after: String,
          $before: String){
          ${section.esSearchName} (
            filter: $filters,
            ordering: $ordering,
            first: $first,
            last: $last,
            after: $after,
            before: $before) {
            edges{
              cursor,
              node{${section.fields}}
            }
          }
        }`;

        if (section.sortBy && section.sortBy.length > 0) {
          section.ordering = {};
          section.sortBy.forEach((sortBy, index) => {
            section.ordering[sortBy] = section.sortDesc ? "DESC" : "ASC";
          }, this);
        }
        const variables = {
          filters: section.filters,
          ordering: section.ordering,
          before: false,
          after: false,
          search: ""
        };
        if (section.page.load) {
          Object.assign(variables, section.page.load);
        } else {
          variables["first"] = section.page.itemsPerPage;
        }
        this.$http
          .post("graphql/", { query: query, variables: variables })
          .then((res) => {
            this.bulletinRows = res.data.data[section.esSearchName].edges;
            section.page.next = res.data.data.issues.pageInfo.endCursor;
            section.page.prev = res.data.data.issues.pageInfo.startCursor;
            section.page.load = false;
            this.loadingBulletinRows = false;
          })
          .catch((res) => {
            this.errors = res.errors;
            this.loadingBulletinRows = false;
          });
      }
    },
    loadAnnouncements() {
      const query = `query{
        announcements(current: true){
          edges{
            node{
              title
              id
            }
          }
          pageInfo {
            startCursor
            endCursor
          }
        }
      }`;
      this.$http.post("graphql/", { query }).then((res) => {
        this.bulletinRows = res.data.data.announcements.edges;
        this.selectedSection.page.next = res.data.data.announcements.pageInfo.startCursor;
        this.selectedSection.page.prev = res.data.data.announcements.pageInfo.startCursor;
        this.selectedSection.page.load = false;
        this.loadingBulletinRows = false;
      });
    },
    launchAnnouncement(item) {
      this.$router.push({ name: "announcement", params: { announcement_id: item.node.id } });
    },
    goToRow(item, body) {
      this.$router.push({
        name: this.selectedSection.routeName,
        params: { ticket_id: item.node.id }
      });
    }
  }
};
</script>
