<template>
  <transition-group
    name="fade"
    tag="div"
  >
    <ZoneControlTrends
      v-show="Object.keys(enlargedTrend).length === 0"
      key="trends"
      :machine_group_id="machineGroupId"
      :machine-group-pk="machineGroupPk"
      :level-id="levelId"
      :enlarged-trend="enlargedTrend"
      :set-enlarged-trend="setEnlargedTrend"
    />

    <ZoneControlEnlargedTrend
      v-if="Object.keys(enlargedTrend).length > 0"
      key="enlargedTrend"
      :enlarged-trend="enlargedTrend"
      :set-enlarged-trend="setEnlargedTrend"
      :level-id="levelId"
      :machine_group_id="machineGroupId"
      :machine-group-pk="machineGroupPk"
    />
  </transition-group>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router/composables";

import ZoneControlEnlargedTrend from "./components/ZoneControlEnlargedTrend.vue";
import ZoneControlTrends from "./components/ZoneControlTrends.vue";

export default {
  components: {
    ZoneControlTrends,
    ZoneControlEnlargedTrend
  },
  props: [],
  setup() {
    const route = useRoute();
    const enlargedTrend = ref({});
    const { machine_group_id, machine_group_pk, level } = route.params;

    const setEnlargedTrend = (value) => {
      enlargedTrend.value = value;
    };

    const levelId = computed(() => {
      return parseInt(level, 10);
    });

    const machineGroupPk = computed(() => {
      return parseInt(machine_group_pk, 10);
    });

    const machineGroupId = computed(() => {
      return machine_group_id;
    });

    return {
      enlargedTrend,
      levelId,
      machineGroupPk,
      machineGroupId,
      setEnlargedTrend
    };
  }
};
</script>
