var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"availabilityReasonCodeTable"},[_c('generic-table',{attrs:{"columns":[
      { title: _vm.$t('Reason Codes'), key: 'description' },
      { title: _vm.$t('Actual'), key: 'duration', format: this.seconds }
    ],"footer":[
      { type: 'csv', csv: _vm.downloadCsv },
      { type: 'text', text: this.seconds(_vm.sumValue(_vm.reasonCodeList, ['duration'])) }
    ],"data":_vm.reasonCodeList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }