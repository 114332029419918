var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paginatedChart"},[_c('div',{staticClass:"flex-firstrow"},[_c('h3',[_vm._v(_vm._s(_vm.numberRecords ? `${_vm.$t("Top")} ${_vm.numberRecords}` : _vm.$t("All"))+" "+_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"leftPicker"},[_c('div',{staticClass:"rangeSelector"},[_c('i',{staticClass:"fa fa-chevron-left clickable",class:_vm.recordRange.start <= 0 && 'disabled',on:{"click":() => {
              if (!(_vm.recordRange.start <= 0)) {
                _vm.ChangeRecordRange(-_vm.numberRecordsPerPage);
              }
            }}}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.recordRange.start + 1)+" - "+_vm._s(_vm.recordRange.end + 1)+" ")]),_vm._v(" "),_c('i',{staticClass:"fa fa-chevron-right clickable",class:((_vm.numberRecords != null && _vm.recordRange.end >= _vm.numberRecords - 1) ||
              (_vm.numberRecords == null && _vm.recordRange.end + 1 >= _vm.filteredItems.length)) &&
            'disabled',on:{"click":() => {
              if (
                !(
                  (_vm.numberRecords != null && _vm.recordRange.end >= _vm.numberRecords - 1) ||
                  (_vm.numberRecords == null && _vm.recordRange.end + 1 >= _vm.filteredItems.length)
                )
              ) {
                _vm.ChangeRecordRange(+_vm.numberRecordsPerPage);
              }
            }}})]),_vm._v(" "),(_vm.useNumberRecordsPicker)?_c('v-tabs',{staticClass:"pageSize",attrs:{"value":[10, 25, 50, null].indexOf(_vm.numberRecords)},on:{"change":_vm.ChangeNumberRecords}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("Top 10")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Top 25")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Top 50")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("All")))])],1):_vm._e()],1)]),_vm._v(" "),_c('bar-chart',{staticClass:"bar_chart",attrs:{"title":"","chart-data":_vm.slicedChartData,"chart-options":_vm.options,"height":_vm.vw > 1000 ? 400 : 250}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }