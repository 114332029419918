<template>
  <div
    class="flex-table"
    ref="tablecontainer"
  >
    <div class="table-body">
      <v-simple-table
        dense
        fixed-header
        :height="tableHeight"
        ref="table"
      >
        <thead>
          <tr>
            <th
              v-for="(t, i) in columns"
              v-if="!t.hidden"
              @click="t.sortable && setSort(t.key, t.sort_method)"
              v-bind:class="t.sortable && 'clickable'"
              :key="i"
              ref="tablehead"
            >
              <div
                class="flex-head"
                :key="sortValues"
              >
                <i v-bind:class="caretClass[sort[t.key]]" />
                <div :style="t.column_style">{{ t.title }}</div>
                <i class="hidden" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="sortedData.length > 0">
          <Fragment
            v-for="(data, di) in sortedData"
            :key="di"
          >
            <tr :key="di">
              <td
                v-for="(col, ci) in columns"
                v-if="!col.hidden"
                :key="ci"
                v-bind:class="{
                  titlerow: ci === 0,
                  clickable: 'clickHandler' in col,
                  [col.cssClass]: 'cssClass' in col
                }"
                @click="handleClick(col, data)"
              >
                <div class="titlerowContent">
                  <div>
                    <i
                      class="fa fa-chevron-circle-right expand-icon"
                      @click="
                        () => {
                          expandRow(data);
                        }
                      "
                      v-if="ci === 0 && data['_nested'] && data['_nested'].length > 0"
                      v-bind:class="expanded[data._i] && 'rotated'"
                    />
                    <i
                      v-else-if="ci === 0"
                      class="fa fa-chevron-circle-right expand-icon hidden"
                    />
                  </div>
                  <div
                    v-if="col.html"
                    v-bind:style="`color: ${checkSelected(col, data) ? (col.selected ? col.selected.color : 'unset') : 'unset'}`"
                  >
                    <span
                      v-html="data[col.html]"
                      @click="
                        () => {
                          col.click ? (col.key ? col.click(data[col.key]) : col.click(di)) : null;
                        }
                      "
                      v-bind:style="{ cursor: col.click ? 'pointer' : null }"
                    />
                  </div>
                  <div
                    v-else
                    :style="col.style"
                    class="titleText"
                    v-bind:class="{
                      [data._class && data._class[col.key]]: data._class && data._class[col.key],
                      'align-right': col.alignRight
                    }"
                    @click="
                      () => {
                        col.click ? col.click(data[col.key]) : null;
                      }
                    "
                  >
                    {{ col.format ? col.format(data[col.key]) : data[col.key] }}
                  </div>
                </div>
              </td>
            </tr>
            <tr
              v-if="expanded[data._i]"
              v-for="(nested, ni) in data['_nested']"
              :key="`${di}_${ni}`"
              class="nest_row"
            >
              <td
                v-for="(col, ci) in nested_columns"
                :key="ci"
                v-bind:class="ci === 0 ? 'titlerow nested' : null"
              >
                <div
                  :style="col.style"
                  v-bind:class="nested._class && nested._class[col.key]"
                >
                  {{
                    col && nested
                      ? col.format
                        ? col.format(nested[col.key])
                        : nested[col.key]
                      : ""
                  }}
                </div>
              </td>
            </tr>
          </Fragment>
        </tbody>
      </v-simple-table>
    </div>
    <div class="table-foot">
      <v-simple-table
        dense
        v-if="footer.length > 0"
      >
        <tfoot>
          <tr>
            <td
              v-for="(t, i) in footer"
              ref="tablefoot"
            >
              <v-btn
                color="primary"
                small
                class="ma-2"
                @click="t.csv"
                style="font-size: 12px"
                v-if="t.type === 'csv'"
              >
                <v-icon style="font-size: 12px">mdi-file-download</v-icon> CSV
              </v-btn>
              <div
                v-if="t.type === 'text'"
                class="center-text"
                v-bind:class="t.class"
              >
                {{ t.text }}
              </div>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Fragment } from "vue-fragment";

import { seconds } from "@/utils/filters";

export default {
  name: "GenericTable",
  props: {
    data: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    nested_columns: { type: Array, default: () => [] },
    footer: { type: Array, default: () => [] },
    height: { type: Number, default: 0 },
    resetExpand: { type: Boolean, default: true },
    expandRowClickHandler: { type: Function, default: () => null }
  },
  components: {
    Fragment
  },
  data() {
    return {
      keys: [],
      sortedData: [],
      expanded: [],
      sort: {},
      sortValues: "",
      caretClass: {
        0: "fa fa-caret-up hidden",
        1: "fa fa-caret-up",
        "-1": "fa fa-caret-down"
      },
      scrollPosition: 0
    };
  },
  mounted() {
    this.setupData();
    for (const t of this.columns) {
      this.sort[t.key] = 0;
    }
    window.addEventListener("resize", this.fixFooterWidth);
    Vue.nextTick(() => {
      this.fixFooterWidth();
    });
  },
  updated() {
    this.$refs.table.$el
      .getElementsByClassName("v-data-table__wrapper")[0]
      .scrollTo({ top: this.scrollPosition, left: 0, behavior: "instant" });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.fixFooterWidth);
  },
  computed: {
    tableHeight() {
      return this.height ? `${this.height}px` : null;
    }
  },
  methods: {
    seconds,
    checkSelected(col, data) {
      if (col.selected && col.selected.data && col.selected.keys) {
        let current = col.selected.data;
        for (const key of col.selected.keys) {
          if (!current[data[key]]) {
            return false;
          } else {
            current = current[data[key]];
          }
        }
      } else {
        return false;
      }

      return true;
    },
    handleClick(col, data) {
      if (!col.clickHandler) {
        return;
      }
      col.clickHandler(data);
    },
    fixFooterWidth() {
      if (this.$refs.tablecontainer) {
        let tableFootHeight = 0;
        if (this.$refs.tablefoot) {
          tableFootHeight = this.$refs.tablefoot[0].clientHeight;
        }
        if (this.$refs.tablecontainer.clientHeight !== 0) {
          this.$refs.table.$el.style.height = `${this.$refs.tablecontainer.clientHeight - tableFootHeight}px`;
          this.$refs.table.$el.getElementsByClassName("v-data-table__wrapper")[0].style.height =
            `${this.$refs.tablecontainer.clientHeight - tableFootHeight}px`;
        }
      }

      if (this.$refs.tablefoot) {
        this.$refs.tablefoot.forEach((element, i) => {
          if (this.$refs.tablehead[i]) {
            element.width = this.$refs.tablehead[i].clientWidth;
          }
        });

        this.$refs.tablehead.forEach((element, i) => {
          if (this.$refs.tablefoot[i]) {
            element.width = this.$refs.tablefoot[i].clientWidth;
          }
        });
      }
    },
    expandRow(data) {
      this.expanded[data._i] = !this.expanded[data._i];
      this.expanded = [...this.expanded];
      if (!this.expandRowClickHandler) {
        return;
      }
      this.scrollPosition =
        this.$refs.table.$el.getElementsByClassName("v-data-table__wrapper")[0].scrollTop;
      this.expandRowClickHandler(data, data._i, this.expanded);
    },
    setSort(sortKey, sort_method) {
      let sortDirection = 0;
      for (const s of Object.keys(this.sort)) {
        if (s !== sortKey) {
          this.sort[s] = 0;
        } else {
          switch (this.sort[s]) {
            case 0:
              this.sort[s] = -1;
              sortDirection = -1;
              break;
            case -1:
              this.sort[s] = 1;
              sortDirection = 1;
              break;
            case 1:
              this.sort[s] = 0;
              sortDirection = 0;
              break;
          }
        }
      }
      if (!sort_method) {
        this.setupData();
      } else {
        sort_method(sortKey, sortDirection);
      }
      this.sortValues = Object.values(this.sort).join("");
    },
    setupData() {
      let dataCopy = [...this.data];
      dataCopy = dataCopy.map((m, i) => {
        return { ...m, _i: i };
      });

      this.keys = [];
      const sortKey = Object.keys(this.sort).filter((s) => this.sort[s] != 0)[0];
      if (!sortKey) {
        this.sortedData = [...dataCopy];
        return;
      }

      const sortedData = dataCopy.sort((a, b) => {
        if (typeof a[sortKey] === "string" && typeof b[sortKey] === "string") {
          return (
            a[sortKey].localeCompare(b[sortKey], undefined, { numeric: true }) * this.sort[sortKey]
          );
        }
        return (a[sortKey] - b[sortKey]) * this.sort[sortKey];
      });
      this.sortedData = [...sortedData];
    },
    toLocaleStringFormat(value) {
      return value.toLocaleString();
    }
  },
  watch: {
    data: function () {
      this.sortedData = [];
      this.expanded = this.resetExpand ? [] : this.expanded;
      requestAnimationFrame(() => {
        this.setupData();
        this.fixFooterWidth();
      });
    },
    interactive_filters: function () {}
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/variables";
@import "../scss/mq";

.flex-table {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  height: 100%;
  width: 100%;

  .table-body {
    flex: 1;
    overflow: hidden;
  }

  .table-foot {
    flex: unset;

    .center-text {
      text-align: center;
    }

    div {
      box-sizing: content-box;
    }

    tr {
      height: 46px !important;
      td {
        height: unset;
      }
    }
  }

  tr {
    td {
      text-align: center;
    }
  }
}

tfoot {
  tr {
    td {
      text-align: left !important;
    }
  }

  .theme--dark & {
    background: $grey;
  }

  .theme--light & {
    background: $light-background;
  }
}

.flex-head {
  display: flex;
  div {
    flex: unset;
    text-align: center;
  }
  i {
    flex: 1;
    width: 10px;
    text-align: right;
    padding: 0 10px;
  }
}

th {
  color: $blue;
  text-align: left;

  &.clickable {
    cursor: pointer;
  }
}

i.hidden {
  visibility: hidden;
}

td:first-child {
  color: $blue;
}

.expand-icon {
  padding: 5px;
  transition: transform 0.25s;
  cursor: pointer;

  &.hidden {
    visibility: hidden;
  }

  &.none {
    display: none;
  }
}

.rotated {
  transform: rotate(90deg);
}

.align-right {
  text-align: right;
}

.titlerow {
  text-align: left !important;
  display: table-cell;
  vertical-align: center;

  .titlerowContent {
    display: flex;
  }

  .titleText {
    line-height: 16px;
    padding: 4px 0;
  }

  &.nested span {
    margin-left: 20px;
  }
}

.clickable {
  cursor: pointer;
}

.nest_row {
  .theme--light & {
    background: $lighterGrey;
  }

  .theme--dark & {
    background: $darkGrey;
  }
}
</style>
