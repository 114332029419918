var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('div',{staticClass:"home",class:{
      openNav: _vm.nav == 'open',
      openHelpPanel: _vm.showHelp,
      openDocPanel: _vm.rightPanel == 'docs' || _vm.rightPanel == 'video',
      openAuditsPanel: _vm.rightPanel == 'audits',
      openSearchPanel: _vm.rightPanel == 'search'
    }},[_c('banner'),_vm._v(" "),_c('nav-bar',{ref:"navbar",attrs:{"status":_vm.nav}}),_vm._v(" "),(_vm.isFetchedTimezone)?_c('div',{staticClass:"content"},[_c('transition',{attrs:{"name":"fade-in-up","mode":"out-in"}},[_c('router-view',{attrs:{"transition-mode":"out-in"}})],1)],1):_vm._e(),_vm._v(" "),_c('audits'),_vm._v(" "),_c('help'),_vm._v(" "),_c('doc-center'),_vm._v(" "),_c('search')],1),_vm._v(" "),_c('div',{staticClass:"alert-container"},_vm._l((_vm.alerts),function(alert,idx){return _c('v-alert',{key:idx,attrs:{"type":alert.type,"elevation":"2","transition":"scale-transition","dense":""}},[_vm._v(_vm._s(alert.text))])}),1),_vm._v(" "),_c('div',{staticClass:"csv-tracker-container"},[(_vm.csvTracker.show)?_c('v-alert',{attrs:{"color":_vm.csvTracker.color,"elevation":"2","transition":"scale-transition","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.csvTracker.name)?_c('p',[_vm._v(_vm._s(_vm.csvTracker.name))]):_vm._e(),_vm._v(" "),(_vm.csvTracker.error)?_c('p',[_vm._v(_vm._s(_vm.csvTracker.error))]):(_vm.csvTracker.status)?_c('p',[_vm._v("\n          "+_vm._s(_vm.csvTracker.status.toUpperCase())+"\n          "),(_vm.csvTracker.progress != -1)?_c('span',[_vm._v(_vm._s(_vm.csvTracker.progress)+"%")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.csvTracker.error && !_vm.csvTracker.complete)?_c('v-progress-linear',{attrs:{"value":_vm.csvTracker.progress,"indeterminate":_vm.csvTracker.progress == -1,"color":"white"}}):_vm._e()]},proxy:true}],null,false,1414075650)}):_vm._e()],1),_vm._v(" "),_c('portal-target',{attrs:{"name":"side-drawer"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"filters-1"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"filters-2"}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"filters-3"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }