<template>
  <transition-group
    name="fade"
    class="zone-control menuBarAdjust"
    tag="div"
  >
    <div
      class="dashboard_wrapper"
      v-show="Object.keys(enlargedTable).length === 0 && Object.keys(enlargedTrend).length === 0"
      key="dash"
    >
      <div class="smallPageTabs">
        <v-tabs
          right
          @change="SetCurrentTab"
          :show-arrows="false"
        >
          <v-tab class="smalltab">{{ $t("Production Summary") }}</v-tab>
          <v-tab class="smalltab">{{ $t("Trends") }}</v-tab>
          <v-tab class="smalltab">{{ $t("Problem Solving") }}</v-tab>
        </v-tabs>
      </div>
      <div class="dashboard">
        <ProductionSummary
          :mobileTabIndex="mobileTabIndex"
          :machineGroupPk="machineGroupPk"
          :selectedMachineGroups="selectedMachineGroups"
          :weekPicker="weekPicker"
          :loadMachineProductionData="loadMachineProductionData"
          :loadMachineGroupProductionData="loadMachineGroupProductionData"
          :loaded="loaded"
          :machineData="machineData"
          :machineHeaders="machineHeaders"
          :getShift="getShift"
          :setWeekPicker="setWeekPicker"
          :setSelectedMachineGroups="setSelectedMachineGroups"
          :shifts="shifts"
          :days="days"
        />
        <Trends
          :mobileTabIndex="mobileTabIndex"
          :machine_group_id="machine_group_id"
          :machineGroupPk="machineGroupPk"
          :levelId="levelId"
          :enlargedTrend="enlargedTrend"
          :setEnlargedTrend="setEnlargedTrend"
        />
        <ProblemSolving
          :mobileTabIndex="mobileTabIndex"
          :machine_group_id="machine_group_id"
          :machineGroupPk="machineGroupPk"
          :enlargedTable="enlargedTable"
          :issueTables="issueTables"
          :issueHeaders="issueHeaders"
          :setEnlargedTable="setEnlargedTable"
          :loadIssuePage="loadIssuePage"
          :handleItemsPerPageChange="handleItemsPerPageChange"
          :getStatusIcon="getStatusIcon"
          :getInprocess="getInprocess"
          :getLatest="getLatest"
          :getOldest="getOldest"
          :addIssue="addIssue"
        />
      </div>
    </div>
    <EnlargedTable
      ref="enlargedTableRef"
      v-if="Object.keys(enlargedTable).length > 0"
      key="enlarged"
      :enlargedTable="enlargedTable"
      :reasons="reasons"
      :machineGroupPk="machineGroupPk"
      :machines="machines"
      :selectedInterval="selectedInterval"
      :setPresetDates="setPresetDates"
      :getInprocess="getInprocess"
      :getLatest="getLatest"
      :getOldest="getOldest"
      :enlargedIssueHeaders="enlargedIssueHeaders"
      :loadIssuePage="loadIssuePage"
      :handleItemsPerPageChange="handleItemsPerPageChange"
      :getStatusIcon="getStatusIcon"
      :machinesLookup="machinesLookup"
      :closeTable="closeTable"
      :fromDate="fromDate"
      :toDate="toDate"
      :handlePresetClick="handlePresetClick"
      :intervals="intervals"
      :loadIssues="loadIssues"
    />
    <EnlargedTrend
      v-if="Object.keys(enlargedTrend).length > 0"
      key="enlargedTrend"
      :enlargedTrend="enlargedTrend"
      :setEnlargedTrend="setEnlargedTrend"
      :levelId="levelId"
      :machine_group_id="machine_group_id"
      :machineGroupPk="machineGroupPk"
    />
  </transition-group>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import DevelopmentFeature from "@/components/DevelopmentFeature";
import ZoneIssue from "@/components/dialogs/ZoneIssue";

import PaginatedChart from "../../analytics/operator/PaginatedChart";
import EnlargedTable from "./EnlargedTable.vue";
import EnlargedTrend from "./EnlargedTrend.vue";
import ProblemSolving from "./ProblemSolving.vue";
import ProductionSummary from "./ProductionSummary.vue";
import Trends from "./Trends.vue";

export default {
  props: ["level", "machine_group_id", "machine_group_pk", "machines", "machinesLookup"],
  components: {
    PaginatedChart,
    ZoneIssue,
    DevelopmentFeature,
    ProductionSummary,
    Trends,
    ProblemSolving,
    EnlargedTable,
    EnlargedTrend
  },
  data() {
    let current = moment().startOf("week");
    let days = [];
    let n = 7;
    while (n > 0) {
      days.push({
        slotName: "item." + current.format("ddd"),
        text: current.format("ddd"),
        value: current.format("ddd"),
        moment: current.clone(),
        align: "center",
        sortable: false
      });
      current.add(1, "day");
      n--;
    }
    return {
      selectedMachineGroups: [],
      weekPicker: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD")
      ],
      mobileTabIndex: 0,
      loaded: false,
      days: days,
      shifts: [],
      machineData: [],
      machineHeaders: [
        { text: this.$t("Shift"), value: "shift", align: "center", sortable: false },
        { text: "", value: "labels", align: "center", sortable: false }
      ].concat(days),
      issueHeaders: [
        { text: this.$t("Machine or Group"), value: "relation", sortable: false, width: "25%" },
        {
          text: this.$t("Issue"),
          value: "reason",
          align: "center",
          sortable: false,
          cellClass: "reasonText",
          width: "calc(75% - 30px)"
        },
        //{ text: this.$t('Owner'), value: 'owner',  align: 'center', sortable: false, width: 'calc(20% - 18px)' },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: "30px"
        }
      ],
      enlargedIssueHeaders: [
        { text: this.$t("Title"), value: "node.title" },
        {
          text: this.$t("Machine or Group"),
          value: "relation",
          sortable: false,
          width: "calc(25% - 18px)"
        },
        {
          text: this.$t("Issue"),
          value: "node.reason",
          align: "center",
          sortable: false,
          width: "25%"
        },
        {
          text: this.$t("Short Term Countermeasures"),
          value: "countermeasures",
          align: "center",
          sortable: false,
          width: "50px"
        },
        {
          text: this.$t("Root Cause"),
          value: "cause",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Long Term Solutions"),
          value: "solution",
          align: "center",
          sortable: false,
          width: "150px"
        },
        //{ text: this.$t('Owner'), value: 'owner',  align: 'center', sortable: false, width: '30px' },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: "150px"
        },
        { text: "", value: "launch", align: "center", sortable: false, width: "40px" }
      ],
      issueTables: [
        {
          title: this.$t("Unresolved Issues for the Week"),
          dates: {
            start: moment().startOf("day").subtract(7, "days"),
            end: moment().endOf("day")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          load: false,
          next: null,
          prev: null
        },
        {
          title: this.$t("Ongoing Unresolved Issues for the Month"),
          dates: {
            start: moment().startOf("day").subtract(30, "days"),
            end: moment().startOf("day").subtract(7, "days")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          load: false,
          next: null,
          prev: null
        },
        {
          title: this.$t("Complex Unresolved Issues"),
          dates: {
            start: null,
            end: moment().startOf("day").subtract(30, "days")
          },
          issues: [],
          loading: false,
          itemsPerPage: 10,
          total: 0,
          current: 1,
          load: false,
          next: null,
          prev: null
        }
      ],
      enlargedTable: {},
      enlargedTrend: {},
      reasons: [],
      reasonIds: [],
      machineIds: [],
      machineGroupIds: [],
      intervals: [
        { name: this.$t("Default"), value: "card" },
        { name: this.$t("Shift"), value: "shift" },
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      selectedInterval: "card",
      fromDate: "",
      toDate: ""
    };
  },
  async created() {
    this.machinesLoaded = false;
    let query = `query getMachineGroup($id: ID!){
      machineGroup(id: $id){
        id
        ... on MachineGroupType{
          name
          pk
          id
        }
      }
      settings{
        zcbEscalationTime
      }
    }`;
    let variables = { id: this.machine_group_id };
    this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
      let title =
        "<h2>" +
        res.data.data.machineGroup.name +
        "</h2>" +
        "<p>" +
        this.$t("Week") +
        moment().format(" W : ") +
        moment().startOf("week").format("MMM DD, YYYY") +
        " - " +
        moment().endOf("week").format("MMM DD, YYYY") +
        "</p>";
      this.setTitles({
        title: title,
        mobile: "<h3>" + res.data.data.machineGroup.name + "</h3"
      });
      if (res.data.data.settings.length > 0) {
        this.issueTables[0].dates.end = moment()
          .startOf("hour")
          .subtract(res.data.data.settings[0].zcbEscalationTime, "hours");
      }
    });
    await this.loadIssueTypes();
    if (this.machineGroupIds.length == 1) {
      this.loadMachineProductionData();
    } else {
      this.loadMachineGroupProductionData();
    }
  },
  computed: {
    ...mapGetters({
      shiftLookup: "dbCache/shiftFromPk",
      machineGroupLookup: "dbCache/machineGroupFromId"
    }),
    levelId() {
      return parseInt(this.level, 10);
    },
    machineGroupPk() {
      return parseInt(this.machine_group_pk, 10);
    }
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    setSelectedMachineGroups(value) {
      this.selectedMachineGroups = value;
    },
    setWeekPicker(weekPicker) {
      this.weekPicker = weekPicker;
    },
    setEnlargedTable(issueTable) {
      this.enlargedTable = issueTable;
    },
    setInterval(interval) {
      this.selectedInterval = interval;
    },
    setEnlargedTrend(value) {
      this.enlargedTrend = value;
    },
    handlePresetClick(value) {
      this.selectedInterval = value;
      this.$refs.enlargedTableRef.$refs.summary.setPreset(value);
    },
    setPresetDates(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    SetCurrentTab(tab) {
      this.mobileTabIndex = tab;
    },
    closeTable() {
      this.selectedInterval = "card";
      this.enlargedTable = {};
    },
    prepareForProductionDataFetching() {
      this.machineData = [];
      let start = moment(this.weekPicker[0]);
      let days = [];
      let n = 7;
      while (n > 0) {
        days.push({
          slotName: "item." + start.format("ddd"),
          text: start.format("ddd"),
          value: start.format("ddd"),
          moment: start.clone(),
          align: "center",
          sortable: false
        });
        start.add(1, "day");
        n--;
      }
      this.days = days;
      let params = {
        machine_group_id:
          this.selectedMachineGroups.length > 0
            ? this.selectedMachineGroups[this.selectedMachineGroups.length - 1].pk
            : this.machineGroupPk,
        scale: "1d",
        from_date: moment(this.weekPicker[0]).toISOString(),
        to_date: moment(this.weekPicker[1]).endOf("day").toISOString()
      };
      return params;
    },
    buildProductionData(data, totalsObj, type) {
      const summaryTotals = [];
      data.forEach((item, item_index) => {
        // console.log("item", item.key);
        const machineOrGroup =
          type === "machine" ? this.machinesLookup[item.key] : this.machineGroupLookup(item.key);
        if (machineOrGroup) {
          item.shifts.buckets.forEach((shift) => {
            shift["name"] = this.getShift(shift.key);
          });
          if (item.shifts.buckets.length > 0) {
            item.shifts.buckets.sort((a, b) =>
              a.name.toUpperCase() < b.name.toUpperCase()
                ? -1
                : a.name.toUpperCase() > b.name.toUpperCase()
                  ? 1
                  : 0
            );
          }
          if (!totalsObj.shifts && item.shifts.buckets) {
            totalsObj.shifts = JSON.parse(JSON.stringify(item.shifts.buckets));
            //when we parse the JSON, there are already values in
            // the produced and production_target fields
            for (const shift of totalsObj.shifts) {
              for (let bucket of shift.intervals.buckets) {
                bucket.produced.value = 0;
                bucket.scaled_target_2.production_target_2.value = 0;
              }
            }
          }
          let machineTotals = [];
          item.shifts.buckets.forEach((shift, shiftIndex) => {
            // console.log("shift", shift.name);
            let shiftTotals = totalsObj.shifts.find((st) => st.key == shift.key);
            if (!shiftTotals && (type == "group" || machine.contributesToTotal)) {
              shiftTotals = JSON.parse(JSON.stringify(shift));
              totalsObj.shifts.push(shiftTotals);
            }
            if (shiftTotals) {
              // console.log("shiftTotal", shiftTotals);
              shift.intervals.buckets.forEach((shiftDay, dayIndex) => {
                // console.log(
                //   "shiftDays",
                //   shiftDay.key_as_string,
                //   shiftDay.produced.value,
                //   shiftDay.scaled_target_2.production_target_2.value
                // );
                if (!machineTotals[dayIndex]) {
                  machineTotals[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                } else {
                  machineTotals[dayIndex]["produced"]["value"] += shiftDay.produced.value;
                  machineTotals[dayIndex]["scaled_target_2"]["production_target_2"]["value"] +=
                    shiftDay.scaled_target_2.production_target_2.value;
                }
                if (type == "group" || machineOrGroup.contributesToTotal) {
                  if (!summaryTotals[dayIndex]) {
                    summaryTotals[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                  } else {
                    summaryTotals[dayIndex]["produced"]["value"] += shiftDay.produced.value;
                    summaryTotals[dayIndex]["scaled_target_2"]["production_target_2"]["value"] +=
                      shiftDay.scaled_target_2.production_target_2.value;
                  }
                  if (dayIndex in shiftTotals.intervals.buckets) {
                    const bucket = shiftTotals.intervals.buckets[dayIndex];
                    bucket.produced.value += shiftDay.produced.value;
                    bucket.scaled_target_2.production_target_2.value +=
                      shiftDay.scaled_target_2.production_target_2.value;
                  } else {
                    shiftTotals.intervals.buckets[dayIndex] = JSON.parse(JSON.stringify(shiftDay));
                  }
                }
              });
            }
          });
          item.shifts.buckets.push({
            key: "totals",
            intervals: {
              buckets: machineTotals
            }
          });
          this.machineData.push({
            name: machineOrGroup.name,
            level: machineOrGroup.level ? machineOrGroup.level.level : null,
            id: machineOrGroup.id,
            pk: machineOrGroup.pk,
            shifts: item.shifts.buckets
          });
        }
      });
      if (!totalsObj.shifts) {
        totalsObj.shifts = [];
      }
      if (totalsObj.shifts.length > 0) {
        totalsObj.shifts.sort((a, b) =>
          a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
        );
      }
      totalsObj.shifts.push({
        key: "totals",
        intervals: {
          buckets: summaryTotals
        }
      });
      if (this.machineData.length > 0) {
        this.machineData.sort((a, b) =>
          a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : a.name.toUpperCase() > b.name.toUpperCase()
              ? 1
              : 0
        );
      }
      if (this.selectedMachineGroups.length > 0) {
        totalsObj["shifts"] = JSON.parse(JSON.stringify(this.selectedMachineGroups[0]["shifts"]));
      }
      this.machineData.unshift(totalsObj);
    },
    async loadMachineGroupProductionData() {
      this.loaded = false;
      const params = this.prepareForProductionDataFetching();
      try {
        const response = await this.$http.get("/metrics/production_per_group_per_shift/", {
          params
        });
        if (response && response.data && response.data.data && response.data.shifts) {
          const data = Object.keys(response.data.data).map((item) => ({
            ...response.data.data[item],
            key: parseInt(item, 10)
          }));
          this.shifts = response.data.shifts;
          const parentMachineGroup = this.machineGroupLookup(this.machineGroupPk);
          console.log(parentMachineGroup);
          const totalsObj = {
            name:
              parentMachineGroup && parentMachineGroup.name
                ? parentMachineGroup.name
                : this.$t("Unknown Group"),
            id: parentMachineGroup.id,
            pk: parentMachineGroup.pk,
            level: parentMachineGroup.level,
            shifts: null
          };
          this.buildProductionData(data, totalsObj, "group");
          this.machineData = this.machineData.map((data) => {
            const group = this.machineGroupLookup(parseInt(data.pk, 10));
            return {
              ...data,
              hasSubGroups: group?.allSubGroups?.length > 0
            };
          });
          if (this.selectedMachineGroups.length > 0) {
            this.machineData.splice(1, 0, ...this.selectedMachineGroups);
          }
        }
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        console.error(error);
      }
    },
    async loadMachineProductionData() {
      this.loaded = false;
      const params = this.prepareForProductionDataFetching();
      try {
        const response = await this.$http.get("metrics/production_per_machine_per_shift/", {
          params
        });
        this.shifts = response.data.shifts;
        const parentMachineGroup = this.machineGroupLookup(this.machineGroupPk);
        let totalsObj = {
          name:
            parentMachineGroup && parentMachineGroup.name
              ? parentMachineGroup.name
              : this.$t("Unknown Group"),
          id: -1,
          pk: false,
          shifts: null
        };
        this.buildProductionData(response.data.data, totalsObj, "machine");
        if (this.selectedMachineGroups.length > 0) {
          this.machineData.splice(1, 0, ...this.selectedMachineGroups);
        }
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        console.error(error);
      }
    },
    getShift(shift_id) {
      let shift = this.shiftLookup(shift_id);
      if (shift_id == "totals") {
        return this.$t("Totals");
      }
      if (shift) {
        return shift.name;
      }
      return this.$t("Unknown Shift") + " (" + shift_id + ")";
    },
    loadIssueTypes() {
      this.reasons = [];
      this.loadingTickets = true;
      const query = `query ($machineGroupId: ID!){
        issueReasons{
          edges{
            node{
              id
              pk
              text
              issueType{
                id
                pk
              }
            }
          }
        }
        machineGroup(id: $machineGroupId){
          id
          ... on MachineGroupType{
            id
            pk
            allMachines{
              id
              pk
            }
            allSubGroups{
              id
              pk
            }
          }
        }
      }`;
      const variables = { machineGroupId: this.machine_group_id };
      return this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.reasons = res.data.data.issueReasons.edges;
          let reasonIds = [6, 7, 8];
          this.reasons.forEach((reason) => {
            if (reason.node.pk && !(reason.node.pk in reasonIds)) {
              reasonIds.push(reason.node.pk);
            }
          });
          this.machineIds = res.data.data.machineGroup.allMachines.map((machine) => machine.pk);
          this.machineGroupIds = res.data.data.machineGroup.allSubGroups.map((group) => group.pk);
          this.machineGroupIds.push(this.machineGroupPk);
          this.reasonIds = reasonIds;
          this.issueTables.forEach((table) => {
            this.loadIssues(table);
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    handleItemsPerPageChange(value, table) {
      this.loadIssues(table);
    },
    loadIssuePage(table, pagination) {
      if (pagination.page > table.page) {
        table.load = {
          after: table.next,
          before: false,
          first: table.itemsPerPage
        };
        this.loadIssues(table);
      } else if (pagination.page < table.page) {
        table.load = {
          after: false,
          before: table.prev,
          last: table.itemsPerPage
        };
        this.loadIssues(table);
      }
      table.page = pagination.page;
    },
    loadIssues(table) {
      table.loading = true;
      const query = `query (
        $andOr: GrapheneElasticAndORIssueSearchConnectionAndOrFilter,
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          simpleQueryString: $search,
          filter: $filters,
          ordering: $ordering,
          andOr: $andOr,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              countermeasures,
              cause,
              solution
              openedDate,
              ticketedDate,
              assignedToNames,
              issueStartDate,
              issueEndDate,
              machineId,
              machineName,
              machineGroupName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      let ordering = { ticketedDate: "DESC" };
      const variables = {
        filters: {
          ticketed: { value: true },
          closedDate: { exists: false },
          reasonId: { in: this.reasonIds }
        },
        andOr: {
          MachineOrMachineGroup: {
            machineIds: this.machineIds,
            machineGroupIds: this.machineGroupIds
          }
        },
        ordering: ordering,
        before: false,
        after: false,
        search: ""
      };
      if (table.dates.start && table.dates.end) {
        variables["filters"]["ticketedDate"] = {
          range: {
            lower: { datetime: table.dates.start.toISOString() },
            upper: { datetime: table.dates.end.toISOString() }
          }
        };
      } else if (table.dates.end) {
        variables["filters"]["ticketedDate"] = {
          lt: { datetime: table.dates.end.toISOString() }
        };
      }
      if (table.load) {
        Object.assign(variables, table.load);
      } else {
        variables["first"] = table.itemsPerPage;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          table.issues = res.data.data.issues.edges;
          table.total = res.data.data.issues.facets.automatic.doc_count;
          table.next = res.data.data.issues.pageInfo.endCursor;
          table.prev = res.data.data.issues.pageInfo.startCursor;
          table.load = false;
          table.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getStatusIcon(issue) {
      let icon = "mdi-circle-outline";
      if (issue.node.cause) {
        icon = "mdi-circle-slice-4";
      }
      if (issue.node.solution) {
        icon = "mdi-circle-slice-6";
      }
      if (issue.node.closedDate) {
        icon = "mdi-circle-slice-8";
      }
      return icon;
    },
    getInprocess(issues) {
      let total = 0;
      issues.forEach((issue) => {
        if (issue.node.cause) {
          total++;
        }
      });
      return total;
    },
    getLatest(issues) {
      let latest = false;
      issues.forEach((issue) => {
        let issueDate = moment(issue.node.ticketedDate);
        if (!latest || issueDate.isAfter(latest)) {
          latest = issueDate;
        }
      });
      if (latest) {
        return moment().diff(latest, "days");
      }
      return "";
    },
    getOldest(issues) {
      let oldest = false;
      issues.forEach((issue) => {
        let issueDate = moment(issue.node.ticketedDate);
        if (!oldest || issueDate.isBefore(oldest)) {
          oldest = issueDate;
        }
      });
      if (oldest) {
        return moment().diff(oldest, "days");
      }
      return "";
    },
    addIssue(issues, issue) {
      issues.unshift({ node: issue });
      this.issueTables = [...this.issueTables];
    }
  }
};
</script>
<style lang="scss">
@import "./ZoneControl.scss";
</style>
